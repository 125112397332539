import React, { lazy, useContext } from "react";
import UserNavbar from "../components/UserNavbar";
import UserSidebar from "../components/UserSidebar";
import UserFooter from "../components/UserFooter";
import { ThemeContext } from "../App";
import Loader from "../components/Loader";

const LazyLightTheme = lazy(() => import("../theme/LigthThemeV2"));
const LazyDarkTheme = lazy(() => import("../theme/DarkThemeV2"));

const UserLayout = ({ children }) => {
  const themeContextValue = useContext(ThemeContext);

  const handleCommonToggle = () => {
    const sidebarRef = document.getElementById("sidebarBox");
    const pageBodyRef = document.getElementById("pageBody");
    sidebarRef.classList.toggle("toggle-sidebar");
    pageBodyRef.classList.toggle("toggle-page");
    sidebarRef.classList.remove("active");
  };
  const handleMobileToggle = () => {
    const sidebarRef = document.getElementById("sidebarBox");
    sidebarRef.classList.toggle("active");
    sidebarRef.classList.remove("toggle-sidebar");
  };

  const ThemeSelector = ({ children }) => {
    return (
      <>
        {/* <React.Suspense fallback={<></>}> */}
        {themeContextValue.selectedTheme === "light" && <LazyLightTheme />}
        {themeContextValue.selectedTheme === "dark" && <LazyDarkTheme />}
        {/* </React.Suspense> */}
        {children}
      </>
    );
  };

  return (
    <>
      <React.Suspense fallback={<Loader />}>
        <div
          class="dashboard-main-wrapper"
          style={{
            backgroundColor:
              themeContextValue.selectedTheme === "dark"
                ? "#14263e"
                : "#f4f6f8",
          }}
        >
          <ThemeSelector>
            <aside
              className={"d-flex sidebar-wrapper text-start "}
              id="sidebarBox"
            >
              <UserSidebar handleCommonToggle={handleCommonToggle} />
            </aside>
            <div className={"page-right-part text-start"} id="pageBody">
              <div
                style={{
                  minHeight: "95vh",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <UserNavbar handleMobileToggle={handleMobileToggle} />
                <div style={{ flex: 1 }}>{children}</div>
                <UserFooter />
              </div>
            </div>
          </ThemeSelector>
        </div>
      </React.Suspense>
    </>
  );
};

export default UserLayout;
