// TODO: change on deploy

let tempbackendServerBaseURL = "http://localhost:7500/api/v1";
let tempbackendServerSocketURL = "http://localhost:7500";
let tempMediaServerBaseURL = "https://lightningproxies.net";
let tempfrontendServerBaseURL = "https://lightningproxies.net";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  console.log("dev");
} else {
  tempbackendServerBaseURL = "https://lightningproxies.net/api/v1";
  tempbackendServerSocketURL = "https://lightningproxies.net";
  tempfrontendServerBaseURL = "https://lightningproxies.net";
  tempMediaServerBaseURL = "https://lightningproxies.net/static";
}

export let backendServerBaseURL = tempbackendServerBaseURL;
export let backendServerSocketURL = tempbackendServerSocketURL;
export let frontendServerBaseURL = tempfrontendServerBaseURL;
export let mediaServerBaseURL = tempMediaServerBaseURL;
