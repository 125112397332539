import React, { lazy, useContext, useState, useEffect } from "react";
import GuideSidebar from "../components/customerpublicapi";
import UserNavbar from "../components/Guidenavbar";
import { ThemeContext } from "../App";
import Loader from "../components/Loader";

const LazyLightTheme = lazy(() => import("../theme/LigthThemeV2"));
const LazyDarkTheme = lazy(() => import("../theme/DarkThemeV2"));

const UserLayout = ({ children }) => {
  const themeContextValue = useContext(ThemeContext);

  const handleCommonToggle = () => {
    const sidebarRef = document.getElementById("sidebarBox");
    const pageBodyRef = document.getElementById("pageBody");
    sidebarRef.classList.toggle("toggle-sidebar");
    pageBodyRef.classList.toggle("toggle-page");
    sidebarRef.classList.remove("active");
  };
  const handleMobileToggle = () => {
    const sidebarRef = document.getElementById("sidebarBox");
    sidebarRef.classList.toggle("active");
    sidebarRef.classList.remove("toggle-sidebar");
  };

  const [isDarkMode, setIsDarkMode] = useState(false);

  const handleCheckboxChange = () => {
    setIsDarkMode((prevMode) => !prevMode);
    // window.location.reload();
  };

  useEffect(() => {
    console.log(isDarkMode);
    const newTheme = isDarkMode ? "dark" : "light";
    console.log(newTheme);

    // Update theme context and local storage
    themeContextValue.setselectedTheme(newTheme);
    localStorage.setItem("theme", newTheme);

    if (isDarkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [isDarkMode]);

  const ThemeSelector = ({ children }) => {
    return (
      <>
        {/* <React.Suspense fallback={<></>}> */}
        {themeContextValue.selectedTheme === "light" && <LazyLightTheme />}
        {themeContextValue.selectedTheme === "dark" && <LazyDarkTheme />}
        {/* </React.Suspense> */}
        {children}
      </>
    );
  };

  return (
    <>
      <React.Suspense fallback={<Loader />}>
        <div
          class="dashboard-main-wrapper"
          style={{
            backgroundColor:
              themeContextValue.selectedTheme === "dark"
                ? "#14263e"
                : "#f4f6f8",
          }}
        >
          <ThemeSelector>
            <aside
              className={"d-flex sidebar-wrapper text-start "}
              id="sidebarBox"
            >
              <GuideSidebar handleCommonToggle={handleCommonToggle} />
            </aside>
            <div className={"page-right-part text-start"} id="pageBody">
              <UserNavbar handleMobileToggle={handleMobileToggle} />
              {children}
            </div>
          </ThemeSelector>
        </div>
      </React.Suspense>
    </>
  );
};

export default UserLayout;
