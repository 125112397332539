import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { getMe, selectMe } from "../redux/slices/generalSlice";
import { debounce } from "lodash";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { backendServerBaseURL } from "../utils/backendServerBaseURL";

const UserSidebar = ({ handleCommonToggle }) => {
  const [showAdmin, setShowAdmin] = useState(false);
  const [showSupport, setShowSupport] = useState(false);
  const [SocialLinks, setSocialLinks] = useState([]);
  const [navOpen, setnavOpen] = useState(true);
  const [selectedMenu, setselectedMenu] = useState("");
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const me = useSelector(selectMe);

  const loadMeInfo = useCallback(
    debounce(() => {
      dispatch(getMe());
    }, 500),
    []
  );

  const fetchDetails = async () => {
    await axios
      .get(`${backendServerBaseURL}/landing`)
      .then((data) => {
        setSocialLinks(data.data.payload.SocialLinks.value);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchDetails();
    if (me?.role == "User") {
      setShowAdmin(false);
      setShowSupport(false);
    }
  }, [me]);

  useEffect(() => {
    const storedSelectedMenu = localStorage.getItem("selectedMenu");

    if (storedSelectedMenu) {
      setselectedMenu(storedSelectedMenu);
    } else {
      // Default value when nothing is stored
    }
  }, []); // Empty dependency array, indicating the effect should run once on mount

  // useEffect(()=>{
  //   localStorage.setItem("selectedMenu", selectedMenu);

  // },[selectedMenu]);

  const handleSelectedMenu = (menu) => {
    // if (window.screen.width <= 1200) {
    //   handleToggleClick();
    // }

    setselectedMenu(menu);
    localStorage.setItem("selectedMenu", menu);
  };

  const handleButtonClick = () => {
    window.open(
      "https://chromewebstore.google.com/detail/lightningproxies-extensio/bgejnhbjikhldeggjhdojncghbfgopjn",
      "_blank"
    );
  };

  return (
    <div className="d-flex flex-column justify-content-between">
      <div className="d-flex flex-column">
        <div class="logo-box">
          <a href="/">
            <img
              src="/assets/images/logo.svg"
              alt="Logo"
              class="img-fluid logo-big d-light"
            />
            <img
              src="/assets/images/dak-mode/logo.svg"
              alt="Logo"
              class="img-fluid logo-big d-dark"
            />
            <img
              src="/assets/images/icons/favicon.svg"
              alt="Logo"
              class="img-fluid logo-small"
            />
          </a>

          <button
            class="btn btn-toggle"
            type="button"
            id="menuToggle"
            onClick={() => {
              setnavOpen(!navOpen);
              // handleToggleClick();
              handleCommonToggle();
            }}
          >
            <i class="fa fa-angle-left"></i>
          </button>
        </div>

        <div class="menu-set mt-0 border-0 ">
          <h6>MAIN MENU</h6>

          <ul>
            <li
              onClick={() => {
                handleSelectedMenu("dashboard");
              }}
            >
              <Link
                to={"/dashboard"}
                className={
                  selectedMenu === "dashboard" ? "active" : "not-active"
                }
              >
                <img
                  src="/assets/images/sidebar-iconsV2/home.svg"
                  alt="icon"
                  class="img-fluid active"
                />
                <img
                  src="/assets/images/sidebar-iconsV2/home-2.svg"
                  alt="icon"
                  class="img-fluid not-active"
                />
                <span>Dashboard</span>
              </Link>
            </li>
            <li
              onClick={() => {
                handleSelectedMenu("plans");
              }}
            >
              <Link
                to="/plans"
                className={selectedMenu === "plans" ? "active" : "not-active"}
              >
                <img
                  src="/assets/images/sidebar-iconsV2/purchase-plan.svg"
                  alt="icon"
                  class="img-fluid active"
                />
                <img
                  src="/assets/images/sidebar-iconsV2/purchase-plan-2.svg"
                  alt="icon"
                  class="img-fluid not-active"
                />
                <span>Purchase Plan</span>
              </Link>
            </li>
            <li
              onClick={() => {
                handleSelectedMenu("topup");
              }}
            >
              <Link
                to="/topup"
                className={selectedMenu === "topup" ? "active" : "not-active"}
              >
                <img
                  src="/assets/images/sidebar-iconsV2/topup.svg"
                  alt="icon"
                  class="img-fluid active"
                />
                <img
                  src="/assets/images/sidebar-iconsV2/topup-2.svg"
                  alt="icon"
                  class="img-fluid not-active"
                />
                <span>Deposit Balance</span>
              </Link>
            </li>
            <li
              onClick={() => {
                handleSelectedMenu("invoices");
              }}
            >
              <Link
                to="/invoices"
                className={
                  selectedMenu === "invoices" ? "active" : "not-active"
                }
              >
                <img
                  src="/assets/images/sidebar-iconsV2/invoices.svg"
                  alt="icon"
                  class="img-fluid active"
                />
                <img
                  src="/assets/images/sidebar-iconsV2/invoices-2.svg"
                  alt="icon"
                  class="img-fluid not-active"
                />
                <span>Invoices</span>
              </Link>
            </li>
            <li
              onClick={() => {
                handleSelectedMenu("proxyusage");
              }}
            >
              <Link
                to="/proxy-usage"
                className={
                  selectedMenu === "proxyusage" ? "active" : "not-active"
                }
              >
                <img
                  src="/assets/images/sidebar-iconsV2/usage-log.svg"
                  alt="icon"
                  class="img-fluid active"
                />
                <img
                  src="/assets/images/sidebar-iconsV2/usage-log-2.svg"
                  alt="icon"
                  class="img-fluid not-active"
                />
                <span>Proxy Usage Log</span>
              </Link>
            </li>
            <li
              onClick={() => {
                handleSelectedMenu("subusers");
              }}
            >
              <Link
                to="/subuser-management"
                className={
                  selectedMenu === "subusers" ? "active" : "not-active"
                }
              >
                <img
                  src="/assets/images/sidebar-iconsV2/subusers.svg"
                  alt="icon"
                  class="img-fluid active"
                />
                <img
                  src="/assets/images/sidebar-iconsV2/subusers-2.svg"
                  alt="icon"
                  class="img-fluid not-active"
                />
                <span>Sub-users</span>
                <span className="ms-2 badge-custom-001">New</span>
              </Link>
            </li>
          </ul>
        </div>

        <div class="menu-set">
          <h6>REFERRALS</h6>
          <ul>
            <li
              onClick={() => {
                handleSelectedMenu("referal");
              }}
            >
              <Link
                to="/referral"
                className={selectedMenu === "referal" ? "active" : "not-active"}
              >
                <img
                  src="/assets/images/sidebar-iconsV2/referall.svg"
                  alt="icon"
                  class="img-fluid active"
                />
                <img
                  src="/assets/images/sidebar-iconsV2/referall-2.svg"
                  alt="icon"
                  class="img-fluid not-active"
                />
                <span>Referral</span>
              </Link>
            </li>
          </ul>
        </div>

        <div class="menu-set">
          <h6>SUPPORT</h6>
          <ul>
            <li
              onClick={() => {
                handleSelectedMenu("faq");
              }}
            >
              <Link
                to="/faq"
                className={selectedMenu === "faq" ? "active" : "not-active"}
              >
                <img
                  src="/assets/images/sidebar-iconsV2/faqs.svg"
                  alt="icon"
                  class="img-fluid active"
                />
                <img
                  src="/assets/images/sidebar-iconsV2/faqs-2.svg"
                  alt="icon"
                  class="img-fluid not-active"
                />
                <span>FAQs</span>
              </Link>
            </li>
            {/*           <li
              onClick={() => {
                handleSelectedMenu("api-docs");
              }}
            >
              <Link to="/api-docs" className={selectedMenu === "api-docs" ? "active" : "not-active"}>
                <img src="/assets/images/sidebar-iconsV2/api-2.svg" alt="icon" class="img-fluid active" />
                <img src="/assets/images/sidebar-iconsV2/api.svg" alt="icon" class="img-fluid not-active" />
                <span>API Docs</span>
              </Link>
            </li> */}
            <li>
              <Link to="/guide">
                <img
                  src="/assets/images/sidebar-iconsV2/proxyGuide.svg"
                  alt="icon"
                  class="img-fluid active"
                />
                <img
                  src="/assets/images/sidebar-iconsV2/proxyGuide-2.svg"
                  alt="icon"
                  class="img-fluid not-active"
                />
                <span>Proxy Guide</span>
                <span className="ms-2 badge-custom-001">Updated</span>
              </Link>
            </li>
          </ul>
        </div>

        <div class="menu-set">
          <h6>RESELLER</h6>
          <ul>
            <li>
              <Link to="https://resell.lightningproxies.net/login">
                <img
                  src="/assets/images/sidebar-iconsV2/reseller.svg"
                  alt="icon"
                  class="img-fluid active"
                />
                <img
                  src="/assets/images/sidebar-iconsV2/reseller-2.svg"
                  alt="icon"
                  class="img-fluid not-active"
                />
                <span>Dashboard</span>
              </Link>
            </li>
            <li
              onClick={() => {
                handleSelectedMenu("reseller-docs");
              }}
            >
              <Link
                to="/reseller/purchase"
                className={
                  selectedMenu === "reseller-docs" ? "active" : "not-active"
                }
              >
                <img
                  src="/assets/images/sidebar-iconsV2/resellerApi.svg"
                  alt="icon"
                  class="img-fluid active"
                />
                <img
                  src="/assets/images/sidebar-iconsV2/resellerApi-2.svg"
                  alt="icon"
                  class="img-fluid not-active"
                />
                <span>API Docs</span>
              </Link>
            </li>
          </ul>
        </div>

        <div class="menu-set">
          <h6>CONNECT WITH US</h6>
          <ul>
            <li>
              <a
                href={
                  SocialLinks?.find((s) => s.social === "Discord")?.link || ""
                }
              >
                <img
                  src="/assets/images/sidebar-iconsV2/discord.svg"
                  alt="icon"
                  class="img-fluid active"
                />
                <img
                  src="/assets/images/sidebar-iconsV2/discord-2.svg"
                  alt="icon"
                  class="img-fluid not-active"
                />
                <span>Join Discord Server</span>
              </a>
            </li>

            <li>
              <a
                href={
                  SocialLinks?.find((s) => s.social === "Telegram")?.link || ""
                }
              >
                <img
                  src="/assets/images/sidebar-icons/telegram-2.svg"
                  alt="icon"
                  class="img-fluid active"
                />
                <img
                  src="/assets/images/sidebar-icons/telegram.svg"
                  alt="icon"
                  class="img-fluid not-active"
                />
                <span>Join Telegram Channel</span>
              </a>
            </li>
          </ul>
          {navOpen && (
            <div className="custom-sidebar-card-001">
              <div className="custom-sidebar-header-002">
                <img
                  src="/logo1.svg"
                  alt="Logo"
                  className="custom-sidebar-logo-003"
                />
                <span className="custom-sidebar-title-004">
                  LightningProxies Extension
                </span>
                <span className="custom-sidebar-new-008">NEW</span>
              </div>
              <p className="custom-sidebar-description-005">
                A proxy extension enhances online privacy by routing traffic
                through an intermediary server.
              </p>
              <button
                className="custom-sidebar-button-006"
                onClick={handleButtonClick}
              >
                <img
                  src="/assets/images/chrome.svg"
                  alt="Chrome"
                  className="custom-button-icon-007"
                />
                Add to Chrome
              </button>
            </div>
          )}

          {navOpen &&
            !showAdmin &&
            (me?.role === "Administrator" || me?.role === "Manager") && (
              <button
                className={"btn btn-primary  text-center  text-white mt-lg-5 "}
                style={{
                  width: "-webkit-fill-available",
                  padding: "0.3rem 0.75rem",
                }}
                onClick={() => {
                  setShowAdmin(true);
                  localStorage.setItem("showAdmin", "true");
                  localStorage.setItem("selectedMenu", "statistics");
                  window.location.href = "/admin/dashboard";
                }}
              >
                Go to Admin Panel
              </button>
            )}

          {navOpen && !showAdmin && me?.role === "Support" && (
            <button
              className={"btn btn-danger  text-center  text-white mt-lg-5 "}
              style={{
                width: "-webkit-fill-available",
                padding: "0.3rem 0.75rem",
              }}
              onClick={() => {
                setShowSupport(true);
                localStorage.setItem("showSupport", "true");
                localStorage.setItem("selectedMenu", "trialpage");
                navigate("/support/trialpage");
              }}
            >
              Go to Support Panel
            </button>
          )}
        </div>
      </div>

      <div class="sidebar-ftr mt-5">
        <ul>
          <li>
            <a href="/terms-of-service">Terms of Service</a>
          </li>
          <li>
            <span>|</span>
          </li>
          <li>
            <a href="/privacy-policy">Privacy Policy</a>
          </li>
        </ul>

        <ul>
          <li>
            <img
              src={"/assets/images/sidebar-icons/social-01.svg"}
              alt="icon"
              className="img-fluid"
              onClick={() => {
                const targetLink =
                  SocialLinks?.find((s) => s.social === "Telegram")?.link || "";
                window.open(targetLink, "_self");
              }}
              style={{ marginRight: "1rem", cursor: "pointer" }}
            />
          </li>
          <li>
            <img
              src={"/assets/images/sidebar-icons/social-02.svg"}
              alt="icon"
              className="img-fluid"
              onClick={() => {
                const targetLink =
                  SocialLinks?.find((s) => s.social === "Instagram")?.link ||
                  "";
                window.open(targetLink, "_self");
              }}
              style={{ marginRight: "1rem", cursor: "pointer" }}
            />
          </li>
          <li>
            <img
              src={"/assets/images/sidebar-icons/social-03.svg"}
              alt="icon"
              className="img-fluid"
              onClick={() => {
                const targetLink =
                  SocialLinks?.find((s) => s.social === "Discord")?.link || "";
                window.open(targetLink, "_self");
              }}
              style={{ marginRight: "1rem", cursor: "pointer" }}
            />
          </li>
          <li>
            <img
              src={"/assets/images/sidebar-icons/social-04.svg"}
              alt="icon"
              className="img-fluid"
              onClick={() => {
                const targetLink =
                  SocialLinks?.find((s) => s.social === "Twitter")?.link || "";
                window.open(targetLink, "_self");
              }}
              style={{ marginRight: "1rem", cursor: "pointer" }}
            />
          </li>
          <li>
            <img
              src={"/assets/images/fb.svg"}
              alt="icon"
              className="img-fluid"
              onClick={() => {
                const targetLink =
                  SocialLinks?.find((s) => s.social === "Facebook")?.link || "";
                window.open(targetLink, "_self");
              }}
              style={{ marginRight: "1rem", cursor: "pointer" }}
            />
          </li>

          {/* {SocialLinks?.map((s, index) => (
            <li>
              <Link to={s.link}>
                <img
                  src={
                    s.social == "Telegram"
                      ? "/assets/images/sidebar-icons/social-01.svg"
                      : s.social == "Instagram"
                      ? "/assets/images/sidebar-icons/social-02.svg"
                      : s.social == "Twitter"
                      ? "/assets/images/sidebar-icons/social-04.svg"
                      : s.social == "Facebook"
                      ? "/assets/images/fb.svg"
                      : s.social == "Discord"
                      ? "/assets/images/sidebar-icons/social-03.svg"
                      : ""
                  }
                  alt="icon"
                  className="img-fluid"
                  onClick={() => {
                    const targetLink = s.link;
                    window.open(targetLink, "_self");
                  }}
                  style={{ marginRight: "1rem", cursor: "pointer" }}
                />
              </Link>
            </li>
          ))} */}
        </ul>
      </div>
    </div>
  );
};

export default UserSidebar;
