import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import loadable from "@loadable/component";

// layouts
import LandingLayout from "./LandingLayout";
import Layout from "./Layout";
import UserLayout from "./UserLayout";
import GuideLayout from "./guideLayout";
import ResellerLayout from "./resellerLayout";
import PublicApi from "./publicapiLayout";

// themes
import AuthTheme from "../theme/AuthTheme";

// auth
const Login = loadable(() => import("../Pages/authentication/Login"));
const Signup = loadable(() => import("../Pages/authentication/Signup"));
const ProcessVerifyEmail = loadable(
  () => import("../Pages/authentication/ProcessVerifyEmail")
);
const VerifyEmail = loadable(
  () => import("../Pages/authentication/VerifyEmail")
);
const ForgotPassword = loadable(
  () => import("../Pages/authentication/ForgotPassword")
);
const ForgotPasswordLinkSentSuccessfully = loadable(
  () => import("../Pages/authentication/ForgotPasswordLinkSentSuccessfully")
);
const ChangePassword = loadable(
  () => import("../Pages/authentication/ChangePassword")
);
const PasswordChangedSuccessfully = loadable(
  () => import("../Pages/authentication/PasswordChangedSuccessfully")
);
const TwoStepVerification = loadable(
  () => import("../Pages/authentication/TwoStepVerification")
);

// Reseller API Docs
const ResellerPurchase = loadable(() => import("../Pages/reseller/purchase"));
const ResellerListResi = loadable(
  () => import("../Pages/reseller/proxy-list/residential")
);
const ResellerListIpv6 = loadable(
  () => import("../Pages/reseller/proxy-list/ipv6")
);
const ResellerListData = loadable(
  () => import("../Pages/reseller/proxy-list/datacenter")
);
const ResellerListMobile = loadable(
  () => import("../Pages/reseller/proxy-list/mobile")
);
const ResellerListisp = loadable(
  () => import("../Pages/reseller/proxy-list/isp")
);
const ResellerListMass = loadable(
  () => import("../Pages/reseller/proxy-list/mass-residential")
);
const ResellerWhitelistipv6 = loadable(
  () => import("../Pages/reseller/whitelist/ipv6")
);
const ResellerWhitelistdatacenter = loadable(
  () => import("../Pages/reseller/whitelist/datacenter")
);
const ResellerGeneralinfo = loadable(
  () => import("../Pages/reseller/generalinfo")
);
const ResellerGBipv6 = loadable(
  () => import("../Pages/reseller/gigabyte/ipv6")
);
const ResellerGBresidential = loadable(
  () => import("../Pages/reseller/gigabyte/residential")
);
const ResellerGBmobile = loadable(
  () => import("../Pages/reseller/gigabyte/mobile")
);
const ResellerResiCountry = loadable(
  () => import("../Pages/reseller/Resi_Locations/Country")
);
const ResellerResiState = loadable(
  () => import("../Pages/reseller/Resi_Locations/State")
);
const ResellerResiCity = loadable(
  () => import("../Pages/reseller/Resi_Locations/city")
);
const ResellerResiISP = loadable(
  () => import("../Pages/reseller/Resi_Locations/isp")
);
const ResellerPlanextend = loadable(
  () => import("../Pages/reseller/extend/planextend")
);
const ResellerCalculateextend = loadable(
  () => import("../Pages/reseller/extend/calculate")
);
const ResellerChanger = loadable(
  () => import("../Pages/reseller/change_username/change")
);

// landing
const LandingPage = loadable(() => import("../Pages/landing/LandingPage"));
const LandingAboutUs = loadable(
  () => import("../Pages/landing/LandingAboutUs")
);
const LandingPricing = loadable(
  () => import("../Pages/landing/LandingPricing")
);
const LandingStatus = loadable(() => import("../Pages/landing/StatusPage"));
const LandingFaq = loadable(() => import("../Pages/landing/LandingFaq"));
const Blogs = loadable(() => import("../Pages/landing/Blogs"));
const Blog = loadable(() => import("../Pages/landing/Blog"));
const Reseller = loadable(() => import("../Pages/landing/Reseller"));
const Tos = loadable(() => import("../Pages/landing/Tos"));
const PrivacyPolicy = loadable(() => import("../Pages/landing/PrivacyPolicy"));
const PrivacyPolicyExtension = loadable(
  () => import("../Pages/landing/PrivacyPolicyExtension")
);
const CookiePolicy = loadable(() => import("../Pages/landing/CookiePolicy"));
const UserFaq = loadable(() => import("../Pages/landing/UserFaq"));
const UserTos = loadable(() => import("../Pages/landing/UserTos"));
const Partners = loadable(() => import("../Pages/landing/Partners"));

// user
const MyProfile = loadable(() => import("../Pages/MyProfile"));
const PurchasePlan = loadable(() => import("../Pages/PurchasePlan"));
const Topup = loadable(() => import("../Pages/Topup"));
const Bill = loadable(() => import("../Pages/Bill"));
const Dashboard = loadable(() => import("../Pages/Dashboard"));
const Invoices = loadable(() => import("../Pages/Invoices"));
const UserPlans = loadable(() => import("../Pages/UserPlans"));
const PaymentSuccess = loadable(() => import("../Pages/PaymentSuccess"));
const PaymentFailed = loadable(() => import("../Pages/PaymentFailed"));
const UnsubMail = loadable(() => import("../Pages/UnsubMail"));
const Api_Docs = loadable(() => import("../Pages/Api_Docs"));
const Referal = loadable(() => import("../Pages/Referal"));
const SubUsers = loadable(() => import("../Pages/SubUsers"));

const Proxyusage = loadable(() => import("../Pages/Proxyusage"));

const PlanGenerator = loadable(() => import("../Pages/PlanGenerator"));
const Reseller_Docs = loadable(() => import("../Pages/Reseller_Docs"));

// Guide
const Howdoiuse = loadable(() => import("../Pages/guide/accessproxy"));
// PC
const LightningProxiesExtension = loadable(
  () => import("../Pages/guide/PC/LightningProxiesExtension")
);
const SwitchyOmegaProxy = loadable(
  () => import("../Pages/guide/PC/SwitchyOmegaProxy")
);
const Bitbrowser = loadable(() => import("../Pages/guide/PC/BitBrowser"));
const Dolphin = loadable(() => import("../Pages/guide/PC/Dolphin"));
const AdsPower = loadable(() => import("../Pages/guide/PC/Adspower"));
// Android
const Android = loadable(() => import("../Pages/guide/Android/android"));
// iPhone
const IPhone = loadable(() => import("../Pages/guide/IPhone/iphone"));
// SUBSCRIPTIONS
const ExtendMyPlan = loadable(
  () => import("../Pages/guide/Subscriptions/extendMyPlan")
);
const UseSticky = loadable(
  () => import("../Pages/guide/Subscriptions/useSticky")
);
const ChangeCountry = loadable(
  () => import("../Pages/guide/Subscriptions/changeCountry")
);
const RenewAutoPlan = loadable(
  () => import("../Pages/guide/Subscriptions/renewAutoPlan")
);
const Subusers = loadable(
  () => import("../Pages/guide/Subscriptions/subusers")
);

const CreateAPi = loadable(() => import("../Pages/guide/API/CreateApi"));

const IntegrationCodes = loadable(
  () => import("../Pages/guide/API/IntegrationCodes")
);

const ProxyChecker = loadable(
  () => import("../Pages/guide/Tools/proxychecker")
);

// support
const SupportPage = loadable(() => import("../Pages/support/trialpage"));
const TrialSupportPage = loadable(
  () => import("../Pages/support/addtrialpage")
);

// admin
const Productmanage = loadable(() => import("../Pages/admin/Productmanage"));
const Productapi = loadable(() => import("../Pages/admin/Productapi"));
const Giftmanage = loadable(() => import("../Pages/admin/Giftmanagement"));
const AdminDashboard = loadable(() => import("../Pages/admin/AdminDashboard"));
const Adminbill = loadable(() => import("../Pages/admin/adminbill"));
const CouponManagement = loadable(
  () => import("../Pages/admin/CouponManagement")
);
const PartnersLinksManagement = loadable(
  () => import("../Pages/admin/PartnersManagement")
);
const CreatePartnersLinks = loadable(
  () => import("../Pages/admin/CreatePartnersLink")
);
const CreateNewCoupon = loadable(
  () => import("../Pages/admin/CreateNewCoupon")
);
const Raccounts = loadable(() => import("../Pages/admin/Accounts/Raccounts"));
const Accounts = loadable(() => import("../Pages/admin/Accounts/Accounts"));
const CreateNewAccount = loadable(
  () => import("../Pages/admin/Accounts/CreateNewAccount")
);
const CreateNewReseller = loadable(
  () => import("../Pages/admin/Accounts/CreateNewReseller")
);
const EditAccount = loadable(
  () => import("../Pages/admin/Accounts/EditAccount")
);
const ReditAccount = loadable(
  () => import("../Pages/admin/Accounts/ReditAccount")
);
const EditAccountBalance = loadable(
  () => import("../Pages/admin/Accounts/EditAccountBalance")
);
const ViewAccounts = loadable(
  () => import("../Pages/admin/Accounts/ViewAccounts")
);
const EditSubscription = loadable(
  () => import("../Pages/admin/EditSubscription")
);
const CreateNewPlan = loadable(
  () => import("../Pages/admin/Plans/CreateNewPlan")
);
const Plans = loadable(() => import("../Pages/admin/Plans/Plans"));
const Subscriptions = loadable(() => import("../Pages/admin/Subscriptions"));
const AddNewSubscription = loadable(
  () => import("../Pages/admin/Accounts/AddNewSubscription")
);
const AdminBlogs = loadable(() => import("../Pages/admin/Blogs/AdminBlogs"));
const CreateBlog = loadable(() => import("../Pages/admin/Blogs/CreateBlog"));
const Category = loadable(() => import("../Pages/admin/Blogs/Category"));
const Mail = loadable(() => import("../Pages/admin/Mail"));

const Settings = loadable(() => import("../Pages/admin/Settings"));

// Public API
const Public_Docs = loadable(() => import("../Pages/PublicApi/PublicAPI"));
const Public_info = loadable(() => import("../Pages/PublicApi/ProxyInfo"));
const Public_whitelist = loadable(
  () => import("../Pages/PublicApi/PublicWhitelist")
);
const Public_location = loadable(
  () => import("../Pages/PublicApi/ResiLocation")
);
const Public_UsageLogs = loadable(
  () => import("../Pages/PublicApi/ProxyUsage")
);

// other
const Page404 = loadable(() => import("../Pages/errors/Page404"));

const RedirectToHome = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    navigate("/");
  }, [navigate]);

  return null;
};

const MainRoutes = () => {
  return (
    <Routes>
      {/* Redirect Routes */}
      <Route path="/location-proxy/usa" element={<RedirectToHome />} />
      <Route path="/location-proxy/germany" element={<RedirectToHome />} />
      <Route path="/location-proxy/uk" element={<RedirectToHome />} />
      <Route path="/location-proxy/canada" element={<RedirectToHome />} />
      <Route path="/location-proxy/china" element={<RedirectToHome />} />
      <Route path="/use-cases/web-scraping" element={<RedirectToHome />} />
      <Route path="/use-cases/serp-monitoring" element={<RedirectToHome />} />
      <Route path="/use-cases/brand-protection" element={<RedirectToHome />} />
      <Route path="/use-cases/market-research" element={<RedirectToHome />} />
      <Route path="/use-cases/ad-verification" element={<RedirectToHome />} />
      <Route
        path="/products/residential-proxies"
        element={<RedirectToHome />}
      />
      <Route
        path="/products/rotating-datacenter-proxies"
        element={<RedirectToHome />}
      />
      <Route path="/products/static-isp-proxies" element={<RedirectToHome />} />
      <Route
        path="/products/rotating-ipv6-proxies"
        element={<RedirectToHome />}
      />

      {/* Landing Pages */}
      <Route
        path="/"
        element={
          <LandingLayout>
            <AuthTheme>
              <LandingPage />
            </AuthTheme>
          </LandingLayout>
        }
      />
      <Route
        path="/about-us"
        element={
          <LandingLayout>
            <LandingAboutUs />
          </LandingLayout>
        }
      />
      <Route
        path="/pricing"
        element={
          <LandingLayout>
            <LandingPricing />
          </LandingLayout>
        }
      />
      <Route
        path="/status"
        element={
          <LandingLayout>
            <LandingStatus />
          </LandingLayout>
        }
      />
      <Route
        path="/faqs"
        element={
          <LandingLayout>
            <AuthTheme>
              <LandingFaq />
            </AuthTheme>
          </LandingLayout>
        }
      />
      <Route
        path="/terms-of-service"
        element={
          <LandingLayout>
            <Tos />
          </LandingLayout>
        }
      />
      <Route
        path="/terms-of-services"
        element={
          <UserLayout>
            <UserTos />
          </UserLayout>
        }
      />
      <Route
        path="/partners"
        element={
          <LandingLayout>
            <Partners />
          </LandingLayout>
        }
      />
      <Route
        path="/privacy-policy"
        element={
          <LandingLayout>
            <PrivacyPolicy />
          </LandingLayout>
        }
      />
      <Route
        path="/privacy-policy-extension"
        element={
          <LandingLayout>
            <PrivacyPolicyExtension />
          </LandingLayout>
        }
      />
      <Route
        path="/cookie-policy"
        element={
          <LandingLayout>
            <CookiePolicy />
          </LandingLayout>
        }
      />
      <Route
        path="/blogs"
        element={
          <LandingLayout>
            <AuthTheme>
              <Blogs />
            </AuthTheme>
          </LandingLayout>
        }
      />
      <Route
        path="/reseller"
        element={
          <LandingLayout>
            <Reseller />
          </LandingLayout>
        }
      />
      <Route
        path="/blog/:id"
        element={
          <LandingLayout>
            <Blog />
          </LandingLayout>
        }
      />

      {/* Authentication */}
      <Route
        path="/login"
        element={
          <AuthTheme>
            <Login />
          </AuthTheme>
        }
      />
      <Route
        path="/2-step-verification/:twoFactorToken"
        element={<TwoStepVerification />}
      />
      <Route
        path="/register"
        element={
          <AuthTheme>
            <Signup />
          </AuthTheme>
        }
      />
      <Route
        path="/verify-email/process/:verifyToken"
        element={<ProcessVerifyEmail />}
      />
      <Route path="/verify-email" element={<VerifyEmail />} />
      <Route
        path="/forgot-password"
        element={
          <AuthTheme>
            <ForgotPassword />
          </AuthTheme>
        }
      />
      <Route
        path="/forgot-password-link-sent-successfully"
        element={<ForgotPasswordLinkSentSuccessfully />}
      />
      <Route
        path="/change-password/:resetPasswordToken"
        element={
          <AuthTheme>
            <ChangePassword />
          </AuthTheme>
        }
      />
      <Route
        path="/password-changed-successfully"
        element={<PasswordChangedSuccessfully />}
      />

      {/* User Dashboard */}
      <Route
        path="/dashboard"
        element={
          <UserLayout>
            <Dashboard />
          </UserLayout>
        }
      />
      <Route
        path="/faq"
        element={
          <UserLayout>
            <UserFaq />
          </UserLayout>
        }
      />
      <Route
        path="/dashboard/payment-success/:subscriptionId"
        element={<PaymentSuccess />}
      />
      <Route path="/dashboard/payment-failed" element={<PaymentFailed />} />
      <Route path="/email-subscription/cancel" element={<UnsubMail />} />

      {/* Reseller Routes */}
      <Route
        path="/reseller/purchase"
        element={
          <ResellerLayout>
            <ResellerPurchase />
          </ResellerLayout>
        }
      />
      <Route
        path="/reseller/proxy-list/residential"
        element={
          <ResellerLayout>
            <ResellerListResi />
          </ResellerLayout>
        }
      />
      <Route
        path="/reseller/proxy-list/ipv6"
        element={
          <ResellerLayout>
            <ResellerListIpv6 />
          </ResellerLayout>
        }
      />
      <Route
        path="/reseller/proxy-list/datacenter"
        element={
          <ResellerLayout>
            <ResellerListData />
          </ResellerLayout>
        }
      />
      <Route
        path="/reseller/proxy-list/mobile"
        element={
          <ResellerLayout>
            <ResellerListMobile />
          </ResellerLayout>
        }
      />
      <Route
        path="/reseller/proxy-list/isp"
        element={
          <ResellerLayout>
            <ResellerListisp />
          </ResellerLayout>
        }
      />
      <Route
        path="/reseller/proxy-list/residential-mass"
        element={
          <ResellerLayout>
            <ResellerListMass />
          </ResellerLayout>
        }
      />
      <Route
        path="/reseller/whitelist/ipv6"
        element={
          <ResellerLayout>
            <ResellerWhitelistipv6 />
          </ResellerLayout>
        }
      />
      <Route
        path="/reseller/whitelist/datacenter"
        element={
          <ResellerLayout>
            <ResellerWhitelistdatacenter />
          </ResellerLayout>
        }
      />
      <Route
        path="/reseller/generalinfo"
        element={
          <ResellerLayout>
            <ResellerGeneralinfo />
          </ResellerLayout>
        }
      />
      <Route
        path="/reseller/gigabyte/ipv6"
        element={
          <ResellerLayout>
            <ResellerGBipv6 />
          </ResellerLayout>
        }
      />
      <Route
        path="/reseller/gigabyte/residential"
        element={
          <ResellerLayout>
            <ResellerGBresidential />
          </ResellerLayout>
        }
      />
      <Route
        path="/reseller/gigabyte/mobile"
        element={
          <ResellerLayout>
            <ResellerGBmobile />
          </ResellerLayout>
        }
      />
      <Route
        path="/reseller/residential/country"
        element={
          <ResellerLayout>
            <ResellerResiCountry />
          </ResellerLayout>
        }
      />
      <Route
        path="/reseller/residential/state"
        element={
          <ResellerLayout>
            <ResellerResiState />
          </ResellerLayout>
        }
      />
      <Route
        path="/reseller/residential/city"
        element={
          <ResellerLayout>
            <ResellerResiCity />
          </ResellerLayout>
        }
      />
      <Route
        path="/reseller/residential/isp"
        element={
          <ResellerLayout>
            <ResellerResiISP />
          </ResellerLayout>
        }
      />
      <Route
        path="/reseller/extend/plan-extend"
        element={
          <ResellerLayout>
            <ResellerPlanextend />
          </ResellerLayout>
        }
      />
      <Route
        path="/reseller/extend/extend-calculate"
        element={
          <ResellerLayout>
            <ResellerCalculateextend />
          </ResellerLayout>
        }
      />
      <Route
        path="/reseller/change/changeuserpass"
        element={
          <ResellerLayout>
            <ResellerChanger />
          </ResellerLayout>
        }
      />

      {/* User Routes */}
      <Route
        path="/invoices"
        element={
          <UserLayout>
            <Invoices />
          </UserLayout>
        }
      />
      <Route
        path="/generator/:subscriptionId"
        element={
          <UserLayout>
            <PlanGenerator />
          </UserLayout>
        }
      />
      <Route
        path="/plans"
        element={
          <UserLayout>
            <UserPlans />
          </UserLayout>
        }
      />
      <Route
        path="/purchase-plan/:planId/:value"
        element={
          <UserLayout>
            <PurchasePlan />
          </UserLayout>
        }
      />
      <Route
        path="/purchase-plan"
        element={
          <UserLayout>
            <PurchasePlan />
          </UserLayout>
        }
      />
      <Route
        path="/topup"
        element={
          <UserLayout>
            <Topup />
          </UserLayout>
        }
      />
      <Route
        path="/bill/:subscriptionId"
        element={
          <UserLayout>
            <Bill />
          </UserLayout>
        }
      />
      <Route
        path="/my-profile"
        element={
          <UserLayout>
            <MyProfile />
          </UserLayout>
        }
      />
      <Route
        path="/referral"
        element={
          <UserLayout>
            <Referal />
          </UserLayout>
        }
      />
      <Route
        path="/subuser-management"
        element={
          <UserLayout>
            <SubUsers />
          </UserLayout>
        }
      />
      <Route
        path="/proxy-usage"
        element={
          <UserLayout>
            <Proxyusage />
          </UserLayout>
        }
      />
      <Route
        path="/api-docs"
        element={
          <UserLayout>
            <Api_Docs />
          </UserLayout>
        }
      />

      {/* Public API */}
      <Route
        path="/customer-proxy-list"
        element={
          <PublicApi>
            <Public_Docs />
          </PublicApi>
        }
      />
      <Route
        path="/customer-proxy-info"
        element={
          <PublicApi>
            <Public_info />
          </PublicApi>
        }
      />
      <Route
        path="/customer-proxy-whitelist"
        element={
          <PublicApi>
            <Public_whitelist />
          </PublicApi>
        }
      />
      <Route
        path="/customer-proxy-location"
        element={
          <PublicApi>
            <Public_location />
          </PublicApi>
        }
      />
      <Route
        path="/publicapi-usage-logs"
        element={
          <PublicApi>
            <Public_UsageLogs />
          </PublicApi>
        }
      />

      {/* Guide */}
      <Route
        path="/guide"
        element={
          <GuideLayout>
            <Howdoiuse />
          </GuideLayout>
        }
      />
      <Route
        path="/guide/lightningproxies-extension"
        element={
          <GuideLayout>
            <LightningProxiesExtension />
          </GuideLayout>
        }
      />
      <Route
        path="/guide/switchy-omega-proxy"
        element={
          <GuideLayout>
            <SwitchyOmegaProxy />
          </GuideLayout>
        }
      />
      <Route
        path="/guide/bitbrowser"
        element={
          <GuideLayout>
            <Bitbrowser />
          </GuideLayout>
        }
      />
      <Route
        path="/guide/dolphin"
        element={
          <GuideLayout>
            <Dolphin />
          </GuideLayout>
        }
      />
      <Route
        path="/guide/adspower"
        element={
          <GuideLayout>
            <AdsPower />
          </GuideLayout>
        }
      />
      <Route
        path="/guide/android"
        element={
          <GuideLayout>
            <Android />
          </GuideLayout>
        }
      />
      <Route
        path="/guide/iphone"
        element={
          <GuideLayout>
            <IPhone />
          </GuideLayout>
        }
      />
      <Route
        path="/guide/extend-my-plan"
        element={
          <GuideLayout>
            <ExtendMyPlan />
          </GuideLayout>
        }
      />
      <Route
        path="/guide/sticky-proxies"
        element={
          <GuideLayout>
            <UseSticky />
          </GuideLayout>
        }
      />
      <Route
        path="/guide/country-settings"
        element={
          <GuideLayout>
            <ChangeCountry />
          </GuideLayout>
        }
      />
      <Route
        path="/guide/renew-auto-plan"
        element={
          <GuideLayout>
            <RenewAutoPlan />
          </GuideLayout>
        }
      />
      <Route
        path="/guide/subusers"
        element={
          <GuideLayout>
            <Subusers />
          </GuideLayout>
        }
      />
      <Route
        path="/guide/create-api-link"
        element={
          <GuideLayout>
            <CreateAPi />
          </GuideLayout>
        }
      />

      <Route
        path="/guide/integration-codes"
        element={
          <GuideLayout>
            <IntegrationCodes />
          </GuideLayout>
        }
      />

      <Route
        path="/guide/proxy-checker"
        element={
          <GuideLayout>
            <ProxyChecker />
          </GuideLayout>
        }
      />

      {/* Admin */}
      <Route
        path="/admin/dashboard"
        element={
          <Layout>
            <AdminDashboard />
          </Layout>
        }
      />
      <Route
        path="/admin/productmanage"
        element={
          <Layout>
            <Productmanage />
          </Layout>
        }
      />
      <Route
        path="/admin/productapi"
        element={
          <Layout>
            <Productapi />
          </Layout>
        }
      />
      <Route
        path="/admin/giftmanage"
        element={
          <Layout>
            <Giftmanage />
          </Layout>
        }
      />
      <Route
        path="/support/trialpage"
        element={
          <Layout>
            <SupportPage />
          </Layout>
        }
      />
      <Route
        path="/support/accounts/:userId/add-subscription"
        element={
          <Layout>
            <TrialSupportPage />
          </Layout>
        }
      />
      <Route
        path="/adminbill/:subscriptionId"
        element={
          <Layout>
            <Adminbill />
          </Layout>
        }
      />

      {/* Admin - Coupons */}
      <Route
        path="/admin/coupons"
        element={
          <Layout>
            <CouponManagement />
          </Layout>
        }
      />
      <Route
        path="/admin/partnersurl/statistic"
        element={
          <Layout>
            <PartnersLinksManagement />
          </Layout>
        }
      />
      <Route
        path="/admin/partnerslink/new"
        element={
          <Layout>
            <CreatePartnersLinks type={"new"} />
          </Layout>
        }
      />
      <Route
        path="/admin/coupons/new"
        element={
          <Layout>
            <CreateNewCoupon type={"new"} />
          </Layout>
        }
      />
      <Route
        path="/admin/coupons/edit/:couponId"
        element={
          <Layout>
            <CreateNewCoupon type={"edit"} />
          </Layout>
        }
      />

      {/* Admin - Invoices */}
      <Route
        path="/admin/invoices"
        element={
          <Layout>
            <Subscriptions />
          </Layout>
        }
      />
      <Route
        path="/admin/subscriptions/edit/:subscriptionId"
        element={
          <Layout>
            <EditSubscription />
          </Layout>
        }
      />

      {/* Admin - Plans */}
      <Route
        path="/admin/plans"
        element={
          <Layout>
            <Plans />
          </Layout>
        }
      />
      <Route
        path="/admin/plans/new"
        element={
          <Layout>
            <CreateNewPlan type={"new"} />
          </Layout>
        }
      />
      <Route
        path="/admin/plans/edit/:planId"
        element={
          <Layout>
            <CreateNewPlan type={"edit"} />
          </Layout>
        }
      />
      <Route
        path="/admin/reselleracc"
        element={
          <Layout>
            <Raccounts />
          </Layout>
        }
      />

      {/* Admin - Accounts */}
      <Route
        path="/admin/accounts"
        element={
          <Layout>
            <Accounts />
          </Layout>
        }
      />
      <Route
        path="/admin/accounts/new"
        element={
          <Layout>
            <CreateNewAccount />
          </Layout>
        }
      />
      <Route
        path="/admin/reseller/new"
        element={
          <Layout>
            <CreateNewReseller />
          </Layout>
        }
      />
      <Route
        path="/admin/accounts/:userId"
        element={
          <Layout>
            <ViewAccounts />
          </Layout>
        }
      />
      <Route
        path="/admin/accounts/:userId/add-subscription"
        element={
          <Layout>
            <AddNewSubscription />
          </Layout>
        }
      />
      <Route
        path="/admin/accounts/:userId/edit"
        element={
          <Layout>
            <EditAccount />
          </Layout>
        }
      />
      <Route
        path="/admin/reseller/:userId/edit"
        element={
          <Layout>
            <ReditAccount />
          </Layout>
        }
      />
      <Route
        path="/admin/accounts/:userId/balance"
        element={
          <Layout>
            <EditAccountBalance />
          </Layout>
        }
      />

      {/* Admin - Website Management */}
      <Route
        path="/admin/website-management"
        element={
          <Layout>
            <Settings />
          </Layout>
        }
      />
      <Route
        path="/admin/mail-sender"
        element={
          <Layout>
            <Mail />
          </Layout>
        }
      />
      <Route
        path="/admin/blogs"
        element={
          <Layout>
            <AdminBlogs />
          </Layout>
        }
      />
      <Route
        path="/admin/category"
        element={
          <Layout>
            <Category />
          </Layout>
        }
      />
      <Route
        path="/admin/blogs/create"
        element={
          <Layout>
            <CreateBlog />
          </Layout>
        }
      />
      <Route
        path="/admin/blogs/edit/:id"
        element={
          <Layout>
            <CreateBlog />
          </Layout>
        }
      />

      {/* 404 Page */}
      <Route
        path="*"
        element={
          <LandingLayout>
            <Page404 />
          </LandingLayout>
        }
      />
    </Routes>
  );
};

export default MainRoutes;
