let tempRecaptchKey = "6LfuvwYkAAAAAEEKBF7SNcjf9X996a99HmV9fQoD";
let tempClientKey =
  "220820702523-shjgukp5vtdk2mf3nujn56l6akb72rq9.apps.googleusercontent.com";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  console.log("dev");
} else {
  tempRecaptchKey = "6LfuvwYkAAAAAEEKBF7SNcjf9X996a99HmV9fQoD";
  tempClientKey =
    "220820702523-shjgukp5vtdk2mf3nujn56l6akb72rq9.apps.googleusercontent.com";
  // tempRecaptchKey = "6Lcodj8mAAAAABa5g7ONaW_qdZMHIfUpc-EacGXQ";
}

export let recaptchKey = tempRecaptchKey;
export let clientId = tempClientKey;
