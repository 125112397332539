import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMe, selectMe } from "../redux/slices/generalSlice";
import { ThemeContext } from "../App";

const UserNavbar = React.memo(({ handleMobileToggle }) => {
  const me = useSelector(selectMe);
  const themeContextValue = useContext(ThemeContext);

  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem("theme", "light") === "dark"
  );

  const handleCheckboxChange = () => {
    setIsDarkMode((prevMode) => !prevMode);
    // window.location.reload();
  };

  useEffect(() => {
    console.log(isDarkMode);
    const newTheme = isDarkMode ? "dark" : "light";
    console.log(newTheme);
    themeContextValue.setselectedTheme(newTheme);
    localStorage.setItem("theme", newTheme);

    if (isDarkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [isDarkMode]);

  return (
    <header class="header-part">
      <div class="breadcumb-box">
        <button
          type="button"
          class={"btn p-0 "}
          id="mobileToggle"
          onClick={() => handleMobileToggle()}
        >
          <img
            src="/assets/images/icons/bars.svg"
            alt="="
            class="img-fluid d-light"
          />
          <img
            src="/assets/images/icons/bars-dark.svg"
            alt="="
            class="img-fluid d-dark"
          />
        </button>
      </div>
    </header>
  );
});

export default UserNavbar;
