import axios from "axios";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    const tempConfig = { ...config };

    if (!tempConfig.headers.Authorization) {
      const token = localStorage.getItem("accessToken");

      if (token) {
        tempConfig.headers.Authorization = `Bearer ${token}`;
      } else {
        // Handle case when token is not available
        // For example, redirect to login page or show an error message
      }
    }

    return tempConfig;
  },
  (error) => {
    console.error("Request error:", error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },

  (error) => {
    console.error("Response error:", error.toJSON());
    console.log("Response data:", error.response);

    if (error.toJSON().message === "Network Error") {
      console.log("No internet connection");
      // Handle no internet connection
    }

    if (error.response && error.response.status === 500) {
      window.open("/500", "_self");
    }

    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.message === "jwt expired"
    ) {
      localStorage.clear();
      window.open("/", "_self");
    }

    if (
      error.response &&
      error.response.status === 403 &&
      error.response.data.message === "unauthorized"
    ) {
      localStorage.clear();
      window.open("/login", "_self");
    }

    return Promise.reject(error); // Reject with the original error for further handling
  }
);

export default axiosInstance;
