import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../assets/vendor/tom-select/dist/css/tom-select.bootstrap5.css";
import "../assets/vendor/bootstrap-icons/font/bootstrap-icons.css";
import { getMe, selectMe } from "../redux/slices/generalSlice";
import { Link, useNavigate } from "react-router-dom";
import { ThemeContext } from "../App";
import { ReactComponent as Moon } from "../assets/img/dashboard/moon.svg";
import { ReactComponent as Sun } from "../assets/img/dashboard/sun.svg";
import { useState } from "react";
import { backendServerBaseURL } from "../utils/backendServerBaseURL";
import axios from "axios";
import {
  readNotification,
  readNotifications,
} from "../redux/slices/adminSlice";
import { toast } from "react-toastify";

const Navbar = ({ screenview, isMobile }) => {
  const me = useSelector(selectMe);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [notificationBar, setNotificationBar] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const themeContextValue = useContext(ThemeContext);
  const errorNotify = (msg) => toast.error(msg);

  const getNotifications = async () => {
    try {
      const token = await localStorage.getItem("accessToken", "");

      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let response = await axios.get(
        `${backendServerBaseURL}/admin/notification`,
        config
      );

      if (
        response.status === 200 &&
        response.data.message === "Notifications list"
      ) {
        setNotificationList(response.data.payload.notifications);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const markRead = async (id) => {
    if (id) {
      await dispatch(readNotification({ id }));
    } else {
      await dispatch(readNotifications());
    }
    getNotifications({ errorNotify });
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <div>
      {!isMobile && (
        <div className="d-flex">
          <header
            id="header"
            className="navbar navbar-expand-lg navbar-height navbar-light navbar-container navbar-bordered"
            style={{
              position: "fixed",
              width: screenview ? "calc(100% - 270px)" : "calc(100% - 88px)",
              backgroundColor:
                themeContextValue.selectedTheme == "dark"
                  ? "#202325"
                  : "#FFFFFF",
              fontFamily: "Inter, sans-serif",
            }}
          >
            <div className="navbar-nav-wrap">
              <div className="navbar-nav-wrap-content-start">
                <div
                  className="rounded p-2 d-flex align-items-center align-self-center"
                  style={{
                    backgroundColor: "#377DFF",
                    marginRight: "0.5rem",
                    cursor: "pointer",
                  }}
                >
                  <div>
                    <i
                      className="bi-wallet-fill"
                      style={{ marginRight: "0.3rem", color: "#FFFFFF" }}
                    />
                  </div>

                  <div className="text-white">
                    <span style={{ fontWeight: "bold" }}>Balance: </span>{" "}
                    <span style={{ color: "#FFFFFF" }}>
                      ${me?.balance ? Math.round(me?.balance * 100) / 100 : "0"}
                    </span>{" "}
                  </div>
                </div>
              </div>
              <div className="navbar-nav-wrap-content-end">
                <ul className="navbar-nav">
                  <li className="navbar-nav">
                    <div
                      className="theme-view"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "1.5rem",
                      }}
                    >
                      <div className="sun-moon m-1">
                        <Sun />
                      </div>

                      <div className="form-check form-switch custom-switch m-0 p-0">
                        <input
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              themeContextValue.setselectedTheme("dark");
                              localStorage.setItem("theme", "dark");
                              window.location.reload();
                            } else {
                              themeContextValue.setselectedTheme("light");
                              localStorage.setItem("theme", "light");
                              window.location.reload();
                            }
                          }}
                          defaultChecked={
                            themeContextValue.selectedTheme === "dark"
                          }
                          className="form-check-input mx-1 p-0"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                        />
                      </div>
                      <div className="sun-moon m-1">
                        <Moon />
                      </div>
                    </div>
                  </li>

                  <li className="nav-item">
                    <div
                      className="dropdown"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <div id="dropdownMenuButton" data-bs-toggle="dropdown">
                        <div className="text-center position-relative me-2">
                          <i class="bi bi-bell-fill fs-2" />
                          {notificationList?.length > 0 && (
                            <i className="fa fa-circle fs-3 text-danger position-relative position-absolute translate-middle  start-75">
                              <span
                                style={{ fontSize: "0.5rem" }}
                                className=" text-white position-absolute start-50 top-50 translate-middle "
                              >
                                {notificationList ? notificationList.length : 0}
                              </span>
                            </i>
                          )}
                        </div>
                      </div>

                      <ul className="dropdown-menu dropdown-menu-end">
                        <li className="text-end">
                          <button
                            className="btn"
                            onClick={() => {
                              markRead();
                            }}
                          >
                            Mark all read
                          </button>
                        </li>
                        <hr style={{ margin: 0, marginBottom: "0.5rem" }} />
                        {notificationList.map((item, index) => {
                          return (
                            <li key={index} className="dropdown-item">
                              <Link to={"/admin/tickets/" + item.ticket}>
                                {item?.message}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div
                      className="dropdown"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <div id="dropdownMenuButton" data-bs-toggle="dropdown">
                        <div
                          className="avatar avatar-primary avatar-circle"
                          style={{ marginRight: "0.4rem" }}
                        >
                          <span className="avatar-initials">
                            <i class="bi-person-fill"></i>
                          </span>
                        </div>
                      </div>

                      <ul className="dropdown-menu dropdown-menu-end">
                        <li>
                          <a
                            className="dropdown-item px-2"
                            href="#"
                            onClick={() => {
                              navigate("/my-profile");
                            }}
                            style={{ color: "#FFFFFF" }}
                          >
                            Account Settings
                          </a>
                        </li>
                        <hr style={{ margin: 0, marginBottom: "0.5rem" }} />

                        <li>
                          <a
                            className="dropdown-item px-2"
                            href="#"
                            onClick={() => {
                              localStorage.removeItem("accessToken");
                              localStorage.removeItem("user");
                              localStorage.setItem("theme", "light");
                              window.open("/login", "_self");
                            }}
                          >
                            <span style={{ color: "red" }}>Logout</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </header>
        </div>
      )}
      <div
        className="col-sm-auto"
        style={{ display: "flex", alignItems: "center" }}
      >
        {isMobile && (
          <div className="navbar flex-fill d-flex">
            <div className="col-2">
              <i
                class="bi bi-list"
                data-bs-toggle="offcanvas"
                href="#offcanvasExample"
                role="button"
                style={{ fontSize: "1.5rem" }}
              ></i>
            </div>

            <div className="col-7">
              <div
                className="theme-view"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "1.5rem",
                }}
              >
                <div className="sun-moon m-1">
                  <Sun />
                </div>

                <div className="form-check form-switch custom-switch m-0 p-0">
                  <input
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        themeContextValue.setselectedTheme("dark");
                        localStorage.setItem("theme", "dark");
                        window.location.reload();
                      } else {
                        themeContextValue.setselectedTheme("light");
                        localStorage.setItem("theme", "light");
                        window.location.reload();
                      }
                    }}
                    defaultChecked={themeContextValue.selectedTheme === "dark"}
                    className="form-check-input mx-1 p-0"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
                <div className="sun-moon m-1">
                  <Moon />
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="d-flex flex-row align-items-center justify-content-between">
                <div
                  className="dropdown"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <div id="dropdownMenuButton" data-bs-toggle="dropdown">
                    <div className="text-center position-relative">
                      <i class="bi bi-bell-fill fs-2" />
                      {notificationList.length > 0 && (
                        <i className="fa fa-circle fs-3 text-danger position-relative position-absolute translate-middle  start-75">
                          <span
                            style={{ fontSize: "0.5rem" }}
                            className=" text-white position-absolute start-50 top-50 translate-middle "
                          >
                            {notificationList.length}
                          </span>
                        </i>
                      )}
                    </div>
                  </div>

                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a
                        className="dropdown-item px-2"
                        href="#"
                        onClick={() => {
                          navigate("/my-profile");
                        }}
                        style={{ color: "#FFFFFF" }}
                      >
                        Account Settings
                      </a>
                    </li>
                    <hr style={{ margin: 0, marginBottom: "0.5rem" }} />

                    <li>
                      <a
                        className="dropdown-item px-2"
                        href="#"
                        onClick={() => {
                          localStorage.removeItem("accessToken");
                          localStorage.removeItem("user");
                          localStorage.setItem("theme", "light");
                          window.open("/login", "_self");
                        }}
                      >
                        <span style={{ color: "red" }}>Logout</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  className="dropdown"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <div id="dropdownMenuButton" data-bs-toggle="dropdown">
                    <div
                      className="avatar avatar-primary avatar-circle"
                      style={{ marginRight: "0.4rem" }}
                    >
                      <span className="avatar-initials">
                        <i class="bi-person-fill"></i>
                      </span>
                    </div>
                  </div>

                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a
                        className="dropdown-item px-2"
                        href="#"
                        onClick={() => {
                          navigate("/my-profile");
                        }}
                        style={{ color: "#FFFFFF" }}
                      >
                        Account Settings
                      </a>
                    </li>
                    <hr style={{ margin: 0, marginBottom: "0.5rem" }} />

                    <li>
                      <a
                        className="dropdown-item px-2"
                        href="#"
                        onClick={() => {
                          localStorage.removeItem("accessToken");
                          localStorage.removeItem("user");
                          localStorage.setItem("theme", "light");
                          window.open("/login", "_self");
                        }}
                      >
                        <span style={{ color: "red" }}>Logout</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
