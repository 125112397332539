import React, { useEffect, useState, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { backendServerBaseURL } from "../utils/backendServerBaseURL";

const GuideSidebar = ({ handleCommonToggle }) => {
  const [SocialLinks, setSocialLinks] = useState([]);
  const [navOpen, setNavOpen] = useState(true);
  const [selectedMenu, setSelectedMenu] = useState("");
  const [openSubMenus, setOpenSubMenus] = useState({
    proxies: false,
    whitelist: false,
    gigabyteOperations: false, // Yeni alt menü durumu
    residentialLocation: false, // Yeni alt menü durumu
  });
  const location = useLocation();

  useEffect(() => {
    const storedSelectedMenu = localStorage.getItem("selectedMenu");
    if (storedSelectedMenu) {
      setSelectedMenu(storedSelectedMenu);
      if (storedSelectedMenu.startsWith("proxies")) {
        setOpenSubMenus((prev) => ({ ...prev, proxies: true }));
      }
      if (storedSelectedMenu.startsWith("whitelist")) {
        setOpenSubMenus((prev) => ({ ...prev, whitelist: true }));
      }
      if (storedSelectedMenu.startsWith("gigabyte")) {
        setOpenSubMenus((prev) => ({ ...prev, gigabyteOperations: true }));
      }
      if (storedSelectedMenu.startsWith("residentialLocation")) {
        setOpenSubMenus((prev) => ({ ...prev, residentialLocation: true }));
      }
    }
  }, []);

  useEffect(() => {
    const path = location.pathname;
    const mapping = {
      "reseller/proxy-list/residential": "proxies-residentiallist",
      "reseller/proxy-list/ipv6": "proxies-ipv6list",
      "reseller/proxy-list/datacenter": "proxies-datalist",
      "reseller/proxy-list/mobile": "proxies-mobilelist",
      "reseller/proxy-list/isp": "proxies-isplist",
      "reseller/proxy-list/residential-mass": "proxies-massresi",
      "reseller/whitelist/ipv6": "whitelist-ipv6white",
      "reseller/whitelist/datacenter": "whitelist-datawhite",
      "reseller/generalinfo": "generalinfo",
      "reseller/purchase": "dashboard1",
      "reseller/gigabyte/ipv6": "gigabyte-ipv6",
      "reseller/gigabyte/residential": "gigabyte-residential",
      "reseller/gigabyte/mobile": "gigabyte-mobile",
      "reseller/residential/country": "residentialLocation-country",
      "reseller/residential/state": "residentialLocation-state",
      "reseller/residential/city": "residentialLocation-city",
      "reseller/residential/isp": "residentialLocation-isp",
      "reseller/extend/extend-calculate": "calculateextend",
      "reseller/extend/plan-extend": "extendplan",
      "reseller/change/changeuserpass": "changetype",
    };

    for (const key in mapping) {
      if (path.includes(key)) {
        setSelectedMenu(mapping[key]);
        if (mapping[key].startsWith("proxies")) {
          setOpenSubMenus((prev) => ({ ...prev, proxies: true }));
        } else if (mapping[key].startsWith("whitelist")) {
          setOpenSubMenus((prev) => ({ ...prev, whitelist: true }));
        } else if (mapping[key].startsWith("gigabyte")) {
          setOpenSubMenus((prev) => ({ ...prev, gigabyteOperations: true }));
        } else if (mapping[key].startsWith("residentialLocation")) {
          setOpenSubMenus((prev) => ({ ...prev, residentialLocation: true }));
        }
        return;
      }
    }
    setSelectedMenu(""); // Default state
  }, [location]);

  const handleSelectedMenu = (menu) => {
    setSelectedMenu(menu);
    localStorage.setItem("selectedMenu", menu);

    if (menu.startsWith("proxies")) {
      setOpenSubMenus((prev) => ({ ...prev, proxies: true }));
    } else if (menu.startsWith("whitelist")) {
      setOpenSubMenus((prev) => ({ ...prev, whitelist: true }));
    } else if (menu.startsWith("gigabyte")) {
      setOpenSubMenus((prev) => ({ ...prev, gigabyteOperations: true }));
    } else if (menu.startsWith("residentialLocation")) {
      setOpenSubMenus((prev) => ({ ...prev, residentialLocation: true }));
    } else {
      setOpenSubMenus((prev) => ({ ...prev }));
    }
  };

  const handleToggleSubMenu = useCallback((menu) => {
    setOpenSubMenus((prev) => ({ ...prev, [menu]: !prev[menu] }));
  }, []);

  useEffect(() => {
    fetchDetails();
  }, []);

  const fetchDetails = async () => {
    try {
      const { data } = await axios.get(`${backendServerBaseURL}/landing`);
      setSocialLinks(data.payload.SocialLinks.value);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="d-flex flex-column justify-content-between">
      <div className="d-flex flex-column">
        <div className="logo-box">
          <a href="/">
            <img
              src="/assets/images/logo.svg"
              alt="Logo"
              className="img-fluid logo-big d-light"
            />
            <img
              src="/assets/images/dak-mode/logo.svg"
              alt="Logo"
              className="img-fluid logo-big d-dark"
            />
            <img
              src="/assets/images/icons/favicon.svg"
              alt="Logo"
              className="img-fluid logo-small"
            />
          </a>

          <button
            className="btn btn-toggle"
            type="button"
            id="menuToggle"
            onClick={() => {
              setNavOpen(!navOpen);
              handleCommonToggle();
            }}
          >
            <i className="fa fa-angle-left"></i>
          </button>
        </div>

        <div className="menu-set mt-0 border-0">
          <h6>Reseller API</h6>

          <ul>
            <li onClick={() => handleSelectedMenu("dashboard1")}>
              <Link
                to={"/reseller/purchase"}
                className={
                  selectedMenu === "dashboard1" ? "active" : "not-active"
                }
              >
                <span className="badge reseller-post me-2">POST</span>
                <span>Purchase Plan</span>
              </Link>
            </li>
            <hr />
            <li onClick={() => handleToggleSubMenu("proxies")}>
              <Link
                to="#"
                className={
                  selectedMenu.startsWith("proxies") ? "active" : "not-active"
                }
              >
                <span className="badge reseller-menu me-2">MENU</span>
                <span>Proxies List</span>
              </Link>
              {openSubMenus.proxies && (
                <ul className="sub-menu">
                  {[
                    {
                      name: "Residential proxies",
                      route: "/reseller/proxy-list/residential",
                      id: "proxies-residentiallist",
                    },
                    {
                      name: "IPv6 Proxies",
                      route: "/reseller/proxy-list/ipv6",
                      id: "proxies-ipv6list",
                    },
                    {
                      name: "Datacenter Proxies",
                      route: "/reseller/proxy-list/datacenter",
                      id: "proxies-datalist",
                    },
                    {
                      name: "Mobile Proxies",
                      route: "/reseller/proxy-list/mobile",
                      id: "proxies-mobilelist",
                    },
                    {
                      name: "ISP Proxies",
                      route: "/reseller/proxy-list/isp",
                      id: "proxies-isplist",
                    },
                    {
                      name: "Residential Mass Proxies",
                      route: "/reseller/proxy-list/residential-mass",
                      id: "proxies-massresi",
                    },
                  ].map(({ name, route, id }) => (
                    <li key={id} onClick={() => handleSelectedMenu(id)}>
                      <Link
                        to={route}
                        className={
                          selectedMenu === id ? "active" : "not-active"
                        }
                      >
                        <span className="badge reseller-get me-2">GET</span>
                        <span>{name}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>

            <hr />
            <li onClick={() => handleToggleSubMenu("whitelist")}>
              <Link
                to="#"
                className={
                  selectedMenu.startsWith("whitelist") ? "active" : "not-active"
                }
              >
                <span className="badge reseller-menu me-2">MENU</span>
                <span>Whitelist API</span>
              </Link>
              {openSubMenus.whitelist && (
                <ul className="sub-menu">
                  {[
                    {
                      name: "IPv6",
                      route: "/reseller/whitelist/ipv6",
                      id: "whitelist-ipv6white",
                    },
                    {
                      name: "Datacenter",
                      route: "/reseller/whitelist/datacenter",
                      id: "whitelist-datawhite",
                    },
                  ].map(({ name, route, id }) => (
                    <li key={id} onClick={() => handleSelectedMenu(id)}>
                      <Link
                        to={route}
                        className={
                          selectedMenu === id ? "active" : "not-active"
                        }
                      >
                        <span className="badge reseller-post me-2">POST</span>
                        <span>{name}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>

            <hr />
            <li onClick={() => handleToggleSubMenu("gigabyteOperations")}>
              <Link
                to="#"
                className={
                  selectedMenu.startsWith("gigabyteOperations")
                    ? "active"
                    : "not-active"
                }
              >
                <span className="badge reseller-menu me-2">MENU</span>
                <span>Gigabyte Operations</span>
              </Link>
              {openSubMenus.gigabyteOperations && (
                <ul className="sub-menu">
                  {[
                    {
                      name: "IPv6 Add/Remove",
                      route: "/reseller/gigabyte/ipv6",
                      id: "gigabyte-ipv6",
                    },
                    {
                      name: "Residential Add/Remove",
                      route: "/reseller/gigabyte/residential",
                      id: "gigabyte-residential",
                    },
                    {
                      name: "Mobile Add/Remove",
                      route: "/reseller/gigabyte/mobile",
                      id: "gigabyte-mobile",
                    },
                  ].map(({ name, route, id }) => (
                    <li key={id} onClick={() => handleSelectedMenu(id)}>
                      <Link
                        to={route}
                        className={
                          selectedMenu === id ? "active" : "not-active"
                        }
                      >
                        <span className="badge reseller-post me-2">POST</span>
                        <span>{name}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>

            <hr />
            <li onClick={() => handleToggleSubMenu("residentialLocation")}>
              <Link
                to="#"
                className={
                  selectedMenu.startsWith("residentialLocation")
                    ? "active"
                    : "not-active"
                }
              >
                <span className="badge reseller-menu me-2">MENU</span>
                <span>Residential Location Targeting</span>
              </Link>
              {openSubMenus.residentialLocation && (
                <ul className="sub-menu">
                  {[
                    {
                      name: "Country",
                      route: "/reseller/residential/country",
                      id: "residentialLocation-country",
                    },
                    {
                      name: "State",
                      route: "/reseller/residential/state",
                      id: "residentialLocation-state",
                    },
                    {
                      name: "City",
                      route: "/reseller/residential/city",
                      id: "residentialLocation-city",
                    },
                    {
                      name: "ISP",
                      route: "/reseller/residential/isp",
                      id: "residentialLocation-isp",
                    },
                  ].map(({ name, route, id }) => (
                    <li key={id} onClick={() => handleSelectedMenu(id)}>
                      <Link
                        to={route}
                        className={
                          selectedMenu === id ? "active" : "not-active"
                        }
                      >
                        <span className="badge reseller-post me-2">POST</span>
                        <span>{name}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>

            <hr />
            <li onClick={() => handleSelectedMenu("generalinfo")}>
              <Link
                to={"/reseller/generalinfo"}
                className={
                  selectedMenu === "generalinfo" ? "active" : "not-active"
                }
              >
                <span className="badge reseller-get me-2">GET</span>
                <span>Proxies Info</span>
              </Link>
            </li>
            <hr />
            <h6>Extend Plan</h6>
            <li onClick={() => handleSelectedMenu("calculateextend")}>
              <Link
                to={"/reseller/extend/extend-calculate"}
                className={
                  selectedMenu === "calculateextend" ? "active" : "not-active"
                }
              >
                <span className="badge reseller-post me-2">POST</span>
                <span>Calculate Price</span>
              </Link>
            </li>
            <li onClick={() => handleSelectedMenu("extendplan")}>
              <Link
                to={"/reseller/extend/plan-extend"}
                className={
                  selectedMenu === "extendplan" ? "active" : "not-active"
                }
              >
                <span className="badge reseller-patch me-2">PATCH</span>
                <span>Extend Plan</span>
              </Link>
            </li>
            <hr></hr>
            <h6>Change Type & User:pass</h6>
            <li onClick={() => handleSelectedMenu("changetype")}>
              <Link
                to={"/reseller/change/changeuserpass"}
                className={
                  selectedMenu === "changetype" ? "active" : "not-active"
                }
              >
                <span className="badge reseller-post me-2">POST</span>
                <span>Changer Credentials</span>
              </Link>
            </li>
          </ul>
        </div>

        <div className="menu-set">
          <h6>CONNECT WITH US</h6>
          <ul>
            <li>
              <a
                href={
                  SocialLinks?.find((s) => s.social === "Discord")?.link || ""
                }
              >
                <img
                  src="/assets/images/sidebar-icons/discord.svg"
                  alt="icon"
                  className="img-fluid"
                />
                <span>Join Discord Server</span>
              </a>
            </li>

            <li>
              <a
                href={
                  SocialLinks?.find((s) => s.social === "Telegram")?.link || ""
                }
              >
                <img
                  src="/assets/images/sidebar-icons/telegram.svg"
                  alt="icon"
                  className="img-fluid"
                />
                <span>Join Telegram Channel</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="sidebar-ftr mt-5">
        <ul>
          <li
            onClick={() => {
              handleSelectedMenu("terms-of-services");
            }}
          >
            <Link to="/terms-of-services">Terms of Service</Link>
          </li>
          <li>
            <span>|</span>
          </li>
          <li>
            <a href="/privacy-policy">Privacy Policy</a>
          </li>
        </ul>

        <ul>
          <li>
            <img
              src={"/assets/images/sidebar-icons/social-01.svg"}
              alt="icon"
              className="img-fluid"
              onClick={() => {
                const targetLink =
                  SocialLinks?.find((s) => s.social === "Telegram")?.link || "";
                window.open(targetLink, "_self");
              }}
              style={{ marginRight: "1rem", cursor: "pointer" }}
            />
          </li>
          <li>
            <img
              src={"/assets/images/sidebar-icons/social-02.svg"}
              alt="icon"
              className="img-fluid"
              onClick={() => {
                const targetLink =
                  SocialLinks?.find((s) => s.social === "Instagram")?.link ||
                  "";
                window.open(targetLink, "_self");
              }}
              style={{ marginRight: "1rem", cursor: "pointer" }}
            />
          </li>
          <li>
            <img
              src={"/assets/images/sidebar-icons/social-03.svg"}
              alt="icon"
              className="img-fluid"
              onClick={() => {
                const targetLink =
                  SocialLinks?.find((s) => s.social === "Discord")?.link || "";
                window.open(targetLink, "_self");
              }}
              style={{ marginRight: "1rem", cursor: "pointer" }}
            />
          </li>
          <li>
            <img
              src={"/assets/images/sidebar-icons/social-04.svg"}
              alt="icon"
              className="img-fluid"
              onClick={() => {
                const targetLink =
                  SocialLinks?.find((s) => s.social === "Twitter")?.link || "";
                window.open(targetLink, "_self");
              }}
              style={{ marginRight: "1rem", cursor: "pointer" }}
            />
          </li>
          <li>
            <img
              src={"/assets/images/fb.svg"}
              alt="icon"
              className="img-fluid"
              onClick={() => {
                const targetLink =
                  SocialLinks?.find((s) => s.social === "Facebook")?.link || "";
                window.open(targetLink, "_self");
              }}
              style={{ marginRight: "1rem", cursor: "pointer" }}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default GuideSidebar;
