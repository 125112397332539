import React, { useState, useContext, useEffect } from "react";
import { useRef } from "react";
import "../assets/css/Dashboard/style.css";
import { Link } from "react-router-dom";
import { ThemeContext } from "../App";
import _ from "lodash";
import { AdminContext } from "../Routes/Layout";
const Sidebar = ({ screenview, setScreenview, isMobile }) => {
  const themeContextValue = useContext(ThemeContext);
  const adminContextValue = useContext(AdminContext);
  const [selectedMenu, setselectedMenu] = useState("Statistics");
  const handleSelectedMenu = (menu) => {
    setselectedMenu(menu);
    localStorage.setItem("selectedMenu", menu);
  };

  const SwitchToUser = () => {
    adminContextValue.setShowAdmin(false);
    localStorage.setItem("showAdmin", "false");
    localStorage.setItem("selectedMenu", "dashboard");
    window.location.replace("/dashboard");
  };

  useEffect(() => {
    if (localStorage.getItem("faqVisited", null) === "1") {
      localStorage.setItem("faqVisited", "0");
      window.location.reload();
    }

    if (localStorage.getItem("selectedMenu")) {
      setselectedMenu(localStorage.getItem("selectedMenu", "Statistics"));
    } else {
      setselectedMenu(localStorage.setItem("selectedMenu", "Statistics"));
    }
  }, []);
  const NavContent = () => {
    const closeMobileMenuBtn = useRef(null);

    useEffect(() => {
      document.body.style.overflow = "auto";
    }, []);

    return (
      <>
        {/* Desktop nav header */}
        {!isMobile && (
          <header
            className="d-block"
            style={{ fontFamily: "Inter, sans-serif" }}
          >
            <div
              className="image-text"
              onClick={() => {
                setselectedMenu("Dashboard");
                // navigate("/dashboard");
                window.location.replace("/dashboard");
              }}
            >
              {screenview ? (
                <span className="image">
                  <img
                    src={
                      themeContextValue.selectedTheme === "dark"
                        ? "/logo-dark.svg"
                        : "/logo.svg"
                    }
                    style={{
                      minWidth: "12rem",
                      position: "relative",
                      right: "-10px",
                    }}
                  />
                </span>
              ) : (
                <span className="image">
                  <img
                    src="/logo1.svg"
                    style={{
                      maxWidth: "32px",
                      position: "relative",
                      right: "4px",
                    }}
                  />
                </span>
              )}
            </div>

            <div>
              <div className="toggle">
                <i
                  className="bi bi-arrow-bar-right "
                  onClick={() => {
                    setScreenview(!screenview);
                  }}
                />
              </div>
            </div>
          </header>
        )}

        {/* Mobile nav header */}
        {isMobile && (
          <header
            className="d-flex align-items-center mb-3"
            style={{ fontFamily: "Inter, sans-serif" }}
          >
            <div className="flex-fill">
              <img
                src={
                  themeContextValue.selectedTheme === "dark"
                    ? "/A-White.svg"
                    : "/logo.svg"
                }
                style={{
                  maxWidth: "140px",
                  position: "relative",
                  right: "-10px",
                }}
              />
            </div>
            <button
              ref={closeMobileMenuBtn}
              type="button"
              className="btn-close text-reset d-inline d-lg-none"
              style={{ marginRight: 18 }}
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            />
          </header>
        )}
        <div className="menu-bar">
          <div className="menu">
            <ul className="menu-links">
              {adminContextValue.showAdmin &&
                adminContextValue.role === "Administrator" && (
                  <li
                    className="sidebar-item "
                    onClick={() => {
                      handleSelectedMenu("statistics");
                    }}
                  >
                    <Link
                      to={"/admin/dashboard"}
                      className={selectedMenu === "statistics" ? "active" : ""}
                    >
                      <i className="bi-bar-chart-fill nav-icon icon" />
                      <span className="nav-text">Statistics</span>
                    </Link>
                  </li>
                )}
              {adminContextValue.showAdmin &&
                (adminContextValue.role === "Administrator" ||
                  adminContextValue.role === "Support") && (
                  <li
                    className="sidebar-item "
                    onClick={() => {
                      handleSelectedMenu("trialpage");
                    }}
                  >
                    <Link
                      to={"/support/trialpage"}
                      className={selectedMenu === "trialpage" ? "active" : ""}
                    >
                      <i className="bi bi-house-add-fill nav-icon icon" />
                      <span className="nav-text">Management panel</span>
                    </Link>
                  </li>
                )}

              {adminContextValue.showAdmin &&
                adminContextValue.role === "Administrator" && (
                  <li
                    className="sidebar-item"
                    onClick={() => {
                      handleSelectedMenu("reseller-management");
                    }}
                  >
                    <Link
                      to={"/admin/reselleracc"}
                      className={
                        selectedMenu === "reseller-management" ? "active" : ""
                      }
                    >
                      <i className="bi-person-lines-fill  nav-icon icon" />
                      <span className="nav-text">Resell user</span>
                    </Link>
                  </li>
                )}
              {adminContextValue.showAdmin &&
                adminContextValue.role === "Administrator" && (
                  <li
                    className="sidebar-item"
                    onClick={() => {
                      handleSelectedMenu("user-management");
                    }}
                  >
                    <Link
                      to={"/admin/accounts"}
                      className={
                        selectedMenu === "user-management" ? "active" : ""
                      }
                    >
                      <i className="bi-person-lines-fill  nav-icon icon" />
                      <span className="nav-text">User Management</span>
                    </Link>
                  </li>
                )}
              {adminContextValue.showAdmin &&
                (adminContextValue.role === "Administrator" ||
                  adminContextValue.role === "Manager") && (
                  <li
                    className="sidebar-item"
                    onClick={() => {
                      handleSelectedMenu("plan-management");
                    }}
                  >
                    <Link
                      to={"/admin/plans"}
                      className={
                        selectedMenu === "plan-management" ? "active" : ""
                      }
                    >
                      <i className="bi-grid-fill nav-icon icon" />
                      <span className="nav-text">Plan Management</span>
                    </Link>
                  </li>
                )}
              {adminContextValue.showAdmin &&
                adminContextValue.role === "Administrator" && (
                  <li
                    className="sidebar-item"
                    onClick={() => {
                      handleSelectedMenu("gift-management");
                    }}
                  >
                    <Link
                      to={"/admin/giftmanage"}
                      className={
                        selectedMenu === "gift-management" ? "active" : ""
                      }
                    >
                      <i className="bi bi-gift-fill nav-icon icon" />
                      <span className="nav-text">Gift Management</span>
                    </Link>
                  </li>
                )}
              {adminContextValue.showAdmin &&
                (adminContextValue.role === "Administrator" ||
                  adminContextValue.role === "Manager") && (
                  <li
                    className="sidebar-item"
                    onClick={() => {
                      handleSelectedMenu("invoices");
                    }}
                  >
                    <Link
                      to={"/admin/invoices"}
                      className={selectedMenu === "invoices" ? "active" : ""}
                    >
                      <i className="bi-grid-3x2-gap-fill nav-icon icon" />
                      <span className="nav-text">Invoices</span>
                    </Link>
                  </li>
                )}

              {adminContextValue.showAdmin &&
                (adminContextValue.role === "Administrator" ||
                  adminContextValue.role === "Manager") && (
                  <li
                    className="sidebar-item"
                    onClick={() => {
                      handleSelectedMenu("productmanage");
                    }}
                  >
                    <Link
                      to={"/admin/productmanage"}
                      className={
                        selectedMenu === "productmanage" ? "active" : ""
                      }
                    >
                      <i className="bi bi-speedometer nav-icon icon" />
                      <span className="nav-text">Product Manage</span>
                    </Link>
                  </li>
                )}

              {adminContextValue.showAdmin &&
                (adminContextValue.role === "Administrator" ||
                  adminContextValue.role === "Manager") && (
                  <li
                    className="sidebar-item"
                    onClick={() => {
                      handleSelectedMenu("productapi");
                    }}
                  >
                    <Link
                      to={"/admin/productapi"}
                      className={selectedMenu === "productapi" ? "active" : ""}
                    >
                      <i className="bi bi-cursor-fill nav-icon icon" />
                      <span className="nav-text">Product API</span>
                    </Link>
                  </li>
                )}

              {adminContextValue.showAdmin &&
                adminContextValue.role === "Administrator" && (
                  <li
                    className="sidebar-item"
                    onClick={() => {
                      handleSelectedMenu("partnerslink");
                    }}
                  >
                    <Link
                      to={"/admin/partnersurl/statistic"}
                      className={
                        selectedMenu === "partnerslink" ? "active" : ""
                      }
                    >
                      <i className="bi bi-bag-heart-fill nav-icon icon" />
                      <span className="nav-text">Partners Link</span>
                    </Link>
                  </li>
                )}

              {adminContextValue.showAdmin &&
                (adminContextValue.role === "Administrator" ||
                  adminContextValue.role === "Manager") && (
                  <li
                    className="sidebar-item"
                    onClick={() => {
                      handleSelectedMenu("coupons");
                    }}
                  >
                    <Link
                      to={"/admin/coupons"}
                      className={selectedMenu === "coupons" ? "active" : ""}
                    >
                      <i className="bi-percent  nav-icon icon" />
                      <span className="nav-text">Coupons</span>
                    </Link>
                  </li>
                )}
              {adminContextValue.showAdmin &&
                (adminContextValue.role === "Administrator" ||
                  adminContextValue.role === "Manager") && (
                  <li
                    className="sidebar-item"
                    onClick={() => {
                      handleSelectedMenu("blogs");
                    }}
                  >
                    <Link
                      to={"/admin/blogs"}
                      className={selectedMenu === "blogs" ? "active" : ""}
                    >
                      <i className="bi bi-bookmark-heart-fill nav-icon icon" />
                      <span className="nav-text">Blogs</span>
                    </Link>
                  </li>
                )}
              {adminContextValue.showAdmin &&
                (adminContextValue.role === "Administrator" ||
                  adminContextValue.role === "Manager") && (
                  <li
                    className="sidebar-item"
                    onClick={() => {
                      handleSelectedMenu("category");
                    }}
                  >
                    <Link
                      to={"/admin/category"}
                      className={selectedMenu === "category" ? "active" : ""}
                    >
                      <i className="bi bi-bookmarks nav-icon icon" />
                      <span className="nav-text">Category</span>
                    </Link>
                  </li>
                )}
              {adminContextValue.showAdmin &&
                (adminContextValue.role === "Administrator" ||
                  adminContextValue.role === "Manager") && (
                  <li
                    className="sidebar-item"
                    onClick={() => {
                      handleSelectedMenu("mail");
                    }}
                  >
                    <Link
                      to={"/admin/mail-sender"}
                      className={selectedMenu === "mail" ? "active" : ""}
                    >
                      <i className="bi bi-envelope nav-icon icon" />
                      <span className="nav-text">MAIL Sender</span>
                    </Link>
                  </li>
                )}
              {adminContextValue.showAdmin &&
                (adminContextValue.role === "Administrator" ||
                  adminContextValue.role === "Manager") && (
                  <li
                    className="sidebar-item"
                    onClick={() => {
                      handleSelectedMenu("website-management");
                    }}
                  >
                    <Link
                      to={"/admin/website-management"}
                      className={
                        selectedMenu === "website-management" ? "active" : ""
                      }
                    >
                      <i className="bi-gear-fill nav-icon icon" />
                      <span className="nav-text">Website Management</span>
                    </Link>
                  </li>
                )}
            </ul>
            {screenview &&
              adminContextValue.showAdmin &&
              (adminContextValue.role === "Administrator" ||
                adminContextValue.role === "Manager" ||
                adminContextValue.role === "Support") && (
                <button
                  className={"btn btn-primary my-5 mx-3 text-center dis "}
                  style={{
                    color: "#FFFFFF",
                    width: "-webkit-fill-available",
                    padding: "0.3rem 0.75rem",
                  }}
                  onClick={() => {
                    SwitchToUser();
                  }}
                >
                  Go to User Panel
                </button>
              )}
          </div>
        </div>
      </>
    );
  };
  return (
    <div>
      <div
        className="offcanvas offcanvas-start"
        tabIndex={-1}
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-body p-2">
          <NavContent />
        </div>
      </div>

      {!isMobile && (
        <nav
          className={screenview ? "sidebar d-block" : "sidebar close d-block"}
        >
          <NavContent />
        </nav>
      )}
    </div>
  );
};

export default Sidebar;
