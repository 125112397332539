import React, { useContext, lazy, memo, createContext } from "react";
import { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { ThemeContext } from "../App";
import { getMe, selectMe } from "../redux/slices/generalSlice";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import Loader from "../components/Loader";

const LazyLightTheme = lazy(() => import("../theme/LigthTheme"));
const LazyDarkTheme = lazy(() => import("../theme/DarkTheme"));

export const AdminContext = createContext();
const Layout = memo(({ children }) => {
  const [screenview, setScreenview] = useState(true);
  const [isMobile, setisMobile] = useState(false);

  const [showAdmin, setShowAdmin] = useState(false);
  const [showSupport, setShowSupport] = useState(false);

  const [role, setRole] = useState("User");

  const me = useSelector(selectMe);
  const dispatch = useDispatch();
  const themeContextValue = useContext(ThemeContext);
  const updateScreenView = (value) => {
    setScreenview(value);
  };

  const checkForAdmin = () => {
    const showAdminLocalStorageValue = localStorage.getItem("showAdmin");
    if (showAdminLocalStorageValue) {
      setShowAdmin(showAdminLocalStorageValue);
    } else {
      setShowAdmin(false);
      localStorage.setItem("showAdmin", "false");
    }
  };

  useEffect(() => {
    dispatch(getMe());
    checkForAdmin();
  }, []);

  useEffect(() => {
    if (me) {
      if (me.role === "User") {
        setShowAdmin(false);
        setRole("User");
      } else if (me.role === "Administrator") {
        setShowAdmin(true);
        setRole("Administrator");
      } else if (me.role === "Manager") {
        setShowAdmin(true);
        setRole("Manager");
      } else if (me.role === "Support") {
        setShowSupport(true);
        setRole("Support");
      }
    }
  }, [me]);

  useEffect(() => {
    document.body.classList.remove("dark-mode");
    window.addEventListener(
      "resize",
      () => {
        setisMobile(window.innerWidth <= 1200);
      },
      false
    );
  }, []);

  useEffect(() => {
    setisMobile(window.innerWidth <= 1200);
  }, []);

  useEffect(() => {
    console.log(isMobile);
  }, [isMobile]);

  const ThemeSelector = ({ children }) => {
    return (
      <>
        <React.Suspense fallback={<></>}>
          {themeContextValue.selectedTheme === "light" && <LazyLightTheme />}
          {themeContextValue.selectedTheme === "dark" && <LazyDarkTheme />}
        </React.Suspense>
        {children}
      </>
    );
  };

  return (
    <>
      <React.Suspense fallback={<Loader />}>
        <AdminContext.Provider value={{ showAdmin, setShowAdmin, role }}>
          <div className="App d-flex">
            <ThemeSelector>
              <div>
                <Sidebar
                  screenview={screenview}
                  setScreenview={updateScreenView}
                  isMobile={isMobile}
                />
              </div>
              {!isMobile && (
                <div
                  style={{
                    position: "relative",
                    left: screenview ? "270px" : "88px",
                    width: screenview
                      ? "calc(100% - 270px)"
                      : "calc(100% - 88px)",
                  }}
                  className={isMobile ? "" : "home"}
                >
                  <Navbar screenview={screenview} isMobile={isMobile} />
                  <div style={{ marginTop: "62px" }}>{children}</div>
                </div>
              )}
              {isMobile && (
                <div className={isMobile ? "" : "home"}>
                  <Navbar screenview={screenview} isMobile={isMobile} />
                  {children}
                </div>
              )}
            </ThemeSelector>
          </div>
        </AdminContext.Provider>
      </React.Suspense>
    </>
  );
});

export default Layout;
