import React from "react";
import "./index.css";
import App from "./App";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { HelmetProvider } from "react-helmet-async";
import "bootstrap-icons/font/bootstrap-icons.css";
// NOTE: Don't remove, import Chart from "chart.js/auto";
// import Chart from "chart.js/auto";
import { clientId } from "./utils/constants";

const root = document.getElementById("root");
render(
  <BrowserRouter>
    <HelmetProvider>
      <Provider store={store}>
        <GoogleOAuthProvider clientId={clientId}>
          <App />
        </GoogleOAuthProvider>
      </Provider>
    </HelmetProvider>
  </BrowserRouter>,
  root
);
