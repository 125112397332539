import React, { useState, useEffect } from "react";
import axios from "axios";
import { backendServerBaseURL } from "../utils/backendServerBaseURL";

const UserFooter = () => {
  const [copyRight, setCopyRight] = useState("");

  useEffect(() => {
    const getPrices = () => {
      axios
        .get(`${backendServerBaseURL}/landing`)
        .then((data) => {
          console.log(data.data.payload);
          setCopyRight(data.data.payload.Copyright);
        })
        .catch((error) => console.log(error));
    };

    getPrices();
  }, []);
  console.log(copyRight);

  return (
    <div class="ftr-txt text-start mt-5 pb-0">
      <p>Copyright © 2024 LightningProxies. All rights reserved.</p>
    </div>
  );
};

export default UserFooter;
