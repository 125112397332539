import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { backendServerBaseURL } from "../../utils/backendServerBaseURL";
import { UnicodeDecodeB64 } from "../../utils/helpers";
import { getMe } from "./generalSlice";

const initialState = {
  autoRenewData: {},
  activeSubscriptions: [],
  userStatistics: null,
  generatorInfo: null,

  suggestionAllowed: null,
  plans: {},
  coupons: [],
  trialPlans: {},
  singlePlanInfo: null,
  customPlanInfo: [],

  allInvoices: [],
  invoicesPager: null,
  invoicesSearch: "",

  allRewards: [],
  rewardsPager: null,
  rewardStats: null,

  mfa: null,

  targetSubscriptionInfo: null,

  discordInfo: null,

  allReferralRewards: [],
  ReferralRewardsPager: null,
  ReferralRewardStats: null,

  allTickets: [],
  TicketsPager: null,
  TicketStats: null,
  ticketSearch: "",

  allRates: null,

  allSubUsers: [],
  subUsersPager: null,
  subUsersSearch: "",
  resellerDashboardData: null,

  singleSubUserInfo: null,
  subUserSubscriptions: [],
  subUserSubscriptionsPager: null,
  subUserSubscriptionsSearch: "",
};

// UnsubMail
export const getUnsubMail = createAsyncThunk(
  "public/getUnsubMail",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {},
    };

    let response = await axios.get(
      `${backendServerBaseURL}/public/email-subscription/cancel`,
      config
    );
  }
);

// Dashboard
export const getDashboard = createAsyncThunk(
  "public/getDashboard",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {},
    };

    let response = await axios.get(
      `${backendServerBaseURL}/public/dashboard`,
      config
    );

    if (response.status === 200 && response.data.message === "Dashboard") {
      const payload = JSON.parse(UnicodeDecodeB64(response.data.payload));

      thunkAPI.dispatch(updateActiveSubscriptions(payload.activeSubscriptions));
      thunkAPI.dispatch(updateUserStatistics(payload.userStatistics));
    }
  }
);

export const updateAvatar = createAsyncThunk(
  "public/updateAvatar",
  async (file, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    const formData = new FormData();
    formData.append("file", file);

    try {
      let response = await axios.post(
        `${backendServerBaseURL}/public/updatemyavatar`,
        formData,
        config
      );

      if (
        response.status === 200 &&
        response.data.message === "Profile updated successfully."
      ) {
        // Avatar URL'sini güncelledikten sonra Redux state'e gönder
        return response.data.avatarUrl;
      } else {
        return thunkAPI.rejectWithValue("Failed to update avatar.");
      }
    } catch (error) {
      console.error("Avatar update failed:", error);
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Avatar update failed."
      );
    }
  }
);

export const deleteAvatar = createAsyncThunk(
  "public/deleteAvatar",
  async (_, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      let response = await axios.delete(
        `${backendServerBaseURL}/public/deletemyavatar`,
        config
      );

      if (
        response.status === 200 &&
        response.data.message === "Profile updated successfully."
      ) {
        return ""; // Avatar URL'yi boş yaparak siliyoruz
      } else {
        return thunkAPI.rejectWithValue("Failed to delete avatar.");
      }
    } catch (error) {
      console.error("Avatar delete failed:", error);
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Avatar delete failed."
      );
    }
  }
);

export const activateSubscription = createAsyncThunk(
  "public/activateSubscription",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {},
    };

    let response = await axios.get(
      `${backendServerBaseURL}/public/activate-subscription/${payload.subscriptionId}`,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Subscription updated successfully"
    ) {
      if (response.data.payload.active === true) {
        payload.navigate(`/dashboard/generator/${payload.subscriptionId}`);
      } else {
        payload.navigate("/dashboard");
      }
    }
  }
);

export const getProxyInfo = createAsyncThunk(
  "public/getProxyInfo",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { country: payload.country },
    };

    try {
      let response = await axios.get(
        `${backendServerBaseURL}/public/generator/${payload.subscriptionId}`,
        config
      );

      if (response.status === 200 && response.data.message === "Generator") {
        const payload = JSON.parse(UnicodeDecodeB64(response.data.payload));
        console.log(payload);
        thunkAPI.dispatch(updateGeneratorInfo(payload));
      }
      payload.setloading(false);
    } catch (err) {
      console.log(err.response);
      if (err.response.data.message == "This plan is expired") {
        window.open(`/dashboard`, "_self");
      }
    }
  }
);

export const updateProxyConfig = createAsyncThunk(
  "public/updateProxyConfig",
  async (payload, thunkAPI) => {
    payload.setErrorProxyConfig(null);
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    console.log("is lightning_residential", payload.lightning_residential);

    const body = {
      geoRegion: payload.geoRegion,
      city: payload.city,
      isp: payload.isp,
    };

    try {
      let response = await axios.patch(
        `${backendServerBaseURL}/public/proxy-config/${payload.subscriptionId}`,
        body,
        config
      );

      if (
        response.status === 200 &&
        response.data.message === "Proxy config updated successfully"
      ) {
        payload.setsuccessfullyUpdatedProxyConfig(true);
        payload.notify("Updated Successfully");
        thunkAPI.dispatch(
          getProxyInfo({
            subscriptionId: payload.subscriptionId,
            country: payload.geoRegion,
          })
        );
      }
    } catch (err) {
      console.log(err.response);
      payload.setsuccessfullyUpdatedProxyConfig(false);
      payload.setErrorProxyConfig(err.response.data.message);
      thunkAPI.dispatch(
        getProxyInfo({ subscriptionId: payload.subscriptionId })
      );
    }
  }
);

export const renewsubsSet = createAsyncThunk(
  "public/renewsubsSet",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const body = {
      subscriptionId: payload.subscriptionId,
      renewvalue: payload.renewvalue,
    };

    try {
      let response = await axios.post(
        `${backendServerBaseURL}/public/autorenew/set`,
        body,
        config
      );

      if (response.status === 200) {
        payload.notify &&
          payload.notify("Your Renew value has been successfully updated");
      }
    } catch (err) {
      console.log(err.response);
      payload.notify && payload.notify(err.response.data.message);
      payload.setErrorProxyConfig(err.response.data.message);
      thunkAPI.dispatch(
        getProxyInfo({ subscriptionId: payload.subscriptionId })
      );
    }

    payload.whitelistIpInpuRef.current.value = "";
  }
);

export const renewsubsStatus = createAsyncThunk(
  "public/renewsubsStatus",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const body = {
      subscriptionId: payload.subscriptionId,
      status: payload.status,
    };

    try {
      let response = await axios.post(
        `${backendServerBaseURL}/public/autorenew/status`,
        body,
        config
      );

      if (response.status === 200) {
        payload.notify &&
          payload.notify("Auto Renew status updated successfully");
      }
    } catch (err) {
      console.log(err.response);
      payload.notify && payload.notify(err.response.data.message);
      payload.setErrorProxyConfig(err.response.data.message);
      thunkAPI.dispatch(
        getProxyInfo({ subscriptionId: payload.subscriptionId })
      );
    }

    payload.whitelistIpInpuRef.current.value = "";
  }
);

export const renewsubsGet = createAsyncThunk(
  "public/renewsubsGet",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const body = {
      subscriptionId: payload.subscriptionId,
    };

    try {
      let response = await axios.post(
        `${backendServerBaseURL}/public/autorenew/get`,
        body,
        config
      );

      if (response.status === 200 && response.data.payload.subscriptionStatus) {
        thunkAPI.dispatch(
          updateAutoRenewData(response.data.payload.subscriptionStatus)
        );
      } else {
        thunkAPI.dispatch(updateAutoRenewData(null));
      }
    } catch (err) {
      thunkAPI.dispatch(updateAutoRenewData(err.response.data.message));
      console.log(err.response);
      payload.notify && payload.notify(err.response.data.message);
      payload.setErrorProxyConfig(err.response.data.message);
    }
  }
);

export const addWhitelistedIp = createAsyncThunk(
  "public/addWhitelistedIp",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const body = {
      ip: payload.ip,
    };

    try {
      let response = await axios.patch(
        `${backendServerBaseURL}/public/generator/${payload.subscriptionId}/add-whitelist-ip`,
        body,
        config
      );

      if (response.status === 200) {
        payload.notify("Updated Successfully");
        thunkAPI.dispatch(
          getProxyInfo({
            subscriptionId: payload.subscriptionId,
          })
        );
      }
    } catch (err) {
      console.log(err.response);
      payload.notify && payload.notify(err.response.data.message);
      payload.setErrorProxyConfig(err.response.data.message);
      thunkAPI.dispatch(
        getProxyInfo({ subscriptionId: payload.subscriptionId })
      );
    }

    payload.whitelistIpInpuRef.current.value = "";
  }
);

export const removeWhitelistedIp = createAsyncThunk(
  "public/addWhitelistedIp",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const body = {
      ip: payload.ip,
    };

    try {
      let response = await axios.patch(
        `${backendServerBaseURL}/public/generator/${payload.subscriptionId}/remove-whitelist-ip`,
        body,
        config
      );

      if (response.status === 200) {
        payload.notify("Updated Successfully");
        thunkAPI.dispatch(
          getProxyInfo({
            subscriptionId: payload.subscriptionId,
          })
        );
      }
    } catch (err) {
      console.log(err.response);
      payload.setErrorProxyConfig(err.response.data.message);
      thunkAPI.dispatch(
        getProxyInfo({ subscriptionId: payload.subscriptionId })
      );
    }

    payload.whitelistIpInpuRef.current.value = "";
  }
);

export const getSingleSubscription = createAsyncThunk(
  "admin/getSingleSubscription",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {},
    };

    let response = await axios.get(
      `${backendServerBaseURL}/public/subscriptions/${payload.subscriptionId}`,
      config
    );

    if (response.status === 200 && response.data.message === "Subscription") {
      thunkAPI.dispatch(
        updateTargetSubscriptionInfo(response.data.payload.subscription)
      );
    }
  }
);

export const deleteResidential = createAsyncThunk(
  "public/deleteResidential",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {},
    };

    let response = await axios.delete(
      `${backendServerBaseURL}/public/residential/${
        thunkAPI.getState().public.generatorInfo?.subscriptionInfo?.id
      }`,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Subscription deleted successfully"
    ) {
      payload.navigate("/dashboard");
    }
  }
);

// Renew
export const renewSubscription = createAsyncThunk(
  "public/renewSubscription",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const body = {
      bandwidth: payload.bandwidth,
      threads: payload.threads,
      days: payload.days,
      updatingType: payload.updatingType,
      coupon: payload.coupon,
      paymentMethod: payload.paymentMethod,
    };

    try {
      let response = await axios.patch(
        `${backendServerBaseURL}/public/subscriptions/${payload.subscriptionId}/renew`,
        body,
        config
      );

      if (
        response.status === 200 &&
        response.data.message === "Renewed successfully" &&
        !Object.keys(response.data.payload).includes("linkData")
      ) {
        payload.navigate(-1);
      }

      if (
        response.status === 200 &&
        response.data.message === "Renewed successfully" &&
        Object.keys(response.data.payload).includes("linkData")
      ) {
        window.open(response.data.payload.linkData.hosted_url, "_self");
      }

      thunkAPI.dispatch(getMe());
    } catch (error) {
      if (error.response.status === 400) {
        payload.setErrors({ afterSubmit: error.response.data.payload.error });
      } else {
        payload.setErrors({ afterSubmit: "Something goes wrong" });
      }
    }
  }
);

// Suggestions
export const createNewSuggestion = createAsyncThunk(
  "public/createNewSuggestion",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.post(
      `${backendServerBaseURL}/public/suggestions`,
      {
        suggestion: payload.Suggestion,
      },
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Suggestion created successfully"
    ) {
      // payload.navigate("/dashboard/public/accounts");
      window.location.reload();
    }
  }
);

export const CheckIsSuggestionAllowed = createAsyncThunk(
  "public/CheckIsSuggestionAllowed",
  async (_, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/public/suggestions/allowed`,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Suggestion allowed"
    ) {
      thunkAPI.dispatch(updateSuggestionAllowed(response.data.payload.allowed));
    }
  }
);

// Reseller
export const submitBecomeResellerRequest = createAsyncThunk(
  "public/submitBecomeResellerRequest",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.post(
      `${backendServerBaseURL}/public/reseller`,
      {
        resellerRequestFormResponses: JSON.stringify(
          payload.resellerRequestFormResponses
        ),
      },
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Reseller request sent successfully"
    ) {
      // payload.navigate("/dashboard/public/accounts");
      window.location.reload();
    }
  }
);

// Topup balance
export const topupBalance = createAsyncThunk(
  "public/topupBalance",
  async (payload, thunkAPI) => {
    try {
      const token = await localStorage.getItem("accessToken", "");

      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let response = await axios.post(
        `${backendServerBaseURL}/public/topup`,
        {
          balance: payload.balance,
          paymentMethod: payload.paymentMethod,
        },
        config
      );

      if (response.status === 200 && response.data.message === "Topup") {
        if (Object.keys(response.data.payload).includes("checkoutUrl")) {
          window.open(response.data.payload.checkoutUrl, "_self");
        }

        if (Object.keys(response.data.payload).includes("invoiceLink")) {
          window.open(response.data.payload.invoiceLink, "_self");
        }
      }
    } catch (error) {
      if (error.response.status === 400) {
        if (error.response.data.message.includes("Your Stripe total limit:")) {
          let message = error.response.data.message;
          let stripeLimit = parseFloat(
            message.split("Your Stripe total limit: ")[1].split(".")[0]
          );
          let remainingLimit = parseFloat(
            message.split("Remaining limit: ")[1].split(".")[0]
          );
          stripeLimit = stripeLimit.toFixed(2);
          remainingLimit = remainingLimit.toFixed(2);
          payload.setErrors({
            afterSubmit:
              "Your Stripe total limit is " +
              stripeLimit +
              "$. Your remaining limit is " +
              remainingLimit +
              "$. Please contact us to increase your daily spending limit.",
          });
        } else {
          payload.setErrors({ afterSubmit: error.response.data.payload.error });
        }
      } else {
        payload.setErrors({ afterSubmit: "Something goes wrong" });
      }
    }
  }
);

// Plans
export const getAllPlans = createAsyncThunk(
  "public/getAllPlans",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/public/plans`,
      config
    );

    if (response.status === 200 && response.data.message === "Plans list") {
      const payload = JSON.parse(UnicodeDecodeB64(response.data.payload));

      thunkAPI.dispatch(updatePlans(payload));
    }
  }
);

export const getAllTrialPlans = createAsyncThunk(
  "public/getAllTrialPlans",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/public/trial-plans`,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Trial plans list"
    ) {
      thunkAPI.dispatch(updateTrialPlans(response.data.payload));
    }
  }
);

export const purchasePlan = createAsyncThunk(
  "public/purchasePlan",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      let response = await axios.post(
        `${backendServerBaseURL}/public/plans/${payload.planId}/purchase`,
        {
          coupon: payload.coupon,
          paymentMethod: payload.paymentMethod,
          region: payload.region,
          bandwidth: payload.bandwidth,
          threads: payload.threads,
          days: payload.days,
          value: payload.value,
          isAutoPay: payload.isAutoPay,
          duration: payload.duration,
        },
        config
      );

      if (
        response.status === 200 &&
        response.data.message === "Plan purchase" &&
        !(
          Object.keys(response.data.payload).includes("linkData") ||
          Object.keys(response.data.payload).includes("invoiceLink")
        )
      ) {
        payload.navigate("/dashboard");
      }

      if (
        response.status === 200 &&
        response.data.message === "Plan purchase" &&
        Object.keys(response.data.payload).includes("linkData")
      ) {
        window.open(response.data.payload.linkData.result.url, "_self");
      }

      if (
        response.status === 200 &&
        response.data.message === "Plan purchase" &&
        Object.keys(response.data.payload).includes("invoiceLink")
      ) {
        window.open(response.data.payload.invoiceLink, "_self");
      }

      thunkAPI.dispatch(getMe());
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const errorMessage = error.response.data.message;

        if (errorMessage.includes("Country not selected")) {
          return thunkAPI.rejectWithValue("Please select a country");
        } else if (errorMessage.includes("Your Stripe total limit:")) {
          let message = errorMessage;
          let stripeLimit = parseFloat(
            message.split("Your Stripe total limit: ")[1].split(".")[0]
          );
          let remainingLimit = parseFloat(
            message.split("Remaining limit: ")[1].split(".")[0]
          );
          stripeLimit = stripeLimit.toFixed(2);
          remainingLimit = remainingLimit.toFixed(2);
          return thunkAPI.rejectWithValue(
            `Your Stripe total limit is ${stripeLimit}$. Your remaining limit is ${remainingLimit}$. Please contact us to increase your daily spending limit.`
          );
        } else if (
          errorMessage.includes("Coupon only valid for certain plans")
        ) {
          return thunkAPI.rejectWithValue(
            "The coupon you entered is only valid for certain plans. Please check the eligible plans or use a different coupon."
          );
        } else {
          return thunkAPI.rejectWithValue(errorMessage);
        }
      } else {
        return thunkAPI.rejectWithValue("Something went wrong");
      }
    }
  }
);

export const getCustomPlanInfo = createAsyncThunk(
  "public/getCustomPlanInfo",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/public/custom-plans/prices`,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Custom plan info"
    ) {
      thunkAPI.dispatch(updateCustomPlanInfo(response.data.payload.settings));
    }
  }
);

export const getSinglePlan = createAsyncThunk(
  "public/getSinglePlan",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/public/plans/${payload.planId}/${payload.value}`,
      config
    );

    if (response.status === 200 && response.data.message === "Plan") {
      thunkAPI.dispatch(updateSinglePlanInfo(response.data.payload));
    }
  }
);

export const getCoupons = createAsyncThunk(
  "public/getCoupons",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/public/coupons`,
      config
    );

    if (response.status === 200 && response.data.message === "Coupons list") {
      thunkAPI.dispatch(updateCoupons(response.data.payload.coupons));
    }
  }
);

// Invoices
export const getAllInvoices = createAsyncThunk(
  "public/getAllInvoices",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: payload.page,
        size: 20,
        search: thunkAPI.getState().public.invoicesSearch,
        sort: payload.sortType,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/public/invoices`,
      config
    );

    if (response.status === 200 && response.data.message === "Invoices list") {
      thunkAPI.dispatch(updateAllInvoices(response.data.payload.invoices));
      thunkAPI.dispatch(updateInvoicesPager(response.data.pager));
    }
    payload.setloading(false);
  }
);

// Rewards
export const getAllRewards = createAsyncThunk(
  "public/getAllRewards",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: payload.page,
        size: 20,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/public/rewards`,
      config
    );

    if (response.status === 200 && response.data.message === "Rewards list") {
      thunkAPI.dispatch(updateallRewards(response.data.payload.rewards));
      thunkAPI.dispatch(updaterewardsPager(response.data.pager));
      thunkAPI.dispatch(updaterRewardStats(response.data.payload.stats));
    }
  }
);

export const claimReward = createAsyncThunk(
  "public/claimReward",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.post(
      `${backendServerBaseURL}/public/rewards/claim`,
      {},
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Reward claimed successfully"
    ) {
      thunkAPI.dispatch(getAllRewards({ page: 1 }));
      thunkAPI.dispatch(getMe());
    }
  }
);

// Profile
export const sendEditToken = createAsyncThunk(
  "public/sendEditToken",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const body = {
      email: payload.email,
    };

    let response = await axios.post(
      `${backendServerBaseURL}/public/profile/edit-token`,
      body,
      config
    );

    if (response.status === 200 && response.data.message === "Edit Token") {
    }
  }
);

export const updateBasicInfo = createAsyncThunk(
  "public/updateBasicInfo",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const body = {
      editToken: payload.editToken,
      email: payload.email,
      username: payload.username,
      legalname: payload.legalName,
      legalusername: payload.legalSurname,
      bilingemail: payload.billingEmail,
      country: payload.country,
      companyname: payload.companyName,
      vatid: payload.vatNumber,
      phonenumber: payload.phoneNumber,
      billingaddress: payload.billingAddress,
      city: payload.city,
      zipcode: payload.zipCode,
    };

    try {
      let response = await axios.patch(
        `${backendServerBaseURL}/public/profile/basic-info`,
        body,
        config
      );

      if (
        response.status === 200 &&
        response.data.message === "Basic info updated successfully"
      ) {
        payload.notify();
        localStorage.setItem(
          "user",
          JSON.stringify(response.data.payload.user)
        );
        payload.closeUpdateBasicInfoDialog.current.click();
      }
    } catch (error) {
      if (
        error.response.status === 400 &&
        error.response.data.message !== "Validation Error"
      ) {
        payload.seteditTokenError(error.response.data.message);
      }
    }
  }
);

export const updatePassowrd = createAsyncThunk(
  "public/updatePassowrd",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const body = {
      currentPassword: payload.currentPassword,
      newPassword: payload.newPassword,
    };

    try {
      let response = await axios.patch(
        `${backendServerBaseURL}/public/profile/password`,
        body,
        config
      );

      if (
        response.status === 200 &&
        response.data.message === "Password updated successfully"
      ) {
        payload.notify();
        payload.resetForm();
        payload.navigate(`/password-changed-successfully`);
      }
    } catch (error) {
      if (
        error.response.status === 400 &&
        error.response.data.message !== "Validation Error"
      ) {
        payload.setErrors({ afterSubmit: error.response.data.message });
      }
    }
  }
);

export const updateAPIKEY = createAsyncThunk(
  "public/updateAPIKEY",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      let response = await axios.post(
        `${backendServerBaseURL}/auth/changekey/${payload.sub}`,
        config
      );

      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      if (
        error.response.status === 400 &&
        error.response.data.message !== "Validation Error"
      ) {
        payload.setErrors({ afterSubmit: error.response.data.message });
      }
    }
  }
);

// MFA
export const getMFA = createAsyncThunk("public/getMFA", async (_, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let response = await axios.get(`${backendServerBaseURL}/auth/mfa`, config);

  if (
    response.status === 200 &&
    response.data.message === "Multi Factor Authentication"
  ) {
    thunkAPI.dispatch(updateMFA(response.data.payload));
  }
});

export const enable2FA = createAsyncThunk(
  "public/enable2FA",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      let response = await axios.post(
        `${backendServerBaseURL}/auth/mfa`,
        {
          totp: payload.totp,
        },
        config
      );

      if (
        response.status === 200 &&
        response.data.message ===
          "Multi Factor Authentication enabled successfully"
      ) {
        localStorage.setItem(
          "user",
          JSON.stringify(response.data.payload.user)
        );
        window.location.reload();
      }
    } catch (error) {
      if (
        error.response.status === 400 &&
        error.response.data.message !== "Validation Error"
      ) {
        payload.setErrors({ afterSubmit: error.response.data.message });
      }
    }
  }
);

export const disable2FA = createAsyncThunk(
  "public/disable2FA",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      let response = await axios.delete(
        `${backendServerBaseURL}/auth/mfa`,
        config
      );

      if (
        response.status === 200 &&
        response.data.message ===
          "Multi Factor Authentication disabled successfully"
      ) {
        localStorage.setItem(
          "user",
          JSON.stringify(response.data.payload.user)
        );
        window.location.reload();
      }
    } catch (error) {
      if (
        error.response.status === 400 &&
        error.response.data.message !== "Validation Error"
      ) {
        payload.setErrors({ afterSubmit: error.response.data.message });
      }
    }
  }
);

// Discord
export const getDiscordInfo = createAsyncThunk(
  "public/getDiscordInfo",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      let response = await axios.get(
        `${backendServerBaseURL}/public/discord`,
        config
      );

      if (response.status === 200 && response.data.message === "Discord") {
        thunkAPI.dispatch(updateDiscordInfo(response.data.payload));
      }
    } catch (error) {
      if (
        error.response.status === 400 &&
        error.response.data.message !== "Validation Error"
      ) {
        payload.setErrors({ afterSubmit: error.response.data.message });
      }
    }
  }
);

export const updateDiscordCode = createAsyncThunk(
  "public/updateDiscordCode",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      let response = await axios.post(
        `${backendServerBaseURL}/public/discord/code`,
        { code: payload.code },
        config
      );

      if (response.status === 200 && response.data.message === "Discord") {
        // thunkAPI.dispatch(updateDiscordInfo(response.data.payload));
      }
    } catch (error) {
      if (
        error.response.status === 400 &&
        error.response.data.message !== "Validation Error"
      ) {
        payload.setErrors({ afterSubmit: error.response.data.message });
      }
    }
  }
);

export const removeDiscord = createAsyncThunk(
  "public/removeDiscord",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      let response = await axios.delete(
        `${backendServerBaseURL}/public/discord`,
        config
      );

      if (
        response.status === 200 &&
        response.data.message === "Discord deleted successfully"
      ) {
        window.open(
          thunkAPI.getState().public.discordInfo.discordDisconnectUrl,
          "_self"
        );
      }
    } catch (error) {
      if (
        error.response.status === 400 &&
        error.response.data.message !== "Validation Error"
      ) {
        payload.setErrors({ afterSubmit: error.response.data.message });
      }
    }
  }
);

// Rates
export const getAllRewardRatesForRewardsAndReferrals = createAsyncThunk(
  "public/getAllRewardRatesForRewardsAndReferrals",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/public/reward-rates`,
      config
    );

    if (response.status === 200 && response.data.message === "Reward Rates") {
      thunkAPI.dispatch(updateallRates(response.data.payload));
    }
  }
);

// Rewards
export const getAllReferralRewards = createAsyncThunk(
  "public/getAllReferralRewards",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: payload.page,
        size: 20,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/public/referral-rewards`,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Referral Rewards list"
    ) {
      thunkAPI.dispatch(
        updateallReferralRewards(response.data.payload.rewards)
      );
      thunkAPI.dispatch(updateReferralRewardsPager(response.data.pager));
      thunkAPI.dispatch(updateReferralRewardStats(response.data.payload.stats));
    }
  }
);

export const claimReferralReward = createAsyncThunk(
  "public/claimReferralReward",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.post(
      `${backendServerBaseURL}/public/referral-rewards/claim`,
      {},
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Referral Reward claimed successfully"
    ) {
      thunkAPI.dispatch(getAllReferralRewards({ page: 1 }));
      thunkAPI.dispatch(getMe());
    }
  }
);

// Reseller Dashboard
export const createSubUser = createAsyncThunk(
  "public/createSubUser",
  async (payload, thunkAPI) => {
    payload.setsuccessMsg(null);
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-api-key": `${payload.xApiKey}`,
      },
    };

    try {
      let response = await axios.post(
        `${backendServerBaseURL}/reseller/sub-users`,
        {
          username: payload.username,
          email: payload.email,
        },
        config
      );

      if (
        response.status === 200 &&
        response.data.message === "Sub-User created successfully"
      ) {
        payload.setsuccessMsg("Sub User Created Successfully");
        payload.resetForm();
        thunkAPI.dispatch(
          getAllSubUsers({ page: 1, xApiKey: payload.xApiKey })
        );
        thunkAPI.dispatch(getResellerDashboard({ xApiKey: payload.xApiKey }));
      }
    } catch (err) {
      payload.setErrors({ afterSubmit: err.response.data.message });
    }
  }
);

export const getAllSubUsers = createAsyncThunk(
  "public/getAllSubUsers",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-api-key": `${payload.xApiKey}`,
      },
      params: {
        page: payload.page,
        size: 30,
        search: thunkAPI.getState().public.subUsersSearch,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/reseller/sub-users`,
      config
    );

    if (response.status === 200 && response.data.message === "Sub-Users list") {
      thunkAPI.dispatch(updateallSubUsers(response.data.payload));
      thunkAPI.dispatch(updatesubUsersPager(response.data.pager));
    }
  }
);

export const getResellerDashboard = createAsyncThunk(
  "public/getResellerDashboard",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-api-key": `${payload.xApiKey}`,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/public/reseller-dashboard`,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Reseller dashboard"
    ) {
      thunkAPI.dispatch(updateresellerDashboardData(response.data.payload));
    }
  }
);

export const addResidentialPlanToResellerSubUser = createAsyncThunk(
  "public/addResidentialPlanToResellerSubUser",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-api-key": `${payload.xApiKey}`,
      },
    };

    let response = await axios.post(
      `${backendServerBaseURL}/reseller/sub-users/${payload.subUserId}/plans`,
      {
        region: 0,
        bandwidth: payload.bandwidth,
      },
      config
    );

    if (response.status === 200 && response.data.message === "Plan purchase") {
      // thunkAPI.dispatch(updateallSubUsers(response.data.payload));
      payload.navigate("/dashboard/reseller-dashboard");
    }
  }
);

export const addDatacenterPlanToResellerSubUser = createAsyncThunk(
  "public/addResidentialPlanToResellerSubUser",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-api-key": `${payload.xApiKey}`,
      },
    };

    let response = await axios.post(
      `${backendServerBaseURL}/reseller/sub-users/${payload.subUserId}/plans`,
      {
        region: payload.region,
        threads: payload.threads,
        days: payload.days,
      },
      config
    );

    if (response.status === 200 && response.data.message === "Plan purchase") {
      // thunkAPI.dispatch(updateallSubUsers(response.data.payload));
      payload.navigate("/dashboard/reseller-dashboard");
    }
  }
);

export const getSingleSubUser = createAsyncThunk(
  "public/getSingleSubUsers",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-api-key": `${payload.xApiKey}`,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/reseller/sub-users/${payload.subUserId}`,
      config
    );

    if (response.status === 200 && response.data.message === "Sub-User") {
      thunkAPI.dispatch(updatesingleSubUserInfo(response.data.payload));
      thunkAPI.dispatch(
        updatesubUserSubscriptions(response.data.payload.activeSubscriptions)
      );
    }
  }
);

// tickets

export const getAllTickets = createAsyncThunk(
  "public/getAllTickets",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: payload.page,
        size: 10,
        search: thunkAPI.getState().public.ticketSearch,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/public/ticket`,
      config
    );

    if (response.status === 200 && response.data.message === "Tickets list") {
      thunkAPI.dispatch(updateAllTickets(response.data.payload.tickets));
      thunkAPI.dispatch(updateTicketsPager(response.data.pager));
    }
  }
);
export const getTicketsStats = createAsyncThunk(
  "public/getTicketStats",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/public/ticket-stats`,
      config
    );

    if (response.status === 200 && response.data.message === "Ticket stats") {
      thunkAPI.dispatch(updateTicketStats(response.data.payload));
    }
    payload.setLoader(false);
  }
);
export const createNewTicket = createAsyncThunk(
  "public/newTicket",
  async (payload, thunkAPI) => {
    try {
      const token = await localStorage.getItem("accessToken", "");

      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let response = await axios.post(
        `${backendServerBaseURL}/public/ticket`,
        payload.values,
        config
      );

      if (
        response.status === 200 &&
        response.data.message === "Ticket created successfully"
      ) {
        payload.navigate("/ticket/" + response.data.payload.ticket._id);
      }
    } catch (error) {
      if (
        error.response.data.payload.error ===
        "You have reached your daily ticket limit"
      ) {
        payload.setErrors({
          afterSubmit: "You have reached your daily ticket limit",
        });
        // payload.errorNotify("You have reached your daily ticket limit");
      } else {
        payload.setErrors({ afterSubmit: "Error creating new ticket" });
        // payload.errorNotify("Error creating new ticket");
      }
    }
  }
);
export const closeTicket = createAsyncThunk(
  "public/closeTicket",
  async (payload, thunkAPI) => {
    try {
      const token = await localStorage.getItem("accessToken", "");

      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let response = await axios.delete(
        `${backendServerBaseURL}/public/ticket/${payload.id}`,
        config
      );

      if (
        response.status === 200 &&
        response.data.message === "Ticket closed successfully"
      ) {
        payload.navigate("/ticket");
        thunkAPI.dispatch(getAllTickets({ page: 1 }));
      }
    } catch (error) {
      payload.errorNotify("Error closing  ticket");
    }
  }
);

export const publicSlice = createSlice({
  name: "public",
  initialState,
  reducers: {
    updateAutoRenewData: (state, action) => {
      state.autoRenewData = action.payload;
    },

    updateGeneratorInfo: (state, action) => {
      state.generatorInfo = action.payload;
    },
    updateActiveSubscriptions: (state, action) => {
      state.activeSubscriptions = action.payload;
    },
    updateUserStatistics: (state, action) => {
      state.userStatistics = action.payload;
    },

    updateSuggestionAllowed: (state, action) => {
      state.suggestionAllowed = action.payload;
    },
    updatePlans: (state, action) => {
      state.plans = action.payload;
    },
    updateCoupons: (state, action) => {
      state.coupons = action.payload;
    },
    updateTrialPlans: (state, action) => {
      state.trialPlans = action.payload;
    },
    updateSinglePlanInfo: (state, action) => {
      state.singlePlanInfo = action.payload;
    },
    updateCustomPlanInfo: (state, action) => {
      state.customPlanInfo = action.payload;
    },

    updateAllInvoices: (state, action) => {
      state.allInvoices = action.payload;
    },
    updateInvoicesPager: (state, action) => {
      state.invoicesPager = action.payload;
    },
    updateInvoicesSearch: (state, action) => {
      state.invoicesSearch = action.payload;
    },
    updateAllTickets: (state, action) => {
      state.allTickets = action.payload;
    },
    updateTicketStats: (state, action) => {
      state.TicketStats = action.payload;
    },
    updateTicketsPager: (state, action) => {
      state.TicketsPager = action.payload;
    },
    updateTicketsSearch: (state, action) => {
      state.ticketSearch = action.payload;
    },

    updateallRewards: (state, action) => {
      state.allRewards = action.payload;
    },
    updaterewardsPager: (state, action) => {
      state.rewardsPager = action.payload;
    },
    updaterRewardStats: (state, action) => {
      state.rewardStats = action.payload;
    },

    updateMFA: (state, action) => {
      state.mfa = action.payload;
    },

    updateTargetSubscriptionInfo: (state, action) => {
      state.targetSubscriptionInfo = action.payload;
    },

    updateDiscordInfo: (state, action) => {
      state.discordInfo = action.payload;
    },

    updateallReferralRewards: (state, action) => {
      state.allReferralRewards = action.payload;
    },
    updateReferralRewardsPager: (state, action) => {
      state.ReferralRewardsPager = action.payload;
    },
    updateReferralRewardStats: (state, action) => {
      state.ReferralRewardStats = action.payload;
    },

    updateallRates: (state, action) => {
      state.allRates = action.payload;
    },

    updateallSubUsers: (state, action) => {
      state.allSubUsers = action.payload;
    },
    updatesubUsersPager: (state, action) => {
      state.subUsersPager = action.payload;
    },
    updatesubUsersSearch: (state, action) => {
      state.subUsersSearch = action.payload;
    },
    updateresellerDashboardData: (state, action) => {
      state.resellerDashboardData = action.payload;
    },

    updatesubUserSubscriptions: (state, action) => {
      state.subUserSubscriptions = action.payload;
    },
    updatesubUserSubscriptionsPager: (state, action) => {
      state.subUserSubscriptionsPager = action.payload;
    },
    updatesubUserSubscriptionsSearch: (state, action) => {
      state.subUserSubscriptionsSearch = action.payload;
    },
    updatesingleSubUserInfo: (state, action) => {
      state.singleSubUserInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateAvatar.fulfilled, (state, action) => {
        state.avatarUrl = action.payload;
      })
      .addCase(updateAvatar.rejected, (state, action) => {
        console.error(action.payload);
      })
      .addCase(deleteAvatar.fulfilled, (state) => {
        state.avatarUrl = "";
      })
      .addCase(deleteAvatar.rejected, (state, action) => {
        console.error(action.payload);
      });
  },
});

export const {
  updateInvoicesSearch,
  updateTicketsSearch,
  updateGeneratorInfo,
  updateSuggestionAllowed,
  updatePlans,
  updateCoupons,
  updateTrialPlans,
  updateSinglePlanInfo,
  updateCustomPlanInfo,
  updateActiveSubscriptions,
  updateAllInvoices,
  updateInvoicesPager,
  updateAllTickets,
  updateTicketStats,
  updateTicketsPager,
  updateallRewards,
  updaterewardsPager,
  updaterRewardStats,
  updateMFA,
  updateUserStatistics,
  updateTargetSubscriptionInfo,
  updateDiscordInfo,
  updateallReferralRewards,
  updateReferralRewardsPager,
  updateReferralRewardStats,
  updateallRates,

  updateallSubUsers,
  updatesubUsersPager,
  updatesubUsersSearch,
  updateresellerDashboardData,

  updatesingleSubUserInfo,
  updatesubUserSubscriptions,
  updatesubUserSubscriptionsPager,
  updatesubUserSubscriptionsSearch,
} = publicSlice.actions;

export const { updateAutoRenewData } = publicSlice.actions;
export const selectGeneratorInfo = (state) => state.public.generatorInfo;
export const selectActiveSubscriptions = (state) =>
  state.public.activeSubscriptions;
export const selectUserStatistics = (state) => state.public.userStatistics;

export const selectSuggestionAllowed = (state) =>
  state.public.suggestionAllowed;

export const selectPlans = (state) => state.public.plans;
export const selectCoupons = (state) => state.public.coupons;
export const selectTrialPlans = (state) => state.public.trialPlans;
export const selectSinglePlanInfo = (state) => state.public.singlePlanInfo;
export const selectCustomPlanInfo = (state) => state.public.customPlanInfo;

export const selectAllInvoices = (state) => state.public.allInvoices;
export const selectInvoicesPager = (state) => state.public.invoicesPager;
export const selectInvoicesSearch = (state) => state.public.invoicesSearch;

export const selectAllTickets = (state) => state.public.allTickets;
export const selectTicketStats = (state) => state.public.TicketStats;
export const selectTicketsPager = (state) => state.public.TicketsPager;
export const selectTicketsSearch = (state) => state.public.ticketSearch;

export const selectallRewards = (state) => state.public.allRewards;
export const selectrewardsPager = (state) => state.public.rewardsPager;
export const selectRewardStats = (state) => state.public.rewardStats;

export const selectMFA = (state) => state.public.mfa;

export const selectTargetSubscriptionInfo = (state) =>
  state.public.targetSubscriptionInfo;

export const selectDiscordInfo = (state) => state.public.discordInfo;

export const selectallReferralRewards = (state) =>
  state.public.allReferralRewards;
export const selectReferralRewardsPager = (state) =>
  state.public.ReferralRewardsPager;
export const selectReferralRewardStats = (state) =>
  state.public.ReferralRewardStats;

export const selectallRates = (state) => state.public.allRates;

export const selectAllSubUsers = (state) => state.public.allSubUsers;
export const selectsubUsersPager = (state) => state.public.subUsersPager;
export const selectsubUsersSearch = (state) => state.public.subUsersSearch;
export const selectresellerDashboardData = (state) =>
  state.public.resellerDashboardData;

export const selectsingleSubUserInfo = (state) =>
  state.public.singleSubUserInfo;
export const selectsubUserSubscriptions = (state) =>
  state.public.subUserSubscriptions;
export const selectsubUserSubscriptionsPager = (state) =>
  state.public.subUserSubscriptionsPager;
export const selectsubUserSubscriptionsSearch = (state) =>
  state.public.subUserSubscriptionsSearch;

export default publicSlice.reducer;
