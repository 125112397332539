import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const GuideSidebar = ({ handleCommonToggle }) => {
  const location = useLocation();
  const [navOpen, setNavOpen] = useState(true);

  const determineMenuState = (path) => {
    let menu = "";
    let subMenu = "";

    if (path.includes("/guide/")) {
      if (
        path.includes("/lightningproxies-extension") ||
        path.includes("/dolphin") ||
        path.includes("/adspower") ||
        path.includes("/bitbrowser") ||
        path.includes("/switchy-omega-proxy") ||
        path.includes("/android") ||
        path.includes("/iphone")
      ) {
        menu = "useProxies";
        if (
          path.includes("/lightningproxies-extension") ||
          path.includes("/switchy-omega-proxy")
        ) {
          subMenu = "pc";
        } else if (path.includes("/android")) {
          subMenu = "android";
        } else if (path.includes("/iphone")) {
          subMenu = "iphone";
        } else if (
          path.includes("/dolphin") ||
          path.includes("/adspower") ||
          path.includes("/bitbrowser")
        ) {
          subMenu = "antibrowser";
        }
      }
    } else if (path === "/guide") {
      window.location.href = "/guide/lightningproxies-extension";
    }

    return { menu, subMenu };
  };

  const initialMenuState = determineMenuState(location.pathname);
  const [selectedMenu, setSelectedMenu] = useState(initialMenuState.menu);
  const [selectedSubMenu, setSelectedSubMenu] = useState(
    initialMenuState.subMenu
  );

  useLayoutEffect(() => {
    const { menu, subMenu } = determineMenuState(location.pathname);
    setSelectedMenu(menu);
    setSelectedSubMenu(subMenu);
  }, [location.pathname]);

  const handleToggle = (menu, event) => {
    event.preventDefault();
    if (selectedMenu === menu) {
      setSelectedMenu("");
      setSelectedSubMenu("");
    } else {
      setSelectedMenu(menu);
      setSelectedSubMenu("");
    }
  };

  const handleSubToggle = (subMenu, event) => {
    event.preventDefault();
    if (selectedSubMenu === subMenu) {
      setSelectedSubMenu("");
    } else {
      setSelectedSubMenu(subMenu);
    }
  };

  const handleMenuClick = () => {
    setSelectedMenu("");
    setSelectedSubMenu("");
  };

  return (
    <div className="d-flex flex-column justify-content-between">
      <div className="d-flex flex-column">
        <div className="logo-box">
          <a href="/">
            <img
              src="/assets/images/logo.svg"
              alt="Logo"
              className="img-fluid logo-big"
            />
          </a>
          <button
            className="btn btn-toggle"
            type="button"
            id="menuToggle"
            onClick={() => {
              setNavOpen(!navOpen);
              handleCommonToggle();
            }}
          >
            <i className="fa fa-angle-left"></i>
          </button>
        </div>
        <div className="menu-set mt-0 border-0">
          <h6>HOW TO USE PROXIES</h6>
          <ul>
            <li>
              <Link
                to="#"
                onClick={(e) => handleToggle("useProxies", e)}
                className={`icon-link ${selectedMenu === "useProxies" ? "active" : ""}`}
              >
                <img
                  src="/assets/images/guide-icons2/howto.svg"
                  alt="icon"
                  className="img-fluid icon-default"
                />
                <img
                  src="/assets/images/guide-icons2/howto2.svg"
                  alt="icon"
                  className="img-fluid icon-hover"
                />
                <span>How do I use proxies?</span>
              </Link>
              {selectedMenu === "useProxies" && (
                <ul>
                  <li>
                    <Link
                      to="#"
                      onClick={(e) => handleSubToggle("pc", e)}
                      className={`sub-menu-link ${selectedSubMenu === "pc" ? "active" : ""}`}
                    >
                      <img
                        src="/assets/images/guide-icons2/computer.svg"
                        alt="icon"
                        className="img-fluid icon-default"
                      />
                      <img
                        src="/assets/images/guide-icons2/computer2.svg"
                        alt="icon"
                        className="img-fluid icon-hover"
                      />
                      {navOpen && <>PC</>}
                    </Link>
                    {selectedSubMenu === "pc" && (
                      <ul>
                        <li class="recommended-item">
                          <Link
                            to="/guide/lightningproxies-extension"
                            className={`sub-menu-link ${
                              location.pathname ===
                              "/guide/lightningproxies-extension"
                                ? "active"
                                : ""
                            }`}
                            onClick={handleMenuClick}
                          >
                            <img
                              src="/assets/images/guide-icons2/star.svg"
                              alt="icon"
                              className="img-fluid icon-default"
                            />
                            <img
                              src="/assets/images/guide-icons2/star2.svg"
                              alt="icon"
                              className="img-fluid icon-hover"
                            />
                            {navOpen && <>LightningProxies Extension</>}
                          </Link>
                        </li>

                        <li>
                          <Link
                            to="/guide/switchy-omega-proxy"
                            className={`sub-menu-link ${
                              location.pathname === "/guide/switchy-omega-proxy"
                                ? "active"
                                : ""
                            }`}
                            onClick={handleMenuClick}
                          >
                            <img
                              src="/assets/images/guide-icons2/star.svg"
                              alt="icon"
                              className="img-fluid icon-default"
                            />
                            <img
                              src="/assets/images/guide-icons2/star2.svg"
                              alt="icon"
                              className="img-fluid icon-hover"
                            />
                            {navOpen && <>SwitchyOmega Extension</>}
                          </Link>
                        </li>
                      </ul>
                    )}
                  </li>
                  <li>
                    <Link
                      to="/guide/android"
                      className={`sub-menu-link ${
                        location.pathname === "/guide/android" ? "active" : ""
                      }`}
                      onClick={() => {
                        setSelectedMenu("");
                        setSelectedSubMenu("");
                      }}
                    >
                      <img
                        src="/assets/images/guide-icons2/android.svg"
                        alt="icon"
                        className="img-fluid icon-default"
                      />
                      <img
                        src="/assets/images/guide-icons2/android2.svg"
                        alt="icon"
                        className="img-fluid icon-hover"
                      />
                      {navOpen && <>Android</>}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/guide/iphone"
                      className={`sub-menu-link ${
                        location.pathname === "/guide/iphone" ? "active" : ""
                      }`}
                      onClick={() => {
                        setSelectedMenu("");
                        setSelectedSubMenu("");
                      }}
                    >
                      <img
                        src="/assets/images/guide-icons2/apple.svg"
                        alt="icon"
                        className="img-fluid icon-default"
                      />
                      <img
                        src="/assets/images/guide-icons2/apple2.svg"
                        alt="icon"
                        className="img-fluid icon-hover"
                      />
                      {navOpen && <>iPhone</>}
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="#"
                      onClick={(e) => handleSubToggle("antibrowser", e)}
                      className={`sub-menu-link ${selectedSubMenu === "antibrowser" ? "active" : ""}`}
                    >
                      <img
                        src="/assets/images/guide-icons2/antibrowsers.svg"
                        alt="icon"
                        className="img-fluid icon-default"
                      />
                      <img
                        src="/assets/images/guide-icons2/antibrowsers2.svg"
                        alt="icon"
                        className="img-fluid icon-hover"
                      />
                      {navOpen && <>Anti Detect Browsers</>}
                    </Link>
                    {selectedSubMenu === "antibrowser" && (
                      <ul>
                        <li>
                          <Link
                            to="/guide/dolphin"
                            className={`sub-menu-link ${
                              location.pathname === "/guide/dolphin"
                                ? "active"
                                : ""
                            }`}
                            onClick={handleMenuClick}
                          >
                            <img
                              src="/assets/images/guide-icons2/star.svg"
                              alt="icon"
                              className="img-fluid icon-default"
                            />
                            <img
                              src="/assets/images/guide-icons2/star2.svg"
                              alt="icon"
                              className="img-fluid icon-hover"
                            />
                            {navOpen && <>Dolphin[anty]</>}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/guide/adspower"
                            className={`sub-menu-link ${
                              location.pathname === "/guide/adspower"
                                ? "active"
                                : ""
                            }`}
                            onClick={handleMenuClick}
                          >
                            <img
                              src="/assets/images/guide-icons2/star.svg"
                              alt="icon"
                              className="img-fluid icon-default"
                            />
                            <img
                              src="/assets/images/guide-icons2/star2.svg"
                              alt="icon"
                              className="img-fluid icon-hover"
                            />
                            {navOpen && <>AdsPower</>}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/guide/bitbrowser"
                            className={`sub-menu-link ${
                              location.pathname === "/guide/bitbrowser"
                                ? "active"
                                : ""
                            }`}
                            onClick={handleMenuClick}
                          >
                            <img
                              src="/assets/images/guide-icons2/star.svg"
                              alt="icon"
                              className="img-fluid icon-default"
                            />
                            <img
                              src="/assets/images/guide-icons2/star2.svg"
                              alt="icon"
                              className="img-fluid icon-hover"
                            />
                            {navOpen && <>BitBrowser</>}
                          </Link>
                        </li>
                      </ul>
                    )}
                  </li>
                </ul>
              )}
            </li>
          </ul>
        </div>

        <div className="menu-set">
          <h6>SUBSCRIPTIONS</h6>
          <ul>
            <li>
              <Link
                to="/guide/extend-my-plan"
                className={`sub-menu-link ${
                  location.pathname === "/guide/extend-my-plan" ? "active" : ""
                }`}
                onClick={handleMenuClick}
              >
                <img
                  src="/assets/images/guide-icons2/extendplan.svg"
                  alt="icon"
                  className="img-fluid icon-default"
                />
                <img
                  src="/assets/images/guide-icons2/extendplan2.svg"
                  alt="icon"
                  className="img-fluid icon-hover"
                />
                <span>How do I extend my plan?</span>
              </Link>
              <Link
                to="/guide/subusers"
                className={`sub-menu-link ${
                  location.pathname === "/guide/subusers" ? "active" : ""
                }`}
                onClick={handleMenuClick}
              >
                <img
                  src="/assets/images/sidebar-iconsV2/subusers-2.svg"
                  alt="icon"
                  className="img-fluid icon-default"
                />
                <img
                  src="/assets/images/sidebar-iconsV2/subusers.svg"
                  alt="icon"
                  className="img-fluid icon-hover"
                />
                <span>How can I create Subusers?</span>
              </Link>
              <Link
                to="/guide/sticky-proxies"
                className={`sub-menu-link ${
                  location.pathname === "/guide/sticky-proxies" ? "active" : ""
                }`}
                onClick={handleMenuClick}
              >
                <img
                  src="/assets/images/guide-icons2/question.svg"
                  alt="icon"
                  className="img-fluid icon-default"
                />
                <img
                  src="/assets/images/guide-icons2/question2.svg"
                  alt="icon"
                  className="img-fluid icon-hover"
                />
                <span>How do I use Sticky Proxies?</span>
              </Link>
              <Link
                to="/guide/country-settings"
                className={`sub-menu-link ${
                  location.pathname === "/guide/country-settings"
                    ? "active"
                    : ""
                }`}
                onClick={handleMenuClick}
              >
                <img
                  src="/assets/images/guide-icons2/flags.svg"
                  alt="icon"
                  className="img-fluid icon-default"
                />
                <img
                  src="/assets/images/guide-icons2/flags2.svg"
                  alt="icon"
                  className="img-fluid icon-hover"
                />
                <span>How to change country settings?</span>
              </Link>
              <Link
                to="/guide/renew-auto-plan"
                className={`sub-menu-link ${
                  location.pathname === "/guide/renew-auto-plan" ? "active" : ""
                }`}
                onClick={handleMenuClick}
              >
                <img
                  src="/assets/images/guide-icons2/renewplan.svg"
                  alt="icon"
                  className="img-fluid icon-default"
                />
                <img
                  src="/assets/images/guide-icons2/renewplan2.svg"
                  alt="icon"
                  className="img-fluid icon-hover"
                />
                <span>Renew Auto Plan</span>
              </Link>
            </li>
          </ul>
        </div>

        <div className="menu-set">
          <h6>API</h6>
          <ul>
            <li>
              <Link
                to="/guide/create-api-link"
                className={`sub-menu-link ${
                  location.pathname === "/guide/create-api-link" ? "active" : ""
                }`}
                onClick={handleMenuClick}
              >
                <img
                  src="/assets/images/guide-icons2/api.svg"
                  alt="icon"
                  className="img-fluid icon-default"
                />
                <img
                  src="/assets/images/guide-icons2/api2.svg"
                  alt="icon"
                  className="img-fluid icon-hover"
                />
                <span>Create an API link</span>
              </Link>
            </li>
            <li>
              <Link
                to="/guide/integration-codes"
                className={`sub-menu-link ${
                  location.pathname === "/guide/integration-codes"
                    ? "active"
                    : ""
                }`}
                onClick={handleMenuClick}
              >
                <img
                  src="/assets/images/guide-icons2/coding.svg"
                  alt="icon"
                  className="img-fluid icon-default"
                />
                <img
                  src="/assets/images/guide-icons2/coding2.svg"
                  alt="icon"
                  className="img-fluid icon-hover"
                />
                <span>Integration Codes</span>
              </Link>
            </li>
          </ul>
        </div>

        <div className="menu-set">
          <h6>TOOLS</h6>
          <ul>
            <li>
              <Link
                to="/guide/proxy-checker"
                className={`sub-menu-link ${
                  location.pathname === "/guide/proxy-checker" ? "active" : ""
                }`}
                onClick={handleMenuClick}
              >
                <img
                  src="/assets/images/guide-icons2/proxychecker.svg"
                  alt="icon"
                  className="img-fluid icon-default"
                />
                <img
                  src="/assets/images/guide-icons2/proxychecker2.svg"
                  alt="icon"
                  className="img-fluid icon-hover"
                />
                <span>Proxy Checker</span>
              </Link>
            </li>
          </ul>
        </div>

        <div className="menu-set">
          <h6>CONNECT WITH US</h6>
          <ul>
            <li>
              <a href="https://discord.com/invite/FUxFcaazX6">
                <img
                  src="/assets/images/sidebar-icons/discord.svg"
                  alt="icon"
                  className="img-fluid"
                />
                <span>Join Discord Server</span>
              </a>
            </li>

            <li>
              <a href="https://t.me/lightningproxies">
                <img
                  src="/assets/images/sidebar-icons/telegram.svg"
                  alt="icon"
                  className="img-fluid"
                />
                <span>Join Telegram Channel</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Footer */}
      <div className="sidebar-ftr mt-5">
        <ul>
          <li>
            <Link to="/terms-of-services">Terms of Service</Link>
          </li>
          <li>
            <span>|</span>
          </li>
          <li>
            <a href="/privacy-policy">Privacy Policy</a>
          </li>
        </ul>

        <ul>
          <li>
            <a href="https://t.me/lightningproxies">
              <img
                src={"/assets/images/sidebar-icons/social-01.svg"}
                alt="icon"
                className="img-fluid"
                style={{ marginRight: "1rem", cursor: "pointer" }}
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src={"/assets/images/sidebar-icons/social-02.svg"}
                alt="icon"
                className="img-fluid"
                style={{ marginRight: "1rem", cursor: "pointer" }}
              />
            </a>
          </li>
          <li>
            <a href="https://discord.com/invite/FUxFcaazX6">
              <img
                src={"/assets/images/sidebar-icons/social-03.svg"}
                alt="icon"
                className="img-fluid"
                style={{ marginRight: "1rem", cursor: "pointer" }}
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src={"/assets/images/sidebar-icons/social-04.svg"}
                alt="icon"
                className="img-fluid"
                style={{ marginRight: "1rem", cursor: "pointer" }}
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src={"/assets/images/fb.svg"}
                alt="icon"
                className="img-fluid"
                style={{ marginRight: "1rem", cursor: "pointer" }}
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default GuideSidebar;
