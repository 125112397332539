import React, { lazy, useEffect } from "react";
import { createContext } from "react";
import axios from "axios";
import { backendServerBaseURL } from "../utils/backendServerBaseURL";
import { useState } from "react";
const LandingNavbar = lazy(() => import("../components/LandingNavbar"));
const LandingFooter = lazy(() => import("../components/LandingFooter"));
const LandingAnnouncement = lazy(
  () => import("../components/LandingAnnouncement")
);
const LandingTheme = lazy(() => import("../theme/LandingTheme"));

export const LandingContext = createContext();

const LandingLayout = ({ children }) => {
  const [landingData, setLandingData] = useState({});
  const getLanding = async () => {
    await axios
      .get(`${backendServerBaseURL}/landing`)
      .then((response) => {
        setLandingData(response.data.payload);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getLanding();
  }, []);

  return (
    <React.Suspense fallback={<></>}>
      <LandingContext.Provider value={{ landingData, setLandingData }}>
        <React.Suspense fallback={<></>}>
          <LandingTheme />
        </React.Suspense>
        <LandingAnnouncement announcements={landingData.Announcements} />
        <LandingNavbar />
        {children}
        <LandingFooter />
      </LandingContext.Provider>
    </React.Suspense>
  );
};

export default LandingLayout;
