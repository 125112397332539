import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMe, selectMe } from "../redux/slices/generalSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ThemeContext } from "../App";
import { formatTimeWithBar } from "../utils/formatTime";

const UserNavbar = React.memo(({ handleMobileToggle }) => {
  const dispatch = useDispatch();
  const me = useSelector(selectMe);
  const navigate = useNavigate();
  const themeContextValue = useContext(ThemeContext);

  const [locationName, setLocationName] = useState("");

  const location = useLocation();
  const activePage = location.pathname.split("/")[1];

  useEffect(() => {
    switch (activePage) {
      case "dashboard":
        setLocationName("");
        break;
      case "plans":
      case "purchase-plan":
        setLocationName("Purchase Plan");
        break;
      case "topup":
        setLocationName("Deposit Balance");
        break;
      case "invoices":
        setLocationName("Invoices");
        break;
      case "bill":
        setLocationName("Invoice details");
        break;
      case "referal":
        setLocationName("Referral");
        break;
      case "faq":
        setLocationName("FAQs");
        break;
      case "api-docs":
        setLocationName("API");
        break;
      case "terms-of-services":
        setLocationName("TOS");
        break;
      case "privacy":
        setLocationName("Privacy Policy");
        break;
      case "generator":
        setLocationName("Generate Proxy");
        break;
      case "my-profile":
        setLocationName("Settings");
        break;

      default:
        break;
    }
  }, [activePage]);

  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem("theme", "light") === "dark"
  );

  const handleCheckboxChange = () => {
    setIsDarkMode((prevMode) => !prevMode);
    // window.location.reload();
  };
  useEffect(() => {
    dispatch(getMe());
  }, []);

  useEffect(() => {
    console.log(isDarkMode);
    const newTheme = isDarkMode ? "dark" : "light";
    console.log(newTheme);

    // Update theme context and local storage
    themeContextValue.setselectedTheme(newTheme);
    localStorage.setItem("theme", newTheme);

    if (isDarkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [isDarkMode]);

  return (
    <header class="header-part">
      <div class="breadcumb-box">
        <button
          type="button"
          class={"btn p-0 "}
          id="mobileToggle"
          onClick={() =>
            //  handleMobileClick()
            handleMobileToggle()
          }
        >
          <img
            src="/assets/images/icons/bars.svg"
            alt="="
            class="img-fluid d-light"
          />
          <img
            src="/assets/images/icons/bars-dark.svg"
            alt="="
            class="img-fluid d-dark"
          />
        </button>

        <ul>
          <li>
            <a href="/dashboard">
              <img
                src="/assets/images/icons/breadcumb.svg"
                alt="icon"
                class="img-fluid d-light"
              />{" "}
              <img
                src="/assets/images/dak-mode/home.svg"
                alt="icon"
                class="img-fluid d-dark"
              />
            </a>
          </li>

          {locationName && (
            <>
              <li>
                <a href="#">
                  <i class="fa fa-angle-right d-light"></i>
                  <i class="fa fa-angle-right text-light d-dark"></i>
                </a>
              </li>
              <li>
                <h6 className="fs-6 ms-2">{locationName}</h6>
              </li>
            </>
          )}
        </ul>
      </div>

      <div class="right-part">
        <div class="dark-light-mode">
          <ul>
            <li>
              <a href="#">
                <img
                  src="/assets/images/icons/moon.svg"
                  alt="moon"
                  class="img-fluid d-light"
                />
                <img
                  src="/assets/images/dak-mode/moon.svg"
                  alt="moon"
                  class="img-fluid d-dark"
                />
              </a>
            </li>
            <li>
              <div class="form-check form-switch me-0 me-md-1">
                <input
                  class="form-check-input d-none d-md-block bg-primary"
                  type="checkbox"
                  role="switch"
                  id="toggleCheckboxTheme"
                  style={{
                    width: "2.25rem",
                    height: "1.3rem",
                    marginBottom: "0.2rem",
                  }}
                  defaultChecked={
                    localStorage.getItem("theme", "light") === "light"
                  }
                  onChange={() => handleCheckboxChange()}
                />
                <input
                  class="form-check-input d-md-none d-sm-block bg-primary"
                  type="checkbox"
                  role="switch"
                  id="toggleCheckboxTheme"
                  style={{
                    marginBottom: "0.2rem",
                  }}
                  defaultChecked={
                    localStorage.getItem("theme", "light") === "light"
                  }
                  onChange={() => handleCheckboxChange()}
                />
              </div>
            </li>
            <li>
              <a href="#">
                <img
                  src="/assets/images/icons/sun.svg"
                  alt="moon"
                  class="img-fluid d-light"
                />
                <img
                  src="/assets/images/dak-mode/sun.svg"
                  alt="moon"
                  class="img-fluid d-dark"
                />
              </a>
            </li>
          </ul>
        </div>

        <div class="balnce-box">
          <button
            type="button"
            class="btn btn-balance d-md-block d-none"
            onClick={() => {
              navigate("/topup");
            }}
          >
            <img
              src="/assets/images/icons/balance-icon.svg"
              alt="Balance"
              class="img-fluid"
            />{" "}
            Balance : ${me?.balance ? Math.round(me?.balance * 100) / 100 : "0"}{" "}
            <i style={{ color: "#2FB851E5" }} class="fa fa-angle-right " />
          </button>
        </div>

        <div class="balnce-box">
          <button
            type="button"
            class="btn btn-balance btn-date  d-lg-block d-none"
          >
            <img
              src="/assets/images/icons/date.svg"
              alt="Balance"
              class="img-fluid d-light"
            />
            <img
              src="/assets/images/dak-mode/date.svg"
              alt="moon"
              class="img-fluid d-dark"
            />
            {formatTimeWithBar(new Date())}
          </button>
        </div>

        <div class="bttn-box">
          <Link to="/my-profile" className="text-truncate">
            <i class="fa fa-user"></i> {me?.username}
          </Link>
          <a
            href="#"
            onClick={() => {
              //   localStorage.clear();
              localStorage.removeItem("accessToken");
              localStorage.removeItem("user");
              localStorage.removeItem("hideDepletedPlans");
              localStorage.setItem("theme", "light");
              window.open("/login", "_self");
              // themeContextValue.setselectedTheme("light");
            }}
          >
            <img
              src="/assets/images/icons/logout.svg"
              alt="icon"
              class="img-fluid"
            />
          </a>
        </div>
      </div>
    </header>
  );
});

export default UserNavbar;
