import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { Link, useNavigate } from "react-router-dom";

const GuideSidebar = ({ handleCommonToggle }) => {
  const [SocialLinks, setSocialLinks] = useState([]);
  const [navOpen, setnavOpen] = useState(true);
  const [selectedMenu, setselectedMenu] = useState("");

  useEffect(() => {
    const storedSelectedMenu = localStorage.getItem("selectedMenu");

    if (storedSelectedMenu) {
      setselectedMenu(storedSelectedMenu);
    } else {
    }
  }, []);

  const handleSelectedMenu = (menu) => {
    setselectedMenu(menu);
    localStorage.setItem("selectedMenu", menu);
  };

  return (
    <div className="d-flex flex-column justify-content-between">
      <div className="d-flex flex-column">
        <div class="logo-box">
          <a href="/">
            <img
              src="/assets/images/logo.svg"
              alt="Logo"
              class="img-fluid logo-big d-light"
            />
            <img
              src="/assets/images/dak-mode/logo.svg"
              alt="Logo"
              class="img-fluid logo-big d-dark"
            />
            <img
              src="/assets/images/icons/favicon.svg"
              alt="Logo"
              class="img-fluid logo-small"
            />
          </a>

          <button
            class="btn btn-toggle"
            type="button"
            id="menuToggle"
            onClick={() => {
              setnavOpen(!navOpen);
              // handleToggleClick();
              handleCommonToggle();
            }}
          >
            <i class="fa fa-angle-left"></i>
          </button>
        </div>

        <div class="menu-set mt-0 border-0 ">
          <h6>Public API</h6>

          <ul>
            <li
              onClick={() => {
                handleSelectedMenu("dashboard1");
              }}
            >
              <Link
                to={"/customer-proxy-list"}
                className={
                  selectedMenu === "dashboard1" ? "active" : "not-active"
                }
              >
                <img
                  src="/assets/images/publicApi/proxylist.svg"
                  alt="icon"
                  class="img-fluid active"
                />
                <img
                  src="/assets/images/publicApi/proxylist.svg"
                  alt="icon"
                  class="img-fluid not-active"
                />
                <span>
                  <span className="http-method">GET</span> Proxies List
                </span>
              </Link>
            </li>
            <li
              onClick={() => {
                handleSelectedMenu("info");
              }}
            >
              <Link
                to="/customer-proxy-info"
                className={selectedMenu === "info" ? "active" : "not-active"}
              >
                <img
                  src="/assets/images/publicApi/proxyinfo.svg"
                  alt="icon"
                  class="img-fluid active"
                />
                <img
                  src="/assets/images/publicApi/proxyinfo.svg"
                  alt="icon"
                  class="img-fluid not-active"
                />
                <span>
                  <span className="http-method">GET</span> Proxies Info
                </span>
              </Link>
            </li>
            <li
              onClick={() => {
                handleSelectedMenu("whitelist");
              }}
            >
              <Link
                to="/customer-proxy-whitelist"
                className={
                  selectedMenu === "whitelist" ? "active" : "not-active"
                }
              >
                <img
                  src="/assets/images/publicApi/add-del.svg"
                  alt="icon"
                  class="img-fluid active"
                />
                <img
                  src="/assets/images/publicApi/add-del.svg"
                  alt="icon"
                  class="img-fluid not-active"
                />
                <span>
                  <span className="http-method">GET</span> Proxies Whitelist
                </span>
              </Link>
            </li>
            <li
              onClick={() => {
                handleSelectedMenu("usagelogs");
              }}
            >
              <Link
                to="/publicapi-usage-logs"
                className={
                  selectedMenu === "usagelogs" ? "active" : "not-active"
                }
              >
                <img
                  src="/assets/images/sidebar-icons/data-log2.svg"
                  alt="icon"
                  class="img-fluid active"
                />
                <img
                  src="/assets/images/sidebar-icons/data-log.svg"
                  alt="icon"
                  class="img-fluid not-active"
                />
                <span>
                  <span className="http-method">GET</span> Proxy Usage Log
                </span>
              </Link>
            </li>
            <hr></hr>
            <li
              onClick={() => {
                handleSelectedMenu("location");
              }}
            >
              <Link
                to="/customer-proxy-location"
                className={
                  selectedMenu === "location" ? "active" : "not-active"
                }
              >
                <img
                  src="/assets/images/publicApi/location.svg"
                  alt="icon"
                  class="img-fluid active"
                />
                <img
                  src="/assets/images/publicApi/location.svg"
                  alt="icon"
                  class="img-fluid not-active"
                />
                <span>
                  <span className="http-method">GET</span> Proxies Location
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div class="sidebar-ftr mt-5">
        <ul>
          <li
            onClick={() => {
              handleSelectedMenu("terms-of-services");
            }}
          >
            <Link to="/terms-of-services">Terms of Service</Link>
          </li>
          <li>
            <span>|</span>
          </li>
          <li>
            <a href="/privacy-policy">Privacy Policy</a>
          </li>
        </ul>

        <ul>
          <li>
            <img
              src={"/assets/images/sidebar-icons/social-01.svg"}
              alt="icon"
              className="img-fluid"
              onClick={() => {
                const targetLink =
                  SocialLinks?.find((s) => s.social === "Telegram")?.link || "";
                window.open(targetLink, "_self");
              }}
              style={{ marginRight: "1rem", cursor: "pointer" }}
            />
          </li>
          <li>
            <img
              src={"/assets/images/sidebar-icons/social-02.svg"}
              alt="icon"
              className="img-fluid"
              onClick={() => {
                const targetLink =
                  SocialLinks?.find((s) => s.social === "Instagram")?.link ||
                  "";
                window.open(targetLink, "_self");
              }}
              style={{ marginRight: "1rem", cursor: "pointer" }}
            />
          </li>
          <li>
            <img
              src={"/assets/images/sidebar-icons/social-03.svg"}
              alt="icon"
              className="img-fluid"
              onClick={() => {
                const targetLink =
                  SocialLinks?.find((s) => s.social === "Discord")?.link || "";
                window.open(targetLink, "_self");
              }}
              style={{ marginRight: "1rem", cursor: "pointer" }}
            />
          </li>
          <li>
            <img
              src={"/assets/images/sidebar-icons/social-04.svg"}
              alt="icon"
              className="img-fluid"
              onClick={() => {
                const targetLink =
                  SocialLinks?.find((s) => s.social === "Twitter")?.link || "";
                window.open(targetLink, "_self");
              }}
              style={{ marginRight: "1rem", cursor: "pointer" }}
            />
          </li>
          <li>
            <img
              src={"/assets/images/fb.svg"}
              alt="icon"
              className="img-fluid"
              onClick={() => {
                const targetLink =
                  SocialLinks?.find((s) => s.social === "Facebook")?.link || "";
                window.open(targetLink, "_self");
              }}
              style={{ marginRight: "1rem", cursor: "pointer" }}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default GuideSidebar;
