import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { backendServerBaseURL } from "../../utils/backendServerBaseURL";
import { getMe } from "./generalSlice";

const initialState = {
  partnersLinks: [],
  totalUsersWithPartnersUrl: 0,
  totalUniqueBuyers: 0,

  totalVisitor: 0,
  rotatingProxy: null,
  allPlans: [],
  planToEdit: null,

  settings: null,

  accounts: [],
  accountsPager: null,
  accountSearch: "",
  viewAccountInfo: null,
  singleAccountSessions: [],
  singleAccountSessionsPager: [],
  singleAccountRewards: [],
  singleAccountRewardsPager: [],

  accountPager: null,

  suggestions: [],
  suggestionsPager: null,

  coupons: [],
  couponsPager: null,
  couponToEdit: null,

  // Subscriptions
  subscriptions: [],
  subscriptionsPager: null,
  subscriptionsSearch: "",
  subscriptionToEdit: null,

  // Statistics
  statistics: null,

  allTickets: [],
  TicketsPager: null,
  TicketStats: null,
  ticketSearch: "",
};

// Plans
export const getSinglePlan = createAsyncThunk(
  "admin/getSinglePlan",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {},
    };

    let response = await axios.get(
      `${backendServerBaseURL}/admin/plans/${payload.planId}`,
      config
    );

    if (response.status === 200 && response.data.message === "Plan") {
      thunkAPI.dispatch(updatePlanToEdit(response.data.payload));
    }
  }
);

export const getAllPlans = createAsyncThunk(
  "admin/getAllPlans",
  async (_, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {},
    };

    let response = await axios.get(
      `${backendServerBaseURL}/admin/plans`,
      config
    );

    if (response.status === 200 && response.data.message === "Plans list") {
      thunkAPI.dispatch(updateAllPlans(response.data.payload.plans));
    }
  }
);

export const supportgetAllPlans = createAsyncThunk(
  "admin/supportgetAllPlans",
  async (_, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {},
    };

    let response = await axios.get(
      `${backendServerBaseURL}/support/plans`,
      config
    );

    if (response.status === 200 && response.data.message === "Plans list") {
      thunkAPI.dispatch(updateAllPlans(response.data.payload.plans));
    }
  }
);

export const createPlan = createAsyncThunk(
  "admin/createPlan",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.post(
      `${backendServerBaseURL}/admin/plans`,
      {
        title: payload.title,
        description: payload.description,
        type: payload.type,
        provider: payload.provider,
        price: payload.price,
        bandwidthPrices: JSON.stringify(payload.bandwidthPrices),
        threadPrices: JSON.stringify(payload.threadPrices),
        minRenewDays: payload.minRenewDays,
        maxRenewDays: payload.maxRenewDays,
        duration: payload.duration,
        bandwidth: payload.bandwidth,
        throughput: payload.throughput,
        threads: payload.threads,
        enabled: payload.status,
        trial: payload.trial,
        order: payload.order,
        serverGeo: payload.serverGeo,
        serverGeoOptions: payload.serverGeoOptions,
        currentThreads: payload.currentThreads,
        customPlan: payload.customPlan,
        residentialType: payload.residentialType,
        supportshow: payload.supportshow,
      },
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Plan created successfully"
    ) {
      payload.navigate("/admin/plans");
    }

    if (response.status === 200 && response.data.message === "error") {
      // payload.setErrors({ afterSubmit: response.data.errors[0] });
    }
  }
);

export const editPlan = createAsyncThunk(
  "admin/editPlan",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.patch(
      `${backendServerBaseURL}/admin/plans/${payload.planId}`,
      {
        title: payload.title,
        description: payload.description,
        type: payload.type,
        provider: payload.provider,
        price: payload.price,
        bandwidthPrices: JSON.stringify(payload.bandwidthPrices),
        threadPrices: JSON.stringify(payload.threadPrices),
        minRenewDays: payload.minRenewDays,
        maxRenewDays: payload.maxRenewDays,
        duration: payload.duration,
        bandwidth: payload.bandwidth,
        throughput: payload.throughput,
        threads: payload.threads,
        enabled: payload.status,
        trial: payload.trial,
        order: payload.order,
        serverGeo: payload.serverGeo,
        serverGeoOptions: payload.serverGeoOptions,
        currentThreads: payload.currentThreads,
        customPlan: payload.customPlan,
        residentialType: payload.residentialType,
        supportshow: payload.supportshow,
      },
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Plan updated successfully"
    ) {
      payload.navigate("/admin/plans");
    }

    if (response.status === 200 && response.data.message === "error") {
      // payload.setErrors({ afterSubmit: response.data.errors[0] });
    }
  }
);

export const deletePlan = createAsyncThunk(
  "admin/deletePlan",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.delete(
      `${backendServerBaseURL}/admin/plans/${payload.planId}`,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Plan deleted successfully"
    ) {
      thunkAPI.dispatch(getAllPlans());
      // payload.reset();
    }

    if (response.status === 200 && response.data.message === "error") {
      // payload.setErrors({ afterSubmit: response.data.errors[0] });
    }
  }
);

// Settings
export const getAllSettings = createAsyncThunk(
  "admin/getAllSettings",
  async (_, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {},
    };

    let response = await axios.get(
      `${backendServerBaseURL}/admin/settings`,
      config
    );

    if (response.status === 200 && response.data.message === "Settings list") {
      thunkAPI.dispatch(updateAllSettings(response.data.payload.settings));
    }
  }
);

export const updateSettings = createAsyncThunk(
  "admin/updateSettings",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let body = payload;
    if (typeof payload["FAQ"] !== "string") {
      body["FAQ"] = JSON.stringify(payload["FAQ"]);
      body["Status"] = JSON.stringify(payload["Status"]);
      body["OwnServers"] = JSON.stringify(payload["OwnServers"]);
      body["SocialLinks"] = JSON.stringify(payload["SocialLinks"]);
      body["RenewResiBandwidthPrices"] = JSON.stringify(
        payload["RenewResiBandwidthPrices"]
      );
      body["RenewISPPrices"] = JSON.stringify(payload["RenewISPPrices"]);
      body["RenewNEWIPv6Prices"] = JSON.stringify(
        payload["RenewNEWIPv6Prices"]
      );
      body["RenewMobileBandwidthPrices"] = JSON.stringify(
        payload["RenewMobileBandwidthPrices"]
      );
      body["RenewIPv6BandwidthPrices"] = JSON.stringify(
        payload["RenewIPv6BandwidthPrices"]
      );
    }

    let response = await axios.put(
      `${backendServerBaseURL}/admin/settings`,
      body,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Setting updated successfully"
    ) {
      thunkAPI.dispatch(updateAllSettings(response.data.payload.settings));
      payload.notify();
    }

    if (response.status === 200 && response.data.message === "error") {
      // payload.setErrors({ afterSubmit: response.data.errors[0] });
    }
  }
);

// Accounts
export const getAllAccounts = createAsyncThunk(
  "admin/getAllAccounts",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: payload.page,
        size: 20,
        search: thunkAPI.getState().admin.accountSearch,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/admin/accounts`,
      config
    );

    if (response.status === 200 && response.data.message === "Accounts list") {
      thunkAPI.dispatch(updateAccounts(response.data.payload.accounts));
      thunkAPI.dispatch(updateAccountsPager(response.data.pager));
    }
  }
);

export const getTrialPage = createAsyncThunk(
  "support/getTrialPage",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: payload.page,
        size: 20,
        search: thunkAPI.getState().admin.accountSearch,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/support/trialpage`,
      config
    );

    if (response.status === 200 && response.data.message === "Accounts list") {
      thunkAPI.dispatch(updateAccounts(response.data.payload.accounts));
      thunkAPI.dispatch(updateAccountsPager(response.data.pager));
    }
  }
);

export const getAllAccounts2 = createAsyncThunk(
  "admin/getAllAccounts",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: payload.page,
        size: 20,
        search: thunkAPI.getState().admin.accountSearch,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/admin/Raccounts`,
      config
    );

    if (response.status === 200 && response.data.message === "Accounts list") {
      thunkAPI.dispatch(updateAccounts(response.data.payload.accounts));
      thunkAPI.dispatch(updateAccountsPager(response.data.pager));
    }
  }
);

export const getMailsender = createAsyncThunk(
  "admin/getMailsender",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/admin/mail-sender`,
      config
    );
  }
);

export const getSingleAccount = createAsyncThunk(
  "admin/getSingleAccount",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: payload.page,
        size: 10,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/admin/accounts/${payload.userId}`,
      config
    );

    if (response.status === 200 && response.data.message === "Account") {
      thunkAPI.dispatch(updateViewAccountInfo(response.data.payload));
      thunkAPI.dispatch(updateAccountPager(response.data.pager));
    }
  }
);

export const getSingleAccountSupport = createAsyncThunk(
  "admin/getSingleAccount",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: payload.page,
        size: 10,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/support/accounts/${payload.userId}`,
      config
    );

    if (response.status === 200 && response.data.message === "Account") {
      thunkAPI.dispatch(updateViewAccountInfo(response.data.payload));
      thunkAPI.dispatch(updateAccountPager(response.data.pager));
    }
  }
);

export const forgotpasswordSupport = createAsyncThunk(
  "admin/forgotpasswordSupport",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.post(
      `${backendServerBaseURL}/support/send/forgot-passwordlink/${payload.userId}`,
      {},
      config
    );

    if (response.status === 200) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const postDeleteGoogle = createAsyncThunk(
  "admin/deleteGoogleid",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.post(
      `${backendServerBaseURL}/admin/accounts/delgoogle/${payload.userId}`,
      {},
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "GoogleLoginClientId deleted successfully"
    ) {
      window.location.reload();
    }
  }
);

export const postDeleteGoogleSupport = createAsyncThunk(
  "admin/deleteGoogleid",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.post(
      `${backendServerBaseURL}/support/accounts/delgoogle/${payload.userId}`,
      {},
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "GoogleLoginClientId deleted successfully"
    ) {
      window.location.reload();
    }
  }
);

export const resetWhitelistLimit = createAsyncThunk(
  "admin/resetWhitelistLimit",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      let response = await axios.get(
        `${backendServerBaseURL}/admin/resetwhitelistlimit/${payload.subscriptionId}`,
        config
      );

      if (response.status === 200) {
        return {
          success: true,
          message: "Whitelist limit has been reset successfully!",
        };
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({
        success: false,
        message: "Failed to reset whitelist limit.",
      });
    }
  }
);

export const setWhitelistLimit = createAsyncThunk(
  "admin/setWhitelistLimit",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      let response = await axios.get(
        `${backendServerBaseURL}/admin/setwhitelistlimit/${payload.subscriptionId}/${payload.limit}`,
        config
      );

      if (response.status === 200) {
        return {
          success: true,
          message: "Whitelist limit has been set successfully!",
        };
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({
        success: false,
        message: "Failed to set whitelist limit.",
      });
    }
  }
);

export const postReVerify = createAsyncThunk(
  "admin/reverify",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.post(
      `${backendServerBaseURL}/admin/accounts/reverify/${payload.userId}`,
      {},
      config
    );

    if (response.status === 200) {
      window.location.reload();
    }
  }
);

export const updateAvatar = createAsyncThunk(
  "admin/updateAvatar",
  async ({ file, userid }, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    const formData = new FormData();
    formData.append("file", file);

    try {
      let response = await axios.post(
        `${backendServerBaseURL}/admin/updatemyavatar/${userid}`,
        formData,
        config
      );

      if (
        response.status === 200 &&
        response.data.message === "Profile updated successfully."
      ) {
        return response.data.avatarUrl;
      } else {
        return thunkAPI.rejectWithValue("Failed to update avatar.");
      }
    } catch (error) {
      console.error("Avatar update failed:", error);
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Avatar update failed."
      );
    }
  }
);

// Kullanıcı avatarını silme
export const deleteAvatar = createAsyncThunk(
  "admin/deleteAvatar",
  async (userid, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      let response = await axios.delete(
        `${backendServerBaseURL}/admin/deletemyavatar/${userid}`,
        config
      );

      if (
        response.status === 200 &&
        response.data.message === "Profile updated successfully."
      ) {
        return ""; // Avatar URL'yi boş yaparak siliyoruz
      } else {
        return thunkAPI.rejectWithValue("Failed to delete avatar.");
      }
    } catch (error) {
      console.error("Avatar delete failed:", error);
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Avatar delete failed."
      );
    }
  }
);

export const postReVerifySupport = createAsyncThunk(
  "admin/reverify",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.post(
      `${backendServerBaseURL}/support/accounts/reverify/${payload.userId}`,
      {},
      config
    );

    if (response.status === 200) {
      window.location.reload();
    }
  }
);

export const postVerify1 = createAsyncThunk(
  "admin/reverify1",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.post(
      `${backendServerBaseURL}/admin/accounts/verifyy/${payload.userId}`,
      {},
      config
    );

    if (response.status === 200) {
      window.location.reload();
    }
  }
);

export const postVerify1Support = createAsyncThunk(
  "admin/reverify1",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.post(
      `${backendServerBaseURL}/support/accounts/verifyy/${payload.userId}`,
      {},
      config
    );

    if (response.status === 200) {
      window.location.reload();
    }
  }
);

export const createNewReseller = createAsyncThunk(
  "admin/createNewAccount",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.post(
      `${backendServerBaseURL}/admin/reseller`,
      {
        username: payload.username,
        email: payload.email,
        password: payload.password,
        apikey: payload.apikey,
        resiprice: payload.resiprice,
        mobileprice: payload.mobileprice,
        dataprice1: payload.dataprice1,
        dataprice7: payload.dataprice7,
        dataprice30: payload.dataprice30,
        gbvalue: payload.gbvalue,
        ispprice: payload.ispprice,
        ipv6white: payload.ipv6white,
        datawhite: payload.datawhite,
        value: payload.value,
      },
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Account created successfully"
    ) {
      payload.navigate("/admin/reselleracc");
    }
  }
);

export const editReseller = createAsyncThunk(
  "admin/editAccount",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let body = {
      username: payload.username,
      email: payload.email,
      password: payload.password,
      manager: payload.manager,
      reseller: payload.reseller,
      cryptoPaymentProcessorEnabled: payload.cryptoPaymentProcessorEnabled,
      balancePaymentProcessorEnabled: payload.balancePaymentProcessorEnabled,
      apikey: payload.apikey,
      resiprice: payload.resiprice,
      mobileprice: payload.mobileprice,
      dataprice1: payload.dataprice1,
      dataprice7: payload.dataprice7,
      dataprice30: payload.dataprice30,
      gbvalue: payload.gbvalue,
      ispprice: payload.ispprice,
      ipv6white: payload.ipv6white,
      datawhite: payload.datawhite,
      value: payload.value,
    };

    let response = await axios.patch(
      `${backendServerBaseURL}/admin/reseller/${payload.userId}`,
      body,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Account updated successfully"
    ) {
      payload.navigate("/admin/reselleracc");
      thunkAPI.dispatch(getMe());
    }
  }
);

export const createNewAccount = createAsyncThunk(
  "admin/createNewAccount",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.post(
      `${backendServerBaseURL}/admin/accounts`,
      {
        username: payload.username,
        email: payload.email,
        password: payload.password,
      },
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Account created successfully"
    ) {
      payload.navigate("/admin/accounts");
    }
  }
);

export const editAccount = createAsyncThunk(
  "admin/editAccount",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let body = {
      username: payload.username,
      email: payload.email,
      password: payload.password,
      manager: payload.manager,
      support: payload.support,
      reseller: payload.reseller,
      cryptoPaymentProcessorEnabled: payload.cryptoPaymentProcessorEnabled,
      balancePaymentProcessorEnabled: payload.balancePaymentProcessorEnabled,
      stripePaymentProcessorEnabled: payload.stripePaymentProcessorEnabled,
      stripeLimit: payload.stripeLimit,
      message: payload.message,
      referralShares: [
        {
          name: "ReferralResidentialRewardRate",
          value: payload.ReferralResidentialRewardRate || 0,
        },
        {
          name: "ReferralDatacenterRewardRate",
          value: payload.ReferralDatacenterRewardRate || 0,
        },
        {
          name: "ReferralISPRewardRate",
          value: payload.ReferralISPRewardRate || 0,
        },
        {
          name: "ReferralIPv6RewardRate",
          value: payload.ReferralIPv6RewardRate || 0,
        },
        {
          name: "ReferralMobileRewardRate",
          value: payload.ReferralMobileRewardRate || 0,
        },
      ],
    };

    let response = await axios.patch(
      `${backendServerBaseURL}/admin/accounts/${payload.userId}`,
      body,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Account updated successfully"
    ) {
      payload.navigate("/admin/accounts");
      thunkAPI.dispatch(getMe());
    }
  }
);

export const deleteAccount = createAsyncThunk(
  "admin/deleteAccount",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.delete(
      `${backendServerBaseURL}/admin/accounts/${payload.userId}`,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Account deleted successfully"
    ) {
      thunkAPI.dispatch(
        getAllAccounts({ page: thunkAPI.getState().admin.accountsPager.page })
      );
    }
  }
);

export const Rdeletereseller = createAsyncThunk(
  "admin/deleteAccount",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.delete(
      `${backendServerBaseURL}/admin/reseller/${payload.userId}`,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Account deleted successfully"
    ) {
      thunkAPI.dispatch(
        getAllAccounts({ page: thunkAPI.getState().admin.accountsPager.page })
      );
    }
  }
);

export const deleteUserAccount = createAsyncThunk(
  "public/deleteAccount",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.delete(
      `${backendServerBaseURL}/public/accounts/${payload.userId}`,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Account deleted successfully"
    ) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      localStorage.setItem("theme", "light");
      window.open("/login", "_self");
    }
  }
);

export const updateAccountBalance = createAsyncThunk(
  "admin/updateAccountBalance",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.patch(
      `${backendServerBaseURL}/admin/accounts/${payload.userId}/balance`,
      {
        balanceToAddOrSub: payload.balanceToAddOrSub,
      },
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Account balance updated successfully"
    ) {
      payload.navigate(`/admin/accounts/${response.data.payload._id}`);
    }
  }
);

export const addNewSubscriptionToAccount = createAsyncThunk(
  "admin/addNewSubscriptionToAccount",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let requestBody = {
      plan: payload.plan,
    };

    if (
      payload.region &&
      typeof payload.region === "string" &&
      payload.region.trim() !== ""
    ) {
      requestBody.region = payload.region;
    }

    if (
      typeof payload.additionalField === "number" &&
      !isNaN(payload.additionalField)
    ) {
      requestBody.additionalField = payload.additionalField;
    }

    let response = await axios.post(
      `${backendServerBaseURL}/admin/accounts/${payload.userId}/add-subscription`,
      requestBody,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "New subscription created successfully"
    ) {
      payload.navigate(`/admin/accounts/${payload.userId}`);
    }
  }
);

export const addTrialaccount = createAsyncThunk(
  "admin/addTrialaccount",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let requestBody = {
      plan: payload.plan,
    };

    if (
      payload.region &&
      typeof payload.region === "string" &&
      payload.region.trim() !== ""
    ) {
      requestBody.region = payload.region;
    }

    if (
      typeof payload.additionalField === "number" &&
      !isNaN(payload.additionalField)
    ) {
      requestBody.additionalField = payload.additionalField;
    }

    let response = await axios.post(
      `${backendServerBaseURL}/support/accounts/${payload.userId}/add-subscription`,
      requestBody,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "New subscription created successfully"
    ) {
      return "success";
    }
  }
);

export const getSingleAccountSessions = createAsyncThunk(
  "admin/getSingleAccountSessions",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: payload.page,
        size: 20,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/admin/accounts/${payload.userId}/sessions`,
      config
    );

    if (response.status === 200 && response.data.message === "Sessions list") {
      thunkAPI.dispatch(
        updateSingleAccountSessions(response.data.payload.sessions)
      );
      thunkAPI.dispatch(updateSingleAccountSessionsPager(response.data.pager));
    }
  }
);

export const getSingleAccountRewards = createAsyncThunk(
  "admin/getSingleAccountRewards",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: payload.page,
        size: 20,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/admin/accounts/${payload.userId}/loyalty-rewards`,
      config
    );

    if (response.status === 200 && response.data.message === "Rewards list") {
      thunkAPI.dispatch(
        updatesingleAccountRewards(response.data.payload.rewards)
      );
      thunkAPI.dispatch(updatesingleAccountRewardsPager(response.data.pager));
    }
  }
);

export const disable2FA = createAsyncThunk(
  "admin/deleteAccount",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.delete(
      `${backendServerBaseURL}/admin/accounts/${payload.userId}/disable-2fa`,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "2FA disabled successfully"
    ) {
      window.location.reload();
    }
  }
);

export const disable2FASupport = createAsyncThunk(
  "admin/deleteAccount",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.delete(
      `${backendServerBaseURL}/support/accounts/${payload.userId}/disable-2fa`,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "2FA disabled successfully"
    ) {
      window.location.reload();
    }
  }
);

// Suggestions
export const getAllSuggestions = createAsyncThunk(
  "admin/getAllSuggestions",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: payload.page,
        size: 20,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/admin/suggestions`,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Suggestions list"
    ) {
      thunkAPI.dispatch(updateSuggestions(response.data.payload.suggestions));
      thunkAPI.dispatch(updateSuggestionsPager(response.data.pager));
    }
  }
);

export const deleteSuggestion = createAsyncThunk(
  "admin/deleteSuggestion",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.delete(
      `${backendServerBaseURL}/admin/suggestions/${payload.suggestionId}`,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Suggestion deleted successfully"
    ) {
      thunkAPI.dispatch(
        getAllSuggestions({
          page: thunkAPI.getState().admin.suggestionsPager.page,
        })
      );
    }
  }
);

// Coupons
export const getSingleCoupon = createAsyncThunk(
  "admin/getSingleCoupon",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {},
    };

    let response = await axios.get(
      `${backendServerBaseURL}/admin/coupons/${payload.couponId}`,
      config
    );

    if (response.status === 200 && response.data.message === "Coupon") {
      thunkAPI.dispatch(updateCouponToEdit(response.data.payload));
    }
  }
);

export const createNewCoupon = createAsyncThunk(
  "admin/createNewCoupon",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.post(
      `${backendServerBaseURL}/admin/coupons`,
      {
        code: payload.code,
        usages: payload.usages,
        discount: payload.discount,
        paymentProcessor: payload.paymentProcessor,
        product: payload.product,
      },
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Coupon created successfully"
    ) {
      payload.navigate("/admin/coupons");
    }
  }
);

export const editCoupon = createAsyncThunk(
  "admin/editCoupon",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let body = {
      code: payload.code,
      usages: payload.usages,
      discount: payload.discount,
      paymentProcessor: payload.paymentProcessor,
      product: payload.product,
    };

    let response = await axios.patch(
      `${backendServerBaseURL}/admin/coupons/${payload.couponId}`,
      body,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Coupon updated successfully"
    ) {
      payload.navigate("/admin/coupons");
    }
  }
);

export const getPartnersLinksCoupons = createAsyncThunk(
  "admin/getPartnersLinksCoupons",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/admin/partnersurl/statistic`,
      config
    );

    if (response.status === 200) {
      // API'den gelen yanıtı ilgili reducer'lara yönlendirin
      thunkAPI.dispatch(setPartnersLinks(response.data.partnersLinks));
      thunkAPI.dispatch(
        setTotalUsersWithPartnersUrl(response.data.totalUsersWithPartnersUrl)
      );
      thunkAPI.dispatch(setTotalUniqueBuyers(response.data.totalUniqueBuyers));
      thunkAPI.dispatch(settotalVisitor(response.data.totalVisitor));
    }
  }
);

export const getPartnersLinksCreate = createAsyncThunk(
  "admin/getPartnersLinksCreate",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const body = {
      domain: payload.code,
      status: payload.status,
    };

    let response = await axios.get(
      `${backendServerBaseURL}/admin/create/partnersurl`,
      body,
      config
    );

    if (response.status === 200) {
      console.log("Success");
    }
  }
);

export const getPartnersLinksDelete = createAsyncThunk(
  "admin/getPartnersLinksDelete",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      domain: payload.domain,
    });

    try {
      let response = await axios.post(
        `${backendServerBaseURL}/admin/delete/partnersurl`,
        body,
        config
      );
      if (response.status === 200) {
        console.log("Success");
        window.location.reload();
      }
    } catch (error) {
      console.error("Failed to delete the partners link", error);
      thunkAPI.dispatch();
    }
  }
);

export const getAllCoupons = createAsyncThunk(
  "admin/getAllCoupons",
  async (payload, thunkAPI) => {
    const token = localStorage.getItem("accessToken"); // async/await kaldırıldı, çünkü gerekli değil

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: payload.page,
        size: 20,
      },
    };

    try {
      let response = await axios.get(
        `${backendServerBaseURL}/admin/coupons`,
        config
      );

      console.log("API Response: ", response.data); // API'dan gelen yanıt loglanıyor

      if (response.status === 200 && response.data.message === "Coupons list") {
        thunkAPI.dispatch(updateCoupons(response.data.payload.coupons));
        thunkAPI.dispatch(updateCouponsPager(response.data.pager));

        console.log("Dispatched Coupons: ", response.data.payload.coupons);
        console.log("Dispatched Pager: ", response.data.pager); // Redux state'ine aktarılan pager bilgisi loglanıyor
      }
    } catch (error) {
      console.error(
        "Error fetching coupons: ",
        error.response || error.message
      );
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteCoupon = createAsyncThunk(
  "admin/deleteCoupon",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.delete(
      `${backendServerBaseURL}/admin/coupons/${payload.couponId}`,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Coupon deleted successfully"
    ) {
      thunkAPI.dispatch(
        getAllCoupons({ page: thunkAPI.getState().admin.couponsPager.page })
      );
    }
  }
);

// Subscriptions
export const getSingleSubscription = createAsyncThunk(
  "admin/getSingleSubscription",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {},
    };

    let response = await axios.get(
      `${backendServerBaseURL}/admin/subscriptions/${payload.subscriptionId}`,
      config
    );

    if (response.status === 200 && response.data.message === "Subscription") {
      thunkAPI.dispatch(
        updateSubscriptionToEdit(response.data.payload.subscription)
      );
    }
  }
);

export const editSubscription = createAsyncThunk(
  "admin/editSubscription",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let body = {
      duration: payload.duration,
    };

    let response = await axios.patch(
      `${backendServerBaseURL}/admin/subscriptions/${payload.subscriptionId}`,
      body,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Subscription updated successfully"
    ) {
      payload.navigate("/admin/invoices");
    }
  }
);

export const GBaddSubscription = createAsyncThunk(
  "admin/GBaddSubscription",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let body = {
      bandwidth: payload.bandwidth,
    };

    let response = await axios.patch(
      `${backendServerBaseURL}/admin/subscriptions/${payload.subscriptionId}/add`,
      body,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Subscription updated successfully"
    ) {
      payload.navigate("/admin/invoices");
    }
  }
);

export const GBaddSubscriptionSupport = createAsyncThunk(
  "admin/GBaddSubscription",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let body = {
      bandwidth: payload.bandwidth,
    };

    let response = await axios.patch(
      `${backendServerBaseURL}/support/subscriptions/${payload.subscriptionId}/add`,
      body,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Subscription updated successfully"
    ) {
      payload.navigate("/admin/invoices");
    }
  }
);

export const getAllSubscriptions = createAsyncThunk(
  "admin/getAllSubscriptions",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: payload.page,
        size: 20,
        search: thunkAPI.getState().admin.subscriptionsSearch,
        type: payload.type !== "ALL" ? payload.type : undefined,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/admin/subscriptions`,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Subscriptions list"
    ) {
      thunkAPI.dispatch(
        updateSubscriptions(response.data.payload.subscriptions)
      );
      thunkAPI.dispatch(updateSubscriptionsPager(response.data.pager));
    }
  }
);

export const getAllSubscriptionsSupports = createAsyncThunk(
  "admin/getAllSubscriptions",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: payload.page,
        size: 20,
        search: thunkAPI.getState().admin.subscriptionsSearch,
        type: payload.type !== "ALL" ? payload.type : undefined,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/support/subscriptions`,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Subscriptions list"
    ) {
      thunkAPI.dispatch(
        updateSubscriptions(response.data.payload.subscriptions)
      );
      thunkAPI.dispatch(updateSubscriptionsPager(response.data.pager));
    }
  }
);

export const deleteSubscription = createAsyncThunk(
  "admin/deleteSubscription",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        deleteOnlyRenew: payload.deleteOnlyRenew,
      },
    };

    let response = await axios.delete(
      `${backendServerBaseURL}/admin/subscriptions/${payload.subscriptionId}`,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Subscription deleted successfully"
    ) {
      thunkAPI.dispatch(
        getAllSubscriptions({
          page: thunkAPI.getState().admin.subscriptionsPager.page,
        })
      );
      thunkAPI.dispatch(getMe());
    }
  }
);

export const deleteSubscriptionSupport = createAsyncThunk(
  "admin/deleteSubscription",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        deleteOnlyRenew: payload.deleteOnlyRenew,
      },
    };

    let response = await axios.delete(
      `${backendServerBaseURL}/support/subscriptions/${payload.subscriptionId}`,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Subscription deleted successfully"
    ) {
      thunkAPI.dispatch(
        getAllSubscriptions({
          page: thunkAPI.getState().admin.subscriptionsPager.page,
        })
      );
      thunkAPI.dispatch(getMe());
    }
  }
);

export const removeWhitelistedIp = createAsyncThunk(
  "public/addWhitelistedIp",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const body = {
      ip: payload.ip,
    };

    try {
      let response = await axios.patch(
        `${backendServerBaseURL}/public/generator/${payload.subscriptionId}/remove-whitelist-ip`,
        body,
        config
      );
    } catch (err) {}

    payload.whitelistIpInpuRef.current.value = "";
  }
);

export const addWhitelistedIp = createAsyncThunk(
  "public/addWhitelistedIp",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const body = {
      ip: payload.ip,
    };

    try {
      let response = await axios.patch(
        `${backendServerBaseURL}/public/generator/${payload.subscriptionId}/add-whitelist-ip`,
        body,
        config
      );
    } catch (err) {}

    payload.whitelistIpInpuRef.current.value = "";
  }
);

export const readGenerator = createAsyncThunk(
  "admin/readGenerator",
  async (payload, { rejectWithValue }, thunkAPI) => {
    const token = await localStorage.getItem("accessToken");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const body = {
      id: payload.id,
    };

    try {
      let response = await axios.post(
        `${backendServerBaseURL}/admin/resigen`,
        body,
        config
      );

      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const readGeneratorSupport = createAsyncThunk(
  "admin/readGenerator",
  async (payload, { rejectWithValue }, thunkAPI) => {
    const token = await localStorage.getItem("accessToken");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const body = {
      id: payload.id,
    };

    try {
      let response = await axios.post(
        `${backendServerBaseURL}/support/resigen`,
        body,
        config
      );

      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const hidePlans = createAsyncThunk(
  "admin/hidePlans",
  async (payload, { rejectWithValue }) => {
    const token = await localStorage.getItem("accessToken");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const body = {
      id: payload.id,
    };

    try {
      let response = await axios.post(
        `${backendServerBaseURL}/admin/planhide/${payload.value}`,
        body,
        config
      );

      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const hidePlansSupport = createAsyncThunk(
  "admin/hidePlans",
  async (payload, { rejectWithValue }) => {
    const token = await localStorage.getItem("accessToken");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const body = {
      id: payload.id,
    };

    try {
      let response = await axios.post(
        `${backendServerBaseURL}/support/planhide/${payload.value}`,
        body,
        config
      );

      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const updateRenewalDate = createAsyncThunk(
  "admin/updateRenewalDate",
  async (payload, {}) => {
    const token = await localStorage.getItem("accessToken");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const body = {
      timeToAdd: payload.timeToAdd,
      operationType: payload.operationType,
    };

    try {
      let response = await axios.post(
        `${backendServerBaseURL}/admin/plantime/${payload.subscriptionId}`,
        body,
        config
      );

      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return payload.timeToAdd(err.response.data);
    }
  }
);

export const updateRenewalDateSupport = createAsyncThunk(
  "admin/updateRenewalDate",
  async (payload, {}) => {
    const token = await localStorage.getItem("accessToken");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const body = {
      timeToAdd: payload.timeToAdd,
      operationType: payload.operationType,
    };

    try {
      let response = await axios.post(
        `${backendServerBaseURL}/support/plantime/${payload.subscriptionId}`,
        body,
        config
      );

      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return payload.timeToAdd(err.response.data);
    }
  }
);

export const resolveSubscription = createAsyncThunk(
  "admin/resolveSubscription",
  async (payload, thunkAPI) => {
    try {
      const token = await localStorage.getItem("accessToken", "");

      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let body = {};

      let response = await axios.patch(
        `${backendServerBaseURL}/admin/subscriptions/${payload.subscriptionId}/resolve`,
        body,
        config
      );

      if (
        response.status === 200 &&
        response.data.message === "Subscription updated successfully"
      ) {
        payload.notify("Subscription updated");
        thunkAPI.dispatch(
          getAllSubscriptions({
            page: thunkAPI.getState().admin.subscriptionsPager.page,
          })
        );
        thunkAPI.dispatch(getMe());
      }
    } catch (error) {
      payload.notify(error.response.data.message);
    }
  }
);

export const resolveSubscriptionSupport = createAsyncThunk(
  "admin/resolveSubscription",
  async (payload, thunkAPI) => {
    try {
      const token = await localStorage.getItem("accessToken", "");

      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let body = {};

      let response = await axios.patch(
        `${backendServerBaseURL}/support/subscriptions/${payload.subscriptionId}/resolve`,
        body,
        config
      );

      if (
        response.status === 200 &&
        response.data.message === "Subscription updated successfully"
      ) {
        payload.notify("Subscription updated");
        thunkAPI.dispatch(
          getAllSubscriptions({
            page: thunkAPI.getState().admin.subscriptionsPager.page,
          })
        );
        thunkAPI.dispatch(getMe());
      }
    } catch (error) {
      payload.notify(error.response.data.message);
    }
  }
);

// Statistics
export const getStatistics = createAsyncThunk(
  "admin/getStatistics",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {},
    };

    let response = await axios.get(
      `${backendServerBaseURL}/admin/statistics`,
      config
    );

    if (response.status === 200 && response.data.message === "Statistics") {
      thunkAPI.dispatch(updateStatistics(response.data.payload.statistics));
    }
  }
);

//blogs
export const getAllBlogs = createAsyncThunk(
  "admin/getAllBlogs",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: payload.page,
        size: 20,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/admin/blogs`,
      config
    );

    if (response.status === 200 && response.data.message === "Blogs list") {
      thunkAPI.dispatch(updateBlogs(response.data.payload.blogs));
      // thunkAPI.dispatch(updateAccountsPager(response.data.pager));
    }
  }
);
export const getSingleBlog = createAsyncThunk(
  "admin/getSingleBlog",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/admin/blogs/${payload.id}`,
      config
    );

    if (response.status === 200 && response.data.message === "Blog") {
      thunkAPI.dispatch(updateSingleBlog(response.data.payload.blog));
    }

    if (response.status === 200 && response.data.message === "error") {
      // payload.setErrors({ afterSubmit: response.data.errors[0] });
    }
  }
);
export const createBlog = createAsyncThunk(
  "admin/createBlog",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    const filteredCategories = await payload?.category?.map((item) => {
      return item.value;
    });

    var bodyFormData = new FormData();
    bodyFormData.append(`title`, payload.title);
    bodyFormData.append(`slugvalue`, payload.slugvalue);
    bodyFormData.append(`author`, payload.author);
    bodyFormData.append(`description`, payload.description);
    bodyFormData.append(`conclusion`, payload.conclusion);
    bodyFormData.append(`toc`, payload.toc);
    bodyFormData.append(`faq`, JSON.stringify(payload.faq));
    bodyFormData.append(`category`, JSON.stringify(filteredCategories));
    if (payload.banner && payload.banner.length != 0) {
      bodyFormData.append(`banner`, payload.banner);
    }
    if (payload.authorPic && payload.authorPic.length != 0) {
      bodyFormData.append(`authorPic`, payload.authorPic);
    }

    let response = await axios.post(
      `${backendServerBaseURL}/admin/blogs`,
      bodyFormData,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Blog created successfully"
    ) {
      payload.navigate("/admin/blogs");
    }

    if (response.status === 200 && response.data.message === "error") {
      // payload.setErrors({ afterSubmit: response.data.errors[0] });
    }
  }
);
export const updateBlog = createAsyncThunk(
  "admin/updateBlog",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");
    console.log("updatting blog");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    const filteredCategories = await payload?.category?.map((item) => {
      return item.value;
    });

    var bodyFormData = new FormData();
    bodyFormData.append(`id`, payload.id);
    bodyFormData.append(`title`, payload.title);
    bodyFormData.append(`slugvalue`, payload.slugvalue);
    bodyFormData.append(`author`, payload.author);
    bodyFormData.append(`description`, payload.description);
    bodyFormData.append(`conclusion`, payload.conclusion);
    bodyFormData.append(`toc`, payload.toc);
    bodyFormData.append(`faq`, JSON.stringify(payload.faq));
    bodyFormData.append(`category`, JSON.stringify(filteredCategories));

    console.log("here ");

    if (payload.banner && payload.banner.length != 0) {
      console.log("banner is herer");
      bodyFormData.append(`banner`, payload.banner);
    }
    if (payload.authorPic && payload.authorPic.length != 0) {
      bodyFormData.append(`authorPic`, payload.authorPic);
    }

    let response = await axios.patch(
      `${backendServerBaseURL}/admin/blogs`,
      bodyFormData,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Blog updated successfully"
    ) {
      payload.notify();
      payload.navigate("/admin/blogs");
    }

    if (response.status === 200 && response.data.message === "error") {
      // payload.setErrors({ afterSubmit: response.data.errors[0] });
    }
  }
);
export const deleteBlog = createAsyncThunk(
  "admin/deleteBlog",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.delete(
      `${backendServerBaseURL}/admin/blogs/${payload.id}`,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Blog deleted successfully"
    ) {
      thunkAPI.dispatch(getAllBlogs({ page: 1 }));
      payload.navigate("/admin/blogs");
    }

    if (response.status === 200 && response.data.message === "error") {
      // payload.setErrors({ afterSubmit: response.data.errors[0] });
    }
  }
);

// tickets

export const getAllTickets = createAsyncThunk(
  "admin/getAllTickets",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: payload.page,
        size: 20,
        search: thunkAPI.getState().admin.ticketSearch,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/admin/ticket`,
      config
    );

    if (response.status === 200 && response.data.message === "Tickets list") {
      thunkAPI.dispatch(updateAllTickets(response.data.payload.tickets));
      thunkAPI.dispatch(updateTicketsPager(response.data.pager));
    }
  }
);
export const getTicketsStats = createAsyncThunk(
  "admin/getTicketStats",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/admin/ticket-stats`,
      config
    );

    if (response.status === 200 && response.data.message === "Ticket stats") {
      thunkAPI.dispatch(updateTicketStats(response.data.payload));
    }
    payload.setLoader(false);
  }
);
export const createNewTicket = createAsyncThunk(
  "admin/newTicket",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.post(
      `${backendServerBaseURL}/admin/ticket`,
      payload.values,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Ticket created successfully"
    ) {
      payload.navigate("/ticket/" + response.data.payload.ticket._id);
    } else {
      payload.errorNotify("Error creating new ticket");
    }
  }
);
export const closeTicket = createAsyncThunk(
  "admin/closeTicket",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.delete(
      `${backendServerBaseURL}/admin/ticket/${payload.id}`,
      config
    );

    if (response.status === 200) {
      payload.successNotify("Ticket closed successfully");
      payload.navigate("/admin/tickets");
    } else {
      payload.errorNotify("Error closing  ticket");
    }
  }
);
export const readNotifications = createAsyncThunk(
  "admin/readNotificatin",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/admin/mark-notification`,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Notifications marked read"
    ) {
    } else {
      payload.errorNotify("Error marking notification read");
    }
  }
);
export const readNotification = createAsyncThunk(
  "admin/readNotificatin",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.get(
      `${backendServerBaseURL}/admin/mark-notification/${payload.id}`,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Notification marked read"
    ) {
    } else {
      payload.errorNotify("Error marking notification read");
    }
  }
);

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setPartnersLinks: (state, action) => {
      state.partnersLinks = action.payload;
    },
    setTotalUsersWithPartnersUrl: (state, action) => {
      state.totalUsersWithPartnersUrl = action.payload;
    },
    setTotalUniqueBuyers: (state, action) => {
      state.totalUniqueBuyers = action.payload;
    },
    settotalVisitor: (state, action) => {
      state.totalVisitor = action.payload;
    },
    updateAllPlans: (state, action) => {
      state.allPlans = action.payload;
    },
    updatePlanToEdit: (state, action) => {
      state.planToEdit = action.payload;
    },

    updateAllSettings: (state, action) => {
      state.settings = action.payload;
    },

    updateAccounts: (state, action) => {
      state.accounts = action.payload;
    },
    updateAccountsPager: (state, action) => {
      state.accountsPager = action.payload;
    },
    updateAccountSearch: (state, action) => {
      state.accountSearch = action.payload;
    },
    updateViewAccountInfo: (state, action) => {
      state.viewAccountInfo = action.payload;
    },
    updateSingleAccountSessions: (state, action) => {
      state.singleAccountSessions = action.payload;
    },
    updateSingleAccountSessionsPager: (state, action) => {
      state.singleAccountSessionsPager = action.payload;
    },
    updatesingleAccountRewards: (state, action) => {
      state.singleAccountRewards = action.payload;
    },
    updatesingleAccountRewardsPager: (state, action) => {
      state.singleAccountRewardsPager = action.payload;
    },

    updateSuggestions: (state, action) => {
      state.suggestions = action.payload;
    },
    updateSuggestionsPager: (state, action) => {
      state.suggestionsPager = action.payload;
    },

    updateCoupons: (state, action) => {
      state.coupons = action.payload;
    },
    updateCouponsPager: (state, action) => {
      state.couponsPager = action.payload;
    },
    updateCouponToEdit: (state, action) => {
      state.couponToEdit = action.payload;
    },

    // Subscriptions
    updateSubscriptions: (state, action) => {
      state.subscriptions = action.payload;
    },
    updateSubscriptionsPager: (state, action) => {
      state.subscriptionsPager = action.payload;
    },
    updateAccountPager: (state, action) => {
      state.accountsPager = action.payload;
    },
    updateSubscriptionsSearch: (state, action) => {
      state.subscriptionsSearch = action.payload;
    },
    updateSubscriptionToEdit: (state, action) => {
      state.subscriptionToEdit = action.payload;
    },

    // Statistics
    updateStatistics: (state, action) => {
      state.statistics = action.payload;
    },

    //blogs
    updateBlogs: (state, action) => {
      state.blogs = action.payload;
    },
    updateSingleBlog: (state, action) => {
      state.blogEdit = action.payload;
    },
    updateAllTickets: (state, action) => {
      state.allTickets = action.payload;
    },
    updateTicketStats: (state, action) => {
      state.TicketStats = action.payload;
    },
    updateTicketsPager: (state, action) => {
      state.TicketsPager = action.payload;
    },
    updateTicketsSearch: (state, action) => {
      state.ticketSearch = action.payload;
    },
    updateAllPlans: (state, action) => {
      state.allPlans = action.payload;
    },
    rotatingProxy: (state, action) => {
      state.rotatingProxy = action.payload;
    },
  },
  extraReducers: {
    [readGenerator.fulfilled]: (state, action) => {
      state.rotatingProxy = action.payload;
    },
  },
});

export const {
  setPartnersLinks,
  setTotalUsersWithPartnersUrl,
  setTotalUniqueBuyers,
  settotalVisitor,
  updateTicketsSearch,
  updateAllTickets,
  updateTicketStats,
  updateTicketsPager,
  rotatingProxy,
  updateAllPlans,
  updatePlanToEdit,

  updateAllSettings,

  updateAccounts,
  updateAccountsPager,
  updateAccountSearch,
  updateViewAccountInfo,
  updateSingleAccountSessions,
  updateSingleAccountSessionsPager,
  updatesingleAccountRewards,
  updatesingleAccountRewardsPager,

  updateSuggestions,
  updateSuggestionsPager,

  updateCoupons,
  updateCouponsPager,
  updateCouponToEdit,

  updateSubscriptions,
  updateSubscriptionsPager,
  updateAccountPager,
  updateSubscriptionsSearch,
  updateSubscriptionToEdit,

  updateStatistics,

  updateBlogs,
  updateSingleBlog,
} = adminSlice.actions;

export const selectAllPlans = (state) => state.admin.allPlans;
export const selectPlanToEdit = (state) => state.admin.planToEdit;

export const selectSettings = (state) => state.admin.settings;

export const selectAccounts = (state) => state.admin.accounts;
export const selectAccountsPager = (state) => state.admin.accountsPager;
export const selectAccountSearch = (state) => state.admin.accountSearch;
export const selectViewAccountInfo = (state) => state.admin.viewAccountInfo;
export const selectSingleAccountSessions = (state) =>
  state.admin.singleAccountSessions;
export const selectSingleAccountSessionsPager = (state) =>
  state.admin.singleAccountSessionsPager;
export const selectsingleAccountRewards = (state) =>
  state.admin.singleAccountRewards;
export const selectsingleAccountRewardsPager = (state) =>
  state.admin.singleAccountRewardsPager;

export const selectSuggestions = (state) => state.admin.suggestions;
export const selectSuggestionsPager = (state) => state.admin.suggestionsPager;

export const selectCoupons = (state) => state.admin.coupons;
export const selectCouponsPager = (state) => state.admin.couponsPager;
export const selectCouponToEdit = (state) => state.admin.couponToEdit;

export const selectCheckerInfo = (state) => state.admin.checkerInfo;
export const selectAuditLogs = (state) => state.admin.auditLogs;
export const selectAuditLogsPager = (state) => state.admin.auditLogsPager;
export const selectAuditsSearch = (state) => state.admin.auditsSearch;

export const selectAllTickets = (state) => state.admin.allTickets;
export const selectTicketStats = (state) => state.admin.TicketStats;
export const selectTicketsPager = (state) => state.admin.TicketsPager;
export const selectTicketsSearch = (state) => state.admin.ticketSearch;

// Subscriptions
export const selectSubscriptions = (state) => state.admin.subscriptions;
export const selectSubscriptionsPager = (state) =>
  state.admin.subscriptionsPager;
export const selectAccountPager = (state) => state.admin.accountsPager;
export const selectSubscriptionsSearch = (state) =>
  state.admin.subscriptionsSearch;
export const selectSubscriptionToEdit = (state) =>
  state.admin.subscriptionToEdit;

// a
export const selectRotatingProxy = (state) => state.admin.rotatingProxy;

// Statistics
export const selectStatistics = (state) => state.admin.statistics;

//blogs
export const bloglist = (state) => state.admin.blogs;
export const selectBlogToEdit = (state) => state.admin.blogEdit;

export const selectPartnersLinks = (state) => state.admin.partnersLinks;
export const selectTotalUsersWithPartnersUrl = (state) =>
  state.admin.totalUsersWithPartnersUrl;
export const selectTotalUniqueBuyers = (state) => state.admin.totalUniqueBuyers;
export const selecttotalVisitor = (state) => state.admin.totalVisitor;

export default adminSlice.reducer;
