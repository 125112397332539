import React, { useEffect, useState } from "react";
import "./App.css";
import MainRoutes from "./Routes/MainRoutes";

export const ThemeContext = React.createContext();

function App() {
  const storedTheme = localStorage.getItem("theme", "light");
  const [selectedTheme, setselectedTheme] = useState("light");

  useEffect(() => {
    if (!localStorage.getItem("showAdmin")) {
      localStorage.setItem("showAdmin", "false");
    }
  }, []);

  useEffect(async () => {
    if (
      storedTheme === null ||
      storedTheme === undefined ||
      storedTheme === ""
    ) {
      await localStorage.setItem("theme", "light");
      setselectedTheme(storedTheme);
    }

    if (storedTheme) {
      setselectedTheme(storedTheme);
    }
  }, [selectedTheme]);

  return (
    <div className="App">
      <ThemeContext.Provider value={{ selectedTheme, setselectedTheme }}>
        <MainRoutes />
      </ThemeContext.Provider>
    </div>
  );
}

export default App;
