import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { backendServerBaseURL } from "../../utils/backendServerBaseURL";

const initialState = {
  me: null,
  typesConfig: null,
  announcements: null,
  countries: [],
};

// Get Me
export const getMe = createAsyncThunk("general/getMe", async (_, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {},
  };

  let response = await axios.get(`${backendServerBaseURL}/auth/me`, config);

  if (response.status === 200 && response.data.message === "Me") {
    thunkAPI.dispatch(updateMe(response.data.payload.user));
    if (response.data.payload.user.reseller === 1) {
      localStorage.clear();
      window.location.href = "/";
    }
  }
});

export const getCountryProfile = createAsyncThunk(
  "general/getCountryProfile",
  async (_, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {},
    };

    let response = await axios.get(
      `${backendServerBaseURL}/auth/country/profile`,
      config
    );

    if (response.status === 200) {
      thunkAPI.dispatch(updateCountries(response.data.payload.countries));
    }
  }
);

export const updateCountries = createAction(
  "general/updateCountries",
  (countries) => {
    return {
      payload: countries,
    };
  }
);

// Types config
export const getTypeConfig = createAsyncThunk(
  "admin/getTypeConfig",
  async (_, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {},
    };

    let response = await axios.get(
      `${backendServerBaseURL}/admin/types-config`,
      config
    );

    if (response.status === 200 && response.data.message === "Types config") {
      thunkAPI.dispatch(updateTypesConfig(response.data.payload));
    }
  }
);
// lightning_residential package list
export const getPyproxyPackages = createAsyncThunk(
  "admin/packages",
  async (_, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {},
    };

    let response = await axios.get(
      `${backendServerBaseURL}/admin/packages`,
      config
    );

    if (response.status === 200 && response.data.message === "Durations list") {
      thunkAPI.dispatch(updatePyproxyPackages(response.data.payload.list));
    }
  }
);

// Announcements
export const getAnnouncements = createAsyncThunk(
  "admin/getAnnouncements",
  async (_, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {},
    };

    let response = await axios.get(
      `${backendServerBaseURL}/public/announcements`,
      config
    );

    if (response.status === 200 && response.data.message === "Announcements") {
      thunkAPI.dispatch(
        updateAnnouncements(response.data.payload.announcements)
      );
    }
  }
);

export const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    updateMe: (state, action) => {
      state.me = action.payload;
    },
    updateTypesConfig: (state, action) => {
      state.typesConfig = action.payload;
    },
    updateAnnouncements: (state, action) => {
      state.announcements = action.payload;
    },
    updatePyproxyPackages: (state, action) => {
      state.lightning_residentialPackages = action.payload;
    },
    updateCountries: (state, action) => {
      state.countries = action.payload;
    },
  },
});

export const {
  updateMe,
  updateTypesConfig,
  updateAnnouncements,
  updatePyproxyPackages,
} = generalSlice.actions;

export const selectMe = (state) => state.general.me;
export const selectTypesConfig = (state) => state.general.typesConfig;
export const selectPyproxyPackages = (state) =>
  state.general.lightning_residentialPackages;
export const selectAnnouncements = (state) => state.general.announcements;
export const selectCountries = (state) => state.general.countries;

export default generalSlice.reducer;
